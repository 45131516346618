import {Injectable, signal} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Task} from '../../models/task';
import {Comment} from '../../models/comment';
import {Bairro} from '../../models/bairro';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BairrosService {

  bairrosList = signal<Bairro[]>([]);

  constructor(private http: HttpClient) {
    this.getAllBairros();
  }

  async getAllBairros() {
    const bairros = await lastValueFrom(this.http.get<Bairro[]>(`${environment.backendUrl}/bairros`));
    this.bairrosList.set(bairros);
    return bairros;
  }
}
