import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {MatIcon} from '@angular/material/icon';
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
  provideNativeDateAdapter
} from '@angular/material/core';
import {MatIconButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInput, MatInputModule} from '@angular/material/input';
import {MatTimepicker, MatTimepickerInput, MatTimepickerToggle} from '@angular/material/timepicker';
import {Editor, NgxEditorModule, Toolbar} from 'ngx-editor';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';
import {MatSelect} from '@angular/material/select';
import {AsyncPipe, NgClass, NgStyle} from '@angular/common';
import {MatCheckbox} from '@angular/material/checkbox';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/user';
import {Event, eventTypes} from '../../../models/event';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {Observable, of} from 'rxjs';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AuthService} from '../../services/auth.service';
import dayjs from 'dayjs';
import {EventService} from '../../services/event.service';
import {SnackbarService} from '../../services/snackbar.service';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS} from '../../utils';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-create-event',
  imports: [
    DeleteConfirmationComponent,
    MatIcon,
    MatFormField,
    MatDatepicker,
    MatIconButton,
    MatDivider,
    MatInput,
    ReactiveFormsModule,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatTimepicker,
    MatTimepickerInput,
    MatTimepickerToggle,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgxEditorModule,
    MatOption,
    MatCheckbox,
    NgClass,
    AsyncPipe,
    MatAutocomplete,
    MatAutocompleteModule,
    MatAutocompleteTrigger,
    PersonBadgeComponent,
    MatProgressSpinner,
    MatTooltip,

  ],
  providers: [
      {
        provide: DateAdapter,
        useClass: CustomDateAdapter,
        deps: [MAT_DATE_LOCALE, Platform]
      }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './create-event.component.html',
  standalone: true,
  styleUrl: './create-event.component.scss'
})
export class CreateEventComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<CreateEventComponent>);
  deleteLoading = signal(false)
  saving = signal(false);
  event? = inject<Event>(MAT_DIALOG_DATA);
  html = '';
  editor?: Editor;
  toolbar?: Toolbar;
  users: Observable<User[]> = of([]);
  invitees: User[] = [];
  inviteeSearch = new FormControl('');
  fb = inject(FormBuilder);
  currentUserId?: string;
  currentUserOrgRole?: string;
  secretaryName?: string;
  minHour?: Date;

  formGroup = this.fb.group({
    name: new FormControl(this.event?.name ?? '', Validators.required),
    date: new FormControl(this.event?.date ? dayjs.unix(this.event?.date).toDate() : '', Validators.required),
    allDay: new FormControl(this.event && !this.event.startTime && !this.event.endTime),
    startTime: new FormControl(this.event?.startTime ? dayjs.unix(this.event?.startTime).toDate() : '', Validators.required),
    endTime: new FormControl(this.event?.endTime ? dayjs.unix(this.event?.endTime).toDate() : '', Validators.required),
    location: new FormControl(this.event?.location ?? ''),
    description: new FormControl(this.event?.description ?? ''),
  })

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private eventService: EventService,
    private snackService: SnackbarService
  ) {
    effect(() => {
      if (this.authService.loggedUser() && !this.event) {
        this.currentUserId = this.authService.loggedUser()!.id;
        this.currentUserOrgRole = this.userService.users().find(user => user.id ===this.currentUserId )?.orgRole;
        this.secretaryName = this.userService.users().find(user => user.orgRole === 'SECRETARIO')?.name;
        this.invitees.push(this.authService.loggedUser()!);
        this.updateUserList('');
      } else {
        this.invitees = this.event?.users.map(u => this.userService.users().find(user => user.id === u.userId)!) ?? [];
      }
      this.users = of(this.userService.users());
    });
    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['ordered_list', 'bullet_list'],
      ['text_color', 'background_color'],
    ];
  }

  ngOnInit(): void {
    this.inviteeSearch.valueChanges.subscribe((value: any) => {
      if (value && typeof value === 'string') {
        this.updateUserList(value);
      } else {
        this.updateUserList('');
      }
    })
    this.handleAllday(this.formGroup.controls.allDay.value!);
    this.formGroup.controls.allDay.valueChanges.subscribe((value: any) => {
        this.handleAllday(value)
    })

    this.formGroup.controls.startTime.valueChanges.subscribe((value: any) => {
      if (value) {
        this.minHour = dayjs(value).add(10, 'minute').toDate();
      }
    })
  }

  handleAllday(isAllDay: boolean){
    if (isAllDay) {
      this.formGroup.controls.startTime.setValue(null);
      this.formGroup.controls.endTime.setValue(null);
      this.formGroup.controls.startTime.disable();
      this.formGroup.controls.endTime.disable();
    } else {
      this.formGroup.controls.startTime.enable();
      this.formGroup.controls.endTime.enable();
    }
  }

  updateUserList(filter: string) {
    setTimeout(() => {
      let users = this.userService.users();
      users = users.filter(u =>
        !this.invitees.find(i => i.id === u.id) &&
        u.name.toLowerCase().includes(filter.toLowerCase())
      );
      this.users = of(users);
    }, 1);
  }

  close() {
    this.dialogRef.close()
  }

  displayFn(user?: User): string {
    return user && user.name ? user.name : '';
  }

  updateInvitees(event: MatAutocompleteSelectedEvent) {
    this.invitees.push(event.option.value)
    this.inviteeSearch.setValue('');
  }

  removeInvitee(userId: string) {
    const index = this.invitees.indexOf(this.invitees.find(u => u.id === userId)!)
    this.invitees.splice(index, 1)
    this.updateUserList('');
  }

  async save() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const date = dayjs(this.formGroup.value.date);
      const formValue = this.formGroup.value;
      let startTime = null;
      let endTime = null;


      if (formValue.startTime){
        startTime = date
          .add(dayjs(this.formGroup.value.startTime).hour(), 'hour')
          .add(dayjs(this.formGroup.value.startTime).minute(), 'minute');
        endTime = date
          .add(dayjs(this.formGroup.value.endTime).hour(), 'hour')
          .add(dayjs(this.formGroup.value.endTime).minute(), 'minute');
      }

      const result = {
        ...formValue,
        date: date.unix(),
        startTime: startTime ? startTime.unix() : null,
        endTime: endTime? endTime.unix() : null,
        users: this.invitees.map(u => ({userId: u.id, role: 'invitee'}))
      };
      delete result.allDay;
      this.saving.set(true);
      if (this.event) {
        this.eventService.update(this.event.id, result as Event).then((created) => {
          this.saving.set(false);
          this.dialogRef.close(created)
        }).catch((error) => {
          this.saving.set(false);
          this.snackService.openSnackBar('error', 'Erro ao criar evento', 'OK');
        })
      } else {
        this.eventService.create(result as Event).then((created) => {
          this.saving.set(false);
          this.dialogRef.close(created)
        }).catch((error) => {
          this.saving.set(false);
          this.snackService.openSnackBar('error', 'Erro ao criar evento', 'OK');
        })
      }
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  deleteEvent() {
    this.deleteLoading.set(true)
      this.eventService.delete(this.event!.id).then(() => {
        this.snackService.openSnackBar('success', 'Evento deletado', 'OK')
        this.dialogRef.close()
      }).catch(() => {
        this.snackService.openSnackBar('error', 'Erro ao deletar evento', 'OK')
      }).finally(() => {
        this.deleteLoading.set(false)
      })
  }

  protected readonly dayjs = dayjs;
  protected readonly eventTypes = eventTypes;
}
