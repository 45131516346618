<div class="main">
  <app-person-badge [user]="history().user" [selected]="false"/>
  <div>
    <div class="who">
      <b>{{ history().user?.name ?? 'Sistema' }}</b>
      @if(history().data.dataChanged === 'description') {
        <div>alterou a <b>descrição</b></div>
      }
      <div class="timestamp">{{ history().id | ulid | gm_date:'timestamp' }}</div>
    </div>
    @if (history().data.dataChanged === 'name') {
      <div class="data">Nome alterado de:<b>"{{ history().data.oldData }}"</b> para <b>"{{ history().data.newData }}
        "</b></div>
    }
    @if (history().data.dataChanged === 'status') {
      <div class="data">Status alterado de:
        <app-status-badge [nameId]="String(history().data.oldData)" [type]="'task'"></app-status-badge>
        para
        <app-status-badge [nameId]="String(history().data.newData)" [type]="'task'"></app-status-badge>
      </div>
    }
    @if (history().data.dataChanged === 'assigneeUserId') {
      @if (history().assigneeChange?.oldAssignee?.name == null) {
        <div class="data">Responsável definido para <b>{{ String(history().assigneeChange?.newAssignee?.name) }}</b>
        </div>
      } @else {
        <div class="data">Responsável alterado de:<b>{{ String(history().assigneeChange?.oldAssignee?.name) }}</b> para
          <b>{{ String(history().assigneeChange?.newAssignee?.name) }}</b></div>
      }
    }
    @if (history().data.dataChanged === 'type') {
      @if (history().data.oldData == null) {
        <div class="data">Tipo definido para <b>{{ String(history().data.newData) }}</b></div>
      } @else {
        <div class="data">Tipo alterado de:<b>{{ String(history().data.oldData) }}</b> para
          <b>{{ String(history().data.newData) }}</b></div>
      }
    }
    @if (history().data.dataChanged === 'deadline') {
      @if (history().data.oldData == null) {
        <div class="data">Prazo definido para <b>{{ history().data.newData | gm_date }}</b></div>
      } @else {
        <div class="data">Prazo alterado de:<b>{{ history().data.oldData! | gm_date }}</b> para
          <b>{{ history().data.newData | gm_date }}</b></div>
      }
    }
    @if (history().data.dataChanged === 'created') {
      <div class="data">{{ history().data.entityType }} criado.</div>
    }
    @if (history().data.dataChanged === 'description') {
      @if (history().data.oldData == null) {
        <div class="data">Descrição definida para <b>{{ String(history().data.newData) }}</b></div>
      } @else {
        <div class="data">de:<b class="description-history" #tooltip="matTooltip"
                                                   [matTooltip]="String(history().data.oldData)">{{ history().data.oldData }}</b>
          para <b class="description-history"
          [innerHTML]="String(history().data.newData)"></b></div>
      }
    }
    @if (history().data.dataChanged === 'address') {
      @if (history().data.oldData == null) {
        <div class="data">Endereço definido para <b>{{ history().data.newData }}</b></div>
      } @else {
        <div class="data">Endereço alterado de:<b>{{ history().data.oldData }}</b>para<b>{{ history().data.newData }}</b></div>
      }
    }
    @if (history().data.dataChanged === 'document') {
      @if (history().data.oldData == null) {
        <div class="data">Documento <b>{{ history().data.newData }}</b> adicionado</div>
      } @else {
        <div class="data">Documento <b>{{ history().data.oldData }}</b> removido.</div>
      }
    }
  </div>
</div>
