<div class="header">
  <app-delete-confirmation
    [hidden]="isNew || authService.loggedUser()?.organizationRelationship?.position === 'ESTAGIARIO'"
    [deleting]="false"
    (deleteConfirmation)="deleteLA()"/>

  @if (isNew) {
    <div class="task-name">Nova proposição</div>
  } @else {
    <div class="task-name text-overflow">{{ formGroup.get('subject')?.value }}
    </div>
  }
  <button mat-icon-button (click)="close()" autofocus>
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content class="container">
  <div class="top">
    <div class="left">
      <div class="content">
        @if (!isEditing()) {
          <mat-icon id="edit-icon" class="edit-icon"
                    fontIcon="edit_square" style="color:#FBC00E"
                    (click)="isEditing.set(true)"></mat-icon>
        }
        <form id="form" [formGroup]="formGroup" class="form">
          <div class="title">Tipo*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="full-span">
              <mat-select formControlName="type">
                @for (t of laService.laTypeList(); track t) {
                  <mat-option [value]="t.id">
                    <p>{{ t.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value full-span" style="width: 95%;">{{ findLabel(laService.laTypeList(), formGroup.controls['type'].value) }}</span>
          }
          <div class="title subject-label">Assunto*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="full-span">
              <input matInput formControlName="subject">
            </mat-form-field>
          } @else {
            <div class="value value--subject full-span">
              <span>{{ formGroup.controls['subject'].value }}</span>
            </div>
          }
          <div class="title">Tema*:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic" class="left-section">
              <mat-select formControlName="theme">
                @for (t of laService.laThemeList(); track t) {
                  <mat-option [value]="t.id">
                    <p>{{ t.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value left-section">{{ findLabel(laService.laThemeList(), formGroup.controls['theme'].value) }}</span>
          }

          <div class="title data-label">Data:</div>
          @if (isEditing()) {
            <mat-form-field subscriptSizing="dynamic">
              <input formMask="date" matInput [matDatepicker]="datepicker" formControlName="date">
              <mat-datepicker #datepicker/>
              <mat-datepicker-toggle [for]="datepicker" matSuffix/>
            </mat-form-field>
          } @else {
            <span
              class="value">{{ formGroup.controls['date'].value ? (formGroup.controls['date'].value! | gm_date) : '' }}</span>
          }
        </form>
        @if (!isNew && isEditing()) {
          <div class="buttons">
            <app-button-w-loader
              label="Cancelar"
              type="outline"
              (click)="close()"
            />
            <app-button-w-loader
              label="Salvar"
              [loading]="isLoading"
              type="yellow"
              (click)="save()"
            />
          </div>
        }
      </div>
      @if (!isNew) {
        <div class="tramites-holder">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <p class="title">Trâmites</p>

            <app-button-w-loader
              [disabled]="showNewTramites"
              padding="0 8px"
              prefixIcon="add"
              label="Novo Trâmite"
              type="outline-purple"
              (click)="addTramite()"
            />

          </div>
          @if (laId) {
            @if (showNewTramites) {
              <div class="tramites-new-holder">
                <div class="new tramites-title">
                  Novo trâmite
                </div>
                <div class="tramites-data">
                  <div class="tramites-date">
                    <mat-form-field subscriptSizing="dynamic">
                      <input formMask="date" matInput [matDatepicker]="datepicker" [formControl]="tramitesDateFormControl">
                      <mat-datepicker #datepicker/>
                      <mat-datepicker-toggle [for]="datepicker" matSuffix/>
                    </mat-form-field>
                  </div>
                  <div class="new tramites-value">
                    <div class="rich-text-holder">
                      <quill-editor placeholder="Escreva aqui" class="rich-text" [formControl]="richControl" format="object"></quill-editor>
                    </div>
                  </div>
                </div>
                <div class="tramites-new-buttons">
                  <app-button-w-loader
                    label="Cancelar"
                    type="outline"
                    (click)="setHideNewTramites()"></app-button-w-loader>
                  <app-button-w-loader
                    label="Salvar"
                    type="yellow"
                    [loading]="loadingNewTramite"
                    (click)="saveTramite()"></app-button-w-loader>
                </div>
              </div>

            }
            @if (tramiteList.length > 0) {
              <div class="tramites-data-holder">
                <div class="tramites-title">
                  Última Atualização
                  @if (loadingNewTramite) {
                    <mat-progress-spinner [diameter]="20" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
                  }
                </div>
                @if (tramitesExpanded) {
                  @for (tramite of tramiteList; track tramite.id) {
                    <div class="tramites-data">
                      <div class="tramites-date">
                        {{ tramite.date | gm_date }}
                      </div>
                      <div class="tramites-divider"></div>
                      <div class="tramites-value">
                        @if(tramite.delta) {
                          <quill-view class="value description-value description-view" format="object" [content]="tramite.delta"></quill-view>
                        } @else {
                          <div [innerHTML]="tramite.description"></div>
                        }
                      </div>
                    </div>
                  }
                } @else if (tramiteList.length > 0) {
                  <div class="tramites-data">
                    <div class="tramites-date">
                      @if (loadingNewTramite) {
                        <app-skeleton [rowNumber]="1" type="tramite"></app-skeleton>
                      } @else {
                        {{ tramiteList[0].date | gm_date }}
                      }

                    </div>
                    <div class="tramites-divider"></div>
                    <div class="tramites-value">
                      @if (loadingNewTramite) {
                        <app-skeleton [rowNumber]="1" type="tramite"></app-skeleton>
                      } @else {
                        @if(tramiteList[0].delta) {
                          <quill-view class="value description-value description-view" format="object" [content]="tramiteList[0].delta"></quill-view>
                        } @else {
                          <div [innerHTML]="tramiteList[0].description"></div>
                        }
                      }

                    </div>
                  </div>
                }


              </div>

              <div class="tramites-see-more-less" (click)="expandTramites()">
                @if (tramitesExpanded) {
                  <mat-icon>keyboard_arrow_up</mat-icon>
                  Ver menos
                } @else {
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  Ver mais
                }

              </div>
            }
          }
        </div>
        @if(authService.orgPlan != Plan.SILVER) {
        <div class="docs-holder">
          <div style="display: flex; justify-content: space-between; margin-top: 10px;align-items: center;">
            <p class="title">Documentos</p>

            <app-button-w-loader
              padding="0 8px"
              prefixIcon="upload"
              label="Adicionar arquivos"
              type="outline-purple"
              (click)="fileInput.click()"
            />
            <input #fileInput type="file" hidden (change)="fileUpload($event)">

          </div>
          @if (laId) {
            <app-file-handler
              entity="la"
              [referenceId]="laId"
              [uploadFileList]="uploadFileList"/>
          }
        </div>}
        <div class="history-holder">
          <mat-expansion-panel
            style="box-shadow: none;"
            class="expansion">
            <mat-expansion-panel-header>
              <mat-panel-title><p class="title">Histórico</p></mat-panel-title>
            </mat-expansion-panel-header>
            <div class="history">
              @for (history of historyList; track history.id) {
                <app-history [history]="history"></app-history>
              }
              @if (historyList.length === 0) {
                <div class="empty-history">Nenhum histórico encontrado.</div>
              }
            </div>
          </mat-expansion-panel>
        </div>
      }
    </div>
    <div class="right">
      <div class="content">
        <section #status class="status-holder">
          <app-status-badge size="large" [nameId]="laStatus" (click)="showOverlay()" [type]="'cs'"></app-status-badge>
        </section>
        <div class="form-2">
          <div class="title">Responsável:</div>
          @if (isEditingAssignee) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-select #assigneeField [value]="laAssignee?.id" (selectionChange)="selectAssignee($event)">
                <mat-select-trigger>
                  @if (laAssignee) {
                    <span>{{ laAssignee.name }}</span>
                  }
                </mat-select-trigger>
                @for (person of allUsers; track person.id) {
                  <mat-option [value]="person.id">
                    <app-person-badge [selected]="false" [user]="person"></app-person-badge>
                    <p>{{ person.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <span class="value editable" (click)="editAssignee()">
              <app-person-badge [selected]="false" [user]="laAssignee"></app-person-badge>
              {{ laAssignee?.name }}
          </span>
          }
          <div class="title">Proposição Nº:</div>
          @if (isNew) {
            <form [formGroup]="formGroup">
              <mat-form-field subscriptSizing="dynamic" class="full-span">
                <input matInput formControlName="proposition" mask="999.99999.9999" [dropSpecialCharacters]="false">
              </mat-form-field>
            </form>
          } @else {
            @if (isEditingNumber) {
              <app-editable-input formName="" [value]="laNumber" mask="999.99999.9999"
                                  (onSave)="updateNumber($event)" [formSize]="'158px'"
                                  (onCancel)="isEditingNumber=false"></app-editable-input>
            } @else {
              <span class="value editable" (click)="isEditingNumber=true">{{ laNumber }}</span>
            }
          }
          @if(laSplStatus) {
            <div class="title">Spl Status:</div>
            <span class="value">{{ laSplStatus }}</span>
          }

        </div>

      </div>
      <div style="height: 10px; width: 10px"></div>
      <app-button-w-loader
        [matTooltip]="'Nenhuma tarefa relacionada.'"
        [matTooltipDisabled]="!!relatedTasksCount"
        [disabled]="!relatedTasksCount"
        padding="0 8px"
        type="outline-purple"
        [label]="'Ver todas as tarefas relacionadas' + (relatedTasksCount ? ' (' + relatedTasksCount + ')' : '')"
        sufixIcon="open_in_new"
        (click)="window.open('/tarefas?laId=' + laId, '_blank')"
      />
    </div>
  </div>
  @if (isNew) {
    <div class="buttons">
      <app-button-w-loader
        label="Cancelar"
        type="outline"
        (click)="close()"
      />
      <app-button-w-loader
        label="Salvar"
        [loading]="isLoading"
        type="yellow"
        (click)="save()"
      />
    </div>
  }
</mat-dialog-content>
