import {Pipe, PipeTransform} from '@angular/core';
import {decodeTime} from 'ulid';

@Pipe({
  name: 'ulid'
})
export class UlidPipe implements PipeTransform {

  transform(value?: string, ...args: any[]): number {
    if (value == null) {
      return 0;
    }
    return Math.floor(decodeTime(value) / 1000);
  }

}
