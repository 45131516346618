import {Component, effect, input} from '@angular/core';
import {History} from '../../../models/history';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {UlidPipe} from '../ulid.pipe';
import {GMDatePipe} from '../date.pipe';
import {StatusBadgeComponent} from '../status-badge/status-badge.component';
import {User} from '../../../models/user';
import {MatTooltip} from '@angular/material/tooltip';

export type HistoryData = History & {
  user?: User;
  assigneeChange?: {
    oldAssignee?: User;
    newAssignee?: User;
  }
}

@Component({
  selector: 'app-history',
  imports: [
    PersonBadgeComponent,
    UlidPipe,
    GMDatePipe,
    StatusBadgeComponent,
    MatTooltip
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss'
})
export class HistoryComponent {

  history = input.required<HistoryData>();

  protected readonly String = String;
}
