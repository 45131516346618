import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Event} from '../../models/event';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private url = environment.backendUrl;

  eventList = signal<Event[]>([]);

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient,
  ) {
    this.realtime.events.pipe(startWith(null))
      .subscribe(() => {
        this.getAll();
      })
  }

  async getAll() {
    return lastValueFrom(this.http.get<Event[]>(this.url + `/events`)).then((events) => {
      this.eventList.set(events);
      return events;
    });
  }

  async create(data: Event) {
    return lastValueFrom(this.http.post<Event[]>(this.url + `/events`, data));
  }

  async update(id:string, data: Event) {
    return lastValueFrom(this.http.put<Event[]>(this.url + `/events/${id}`, data));
  }

  async delete(id:string) {
    return lastValueFrom(this.http.delete<Event[]>(this.url + `/events/${id}`));
  }
}
