<div
  matRipple
  class="button"
  [matRippleDisabled]="disabled"
  (click)="_click()"
  [ngClass]="[disabled ? 'disabled' : '', type]"
>
  <div class="loader-holder">
    <mat-spinner [style.visibility]="!loading ? 'hidden' : 'unset'" diameter="25" color="black"></mat-spinner>
  </div>
    <div [style.visibility]="loading ? 'hidden' : 'unset'" >{{ label }}</div>
</div>
