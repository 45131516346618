<div class="main">
  <div class="title">
    Nome Legal
  </div>
  <div>
    <div routerLink="/notificacoes" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon [matBadgeHidden]="count==0" [matBadge]="count" matBadgeSize="small" fontIcon="notifications"></mat-icon>
      <span>
    Notificações {{ count > 0 ? '(' + count + ')' : '' }}
  </span>
    </div>

    <div routerLink="/calendario" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="calendar_month"></mat-icon>
      <span>
    Calendário
  </span>
    </div>

    <div routerLink="/tarefas" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="task"></mat-icon>
      <span>
    Tarefas
  </span>
    </div>

    <div routerLink="/atendimentos" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="support_agent"></mat-icon>
      <span>
    Atendimentos
  </span>
    </div>

    <div routerLink="/mapa" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="map"></mat-icon>
      <span>
    Mapa
  </span>
    </div>

    <div routerLink="/contatos" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="contact_phone"></mat-icon>
      <span>
    Contatos
  </span>
    </div>

    <!--    <div mat-ripple class="menu-item">-->
    <!--      <mat-icon fontIcon="savings"></mat-icon>-->
    <!--      <span>-->
    <!--    Finanças-->
    <!--  </span>-->
    <!--    </div>-->

    <div routerLink="/usuarios" routerLinkActive="active" mat-ripple class="menu-item">
      <mat-icon fontIcon="group"></mat-icon>
      <span>
    Gerenciar Usuários
  </span>
    </div>
  </div>

  <div class="user-container">
    <div class="line-container">
      <app-person-badge [size]="46" [user]="user" [selected]="false"></app-person-badge>
      <div class="text">
        <span>Olá, {{ user?.name ?? 'User' }}!</span>
        <span class="configuration-link" (click)="openDialog()">Configurações</span>
      </div>
      <div class="logout">
        <button mat-icon-button>
          <mat-icon fontIcon="logout" (click)="logout()"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
