import {Component, effect, inject, input, Input, numberAttribute, OnInit} from '@angular/core';
import {NgOptimizedImage, NgStyle} from '@angular/common';
import {User} from '../../../../models/user';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-person-badge',
  imports: [
    NgStyle,
    NgOptimizedImage,
    MatTooltip
  ],
  templateUrl: './person-badge.component.html',
  standalone: true,
  styleUrl: './person-badge.component.scss'
})
export class PersonBadgeComponent {
  @Input({required: true}) selected!: boolean;
  @Input() profilePicture?: string;
  size = input(30);
  @Input() disableTooltip: boolean = false;

  user = input.required< User | undefined>();
  placeholderNumber = input(0);

  circleSize = '30px'
  fontSize = '10px'

  constructor() {
    effect(() => {
      this.circleSize = (this.size()) + 'px';
      this.fontSize = Math.floor((this.size()) /3) + 'px';
    });
  }

  getInitials(name?: string): string {
    if (!name) {
      return 'S';
    }
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }
}
