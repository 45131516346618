import {Component, effect, Inject, inject} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions, MatSnackBarLabel,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import {SnackBarData} from '../../../models/snackbarData';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-snack-bar',
  imports: [
    MatSnackBarAction,
    MatSnackBarActions,
    MatSnackBarLabel,
    MatButton
  ],
  templateUrl: './snack-bar.component.html',
  standalone: true,
  styleUrl: './snack-bar.component.scss'
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }
  snackBarRef = inject(MatSnackBarRef);

}
