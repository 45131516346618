import {Component, effect, signal} from '@angular/core';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {Notifications} from '../../models/notifications';
import {NotificationsService} from '../services/notifications.service';
import {MatRipple} from '@angular/material/core';
import {ButtonWLoaderComponent} from '../common/button-w-loader/button-w-loader.component';
import {UserService} from '../services/user.service';


@Component({
  selector: 'app-notifications',
  imports: [
    NotificationItemComponent,
    ButtonWLoaderComponent,
  ],
  templateUrl: './notifications.component.html',
  standalone: true,
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

  notifications = signal<Notifications[] | null>(null);
  count = signal<number>(0);
  readAllLoading = signal(false);
  clearAllLoading = signal(false);
  constructor(
    private usersService: UserService,
    private notificationService: NotificationsService
  ) {
    effect(() => {
      if(this.usersService.users().length > 0 && this.notificationService.notifications() != null) {
        // @ts-ignore
        this.notifications.set(this.notificationService.notifications().map((notification) => ({
          ...notification,
          message: notification.message.replace('{FRONTEND_URL}', window.location.origin),
          sender: this.usersService.users().find((user) => user.id === notification.senderId)
        })));
        this.count.set(this.notificationService.unredCount());
      }
    });
  }
  async toogleRead(notification: Notifications) {
    return  this.notificationService.updateNotification(notification.id, {read: !notification.read});
  }

  async clear(notification: Notifications) {
    return  this.notificationService.updateNotification(notification.id, {cleared: true});
  }

  async clearAll() {
    this.clearAllLoading.set(true);
    return this.notificationService.clearAll().then(() => {
      this.clearAllLoading.set(false);
    })
  }

  async readAll() {
    this.readAllLoading.set(true);
    return this.notificationService.readAll().then(() => {
      this.readAllLoading.set(false);
    })
  }

  protected readonly event = event;
}
