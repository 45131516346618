import {ChangeDetectionStrategy, Component, effect, EventEmitter, inject, input, OnInit, Output, ViewChild} from '@angular/core';
import {GMDatePipe} from "../../common/date.pipe";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {PersonBadgeComponent} from "../../common/person-picker/person-badge/person-badge.component";
import {StatusBadgeComponent} from "../../common/status-badge/status-badge.component";
import {User} from '../../../models/user';
import {Contact} from '../../../models/contact';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import dayjs from 'dayjs';
import {CS} from '../../../models/cs';
import {ContactService} from '../../services/contact.service';
import {EmptyViewPipe} from '../../common/empty-view.pipe';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {AssignPersonComponent} from '../../common/assign-person/assign-person.component';
import {MatDialog} from '@angular/material/dialog';
import {CSService} from '../../services/cs.service';
import {environment} from '../../../environments/environment';
import {findLabel, toFilter} from '../../utils';
import {MatTooltip} from '@angular/material/tooltip';

export type CSData = CS & {
  bairro?: string;
  contact?: Contact;
  loadingAssignee?: boolean;
  _address?: string;
  _assignee?: string;
  _subject?: string;
  _type?: string;
  _bairro?: string;
}

@Component({
  selector: 'app-cs-table',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule,
    MatSortModule, MatPaginatorModule, MatIcon, MatIconButton,
    PersonBadgeComponent, StatusBadgeComponent, GMDatePipe,
    EmptyViewPipe, MatProgressSpinner, MatTooltip],
  templateUrl: './cs-table.component.html',
  standalone: true,
  styleUrl: './cs-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsTableComponent implements OnInit {
  displayedColumns: string[] = ['id', 'timestamp', '_type', '_subject', '_bairro', '_assignee', 'status', 'open'];
  dataSource: MatTableDataSource<CSData> = new MatTableDataSource([] as CSData[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  values = input.required<CS[]>();

  filterUsers = input<User[]>([]);
  toBeDeleted = input<string>();
  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<CSData[]> = new EventEmitter();

  initial = true;

  searchValue = input.required<string>();
  contactService = inject(ContactService);

  filterToday = input<boolean>(false);
  filterDay = input<Date>();
  filterBairro = input<string>();

  constructor() {
    effect(() => {
      this.contacts = this.contactService.contactList();
      this.update();
      this.applyFilter();
    });
    if (this.csService.csTypeList().length === 0 || this.csService.csTypeList().length === 0) {
      this.csService.getAllCsInformation();
    }
  }

  update() {
    this.dataSource.data = this.values().map((value) => {
      return this.createNewTask(value);
    }).filter((value) => value.id !== this.toBeDeleted());
  }

  csService = inject(CSService);
  readonly dialog = inject(MatDialog);

  openDialog(cs: CSData): void {
    cs.loadingAssignee = true;
    const dialogRef = this.dialog.open(AssignPersonComponent, {
      width: '400px',
      height: '100px',
      data: {},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.assignee) {
        await this.csService.updateAssignee(cs.id, result.assignee);
        await this.csService.getAllServices();
      }
      cs.loadingAssignee = false;
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.values().map((value) => {
      return this.createNewTask(value);
    }));
    this.update();
    this.dataSource.filterPredicate = ((data: CSData): boolean => {
      let r = true;
      if (this.filterUsers().length > 0) {
        if (data.assigneeUser == null) {
          r = false;
        }
        if (data.assigneeUser?.id != this.filterUsers().find((user) => user.id === data.assigneeUser?.id)?.id) {
          r = false;
        }
      }
      if (this.filterDay() || this.filterToday()) {
        if (data.attendDate == null) {
          r = false;
        } else if (
          (!this.filterToday() || !dayjs().isSame(dayjs.unix(data.attendDate), 'day')) &&
          (!this.filterDay() || !dayjs(this.filterDay()).isSame(dayjs.unix(data.attendDate), 'day'))) {
          r = false;
        }
      }

      if (this.filterBairro() && this.filterBairro() !== 'Todos') {
        r =  r && data.bairro === this.filterBairro();
      }

      if (this.searchValue() != '') {
        if ((!data.protocol || data.protocol?.toLowerCase().split('.').join('').indexOf(this.searchValue().split('.').join('')) === -1) &&
          (data.subject?.toLowerCase().indexOf(this.searchValue()) === -1) &&
          data.type?.toLowerCase().indexOf(this.searchValue()) === -1 &&
          (toFilter(data.contact?.name?.toLowerCase()).indexOf(toFilter(this.searchValue())) === -1)){
          r = false;
        }
      }
      return r;
    });

    setTimeout(() => {
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
      this.sort?.sort({id: 'timestamp', start: 'desc', disableClear: false});
    }, 0);
  }

  contacts: Contact[] = [];

  applyFilter() {
    this.dataSource.filter = String(this.filterToday()) + String(this.filterDay()) + this.searchValue() + String(this.filterUsers().length);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    if (!this.initial) {
      this.filteredData.emit(this.dataSource.filteredData);
    } else {
      this.initial = false;//gambi
    }
  }

  createNewTask(cs: CS): CSData {
    return {
      ...cs,
      contact: this.contacts.find((contact) => contact.id === cs.contactId),
      _address: toFilter(cs.address),
      _assignee: toFilter(cs.assigneeUser?.name),
      _subject: toFilter(cs.subject),
      _type: toFilter(cs.type),
      _bairro: toFilter(cs.bairro),
    };
  }

  protected readonly environment = environment;
  protected readonly window = window;
  protected readonly findLabel = findLabel;
}
