<div class="dialog">
  <section class="entry">
    <p class="subtitle">Responsável:</p>
    <section class="assignee-cell">
      @if (assignee) {
        <app-person-badge [selected]="false" [user]="assignee"></app-person-badge>
      }
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Responsável</mat-label>
        <mat-select [value]="assignee?.id" (selectionChange)="selectAssignee($event)">
          <mat-select-trigger>
            @if (assignee) {
              <span>{{ assignee.name }}</span>
            }
          </mat-select-trigger>
          @for (person of userService.users(); track person.id) {
            <mat-option [value]="person.id">
              <app-person-badge [selected]="false" [user]="person"></app-person-badge>
              <p>{{ person.name }}</p>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </section>
  </section>
</div>
