<div class="notifications-main">
  <div class="header">
    <div class="title">
      <span>Notificações {{ count() > 0 ? '(' + count() + ')' : '' }}</span>
    </div>
    <div class="buttons">
      <app-button-w-loader
        [disabled]="count()===0"
        [loading]="readAllLoading()"
        (buttonClick)="readAll()"
        label="Marcar todas como lido"
      />
      <app-button-w-loader
        [disabled]="notifications()?.length === 0"
        [loading]="clearAllLoading()"
        (buttonClick)="clearAll()"
        type="yellow"
        label="Limpar todas"/>
    </div>
  </div>
  <div class="rift"></div>
  <div class="notifications-holder">
    @for (notification of notifications(); track notification) {
      <app-notification-item
        (onClear)="clear($event)"
        (onToogleRead)="toogleRead($event)"
        [notification]="notification"/>
    }
    @if (this.notifications()?.length === 0 ){
      <div class="no-notifications">
        <span>Tudo resolvido!</span>
      </div>
    }
    @if (!this.notifications()){
      <div class="loader"></div>
    }
  </div>
</div>
