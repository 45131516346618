import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CdkStepLabel} from '@angular/cdk/stepper';
import {Notifications} from '../../../models/notifications';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {MatIcon} from '@angular/material/icon';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'app-notification-item',
  imports: [
    PersonBadgeComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './notification-item.component.html',
  standalone: true,
  styleUrl: './notification-item.component.scss'
})
export class NotificationItemComponent implements OnChanges  {
  @Input({required: true}) notification!: Notifications;
  @Input() loader!: boolean;
  @Output() onClear: EventEmitter<Notifications> = new EventEmitter();
  @Output() onToogleRead: EventEmitter<Notifications> = new EventEmitter();

  loading = false;

  _onClear() {
    this.loading = true;
    return this.onClear.emit(this.notification);
  }

  _onToggle() {
    this.loading = true;
    return this.onToogleRead.emit(this.notification);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.loading){
      this.loading = false;
    }
  }
}
