import {EventEmitter, Injectable} from '@angular/core';
import {collection, Firestore, onSnapshot, query, where} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class RealtimeService {

  tasks = new EventEmitter();
  users = new EventEmitter();
  contacts = new EventEmitter();
  comments = new EventEmitter();
  events = new EventEmitter();
  cs = new EventEmitter();

  lastDoc: Record<string, number> = {};

  constructor(
    private firestore: Firestore
  ) { //TODO: get organization from somewhere
    const collectionRef = collection(this.firestore, 'org/01JDBB3NV8RCPJFQ11W6FXER1N/realtime')
    onSnapshot(query(collectionRef, where('__name__', '==', 'updates')), (snapshot) => {
      const change = snapshot.docChanges()[0];
      const data = change.doc.data()
      if (change.type === 'added') {
        this.lastDoc = data;
      } else if (change.type === 'modified') {
        for (const key in data) {
          // @ts-ignore
          if (this[key] && (!this.lastDoc[key] || this.lastDoc[key] < data[key]) ) {
            // @ts-ignore
            this[key].emit(change.doc.data()[key]);
          }
        }
        this.lastDoc = data;
      }
    })
  }
}
