import {Component, inject, ViewEncapsulation} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {MatSelect, MatSelectChange, MatSelectTrigger} from '@angular/material/select';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/user';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-assign-person',
  imports: [
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    PersonBadgeComponent
  ],
  templateUrl: './assign-person.component.html',
  styleUrl: './assign-person.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AssignPersonComponent {
  readonly dialogRef = inject(MatDialogRef<AssignPersonComponent>);

  userService = inject(UserService);
  assignee?: User;

  selectAssignee(selection: MatSelectChange) {
    this.dialogRef.close({ assignee: selection.value });
  }
}
