import {Component, effect, inject} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatOption, MatRipple} from '@angular/material/core';
import {Location, NgIf} from '@angular/common';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {ContactTable} from './contact-table/contact-table.component';
import {Contact} from '../../models/contact';
import {ContactService} from '../services/contact.service';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {CreateContactComponent} from './create-contact/create-contact.component';
import {BairrosService} from '../services/bairros.service';
import {Bairro} from '../../models/bairro';
import {User} from '../../models/user';
import {ItemCounterComponent} from '../common/item-counter/item-counter.component';
import {ContactCompletePipe} from './contact-complete.pipe';
import {StatusGroup} from '../common/status-parser.pipe';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-contacts',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatRipple,
    PersonPickerComponent,
    ContactTable,
    MatSelect,
    MatOption,
    ItemCounterComponent
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss'
})
export class ContactsComponent {
  contacts: Contact[] = [];

  statusMap = new Map<string, StatusGroup>([
    ['complete', StatusGroup.complete],
    ['incomplete', StatusGroup.incomplete],
  ]);

  tmpTask?: string;

  bairrosService = inject(BairrosService);

  bairros?: Bairro[];
  selectedBairro = '__all__'
  usersToShowBadges?: User[];

  activatedRoute = inject(ActivatedRoute);

  cId: string | null = '';

  contactService = inject(ContactService);

  constructor() {
    this.activatedRoute.paramMap.subscribe((param) => {
      this.cId = param.get('contactId');
      this.update();
    });
    effect(() => {
      this.update();
    });
  }

  static updateUsers(contacts: Contact[]): User[] | undefined {
    if (contacts.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of contacts) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  setStatusMap(cData: Contact[]) {
    this.statusMap.get('complete')?.setCount(cData.filter((contact) => new ContactCompletePipe().transform(contact) === 'Completo').length);
    this.statusMap.get('incomplete')?.setCount(cData.filter((contact) => new ContactCompletePipe().transform(contact) === 'Pendente').length);
  }

  filteredTableData(contactData: Contact[]) {
    this.setStatusMap(contactData);
  }
  searchBairro(event: MatSelectChange) {
    this.selectedBairro = event.value;
  }

  opened = false;

  location = inject(Location);

  async update() {
    this.bairros = this.bairrosService.bairrosList().sort((a, b) => a.name.localeCompare(b.name));
    this.usersToShowBadges = ContactsComponent.updateUsers(this.contactService.contactList());
    this.setStatusMap(this.contactService.contactList().filter((contact) => !contact.deletedAt));
    if (this.cId && this.contactService.contactList().find((c) => c.id === this.cId)) {
      this.openDialog(this.cId);
    }
  }

  readonly dialog = inject(MatDialog);

  openDialog(contactId?: string): void {
    if (this.opened) {
      return;
    }
    this.opened = true;
    const dialogRef = this.dialog.open(CreateContactComponent, {
      maxWidth: '830px',
      height: contactId ? '920px' : '1200px',
      data: {
        contactData: this.contactService.contactList().find((contact) => contact.id === contactId),
        bairros: this.bairros,
      },
    });

    dialogRef.componentInstance.tmp.subscribe((value) => {
      this.tmpTask = value;
    });
    dialogRef.afterClosed().subscribe(async result => {
      this.cId = null;
      this.location.go('contatos');
      await this.update();
      this.opened = false;
    });
  }

  searchValue: string = '';

  newContact() {
    this.openDialog();
  }

  openDetails(contactId: string) {
    this.openDialog(contactId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
  }

  usersToFilter: User[] = []

  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
  }
}
