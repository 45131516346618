import {inject, Injectable, OnDestroy, signal} from '@angular/core';
import {User} from '../../models/user';
import {lastValueFrom, Subject, takeUntil, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LoginResponse, RefreshResponse} from '../../models/auth';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';
import {Auth, signOut} from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    const authData = localStorage.getItem('auth-data');
    if(authData) {
      this.authData = JSON.parse(authData);
      // this.postAuth();
    }
  }

  auth = inject(Auth);

  loggedUser = signal<User|null|undefined>(null);
  loggedIn:boolean = false;
  private authData: LoginResponse|null = null;
  private cancelReAuth$ = new Subject<void>(); // Subject to signal cancellation

  getToken() {
    return this.authData?.idToken;
  }

  private saveToLocalStorage(data:LoginResponse) {
    localStorage.setItem('auth-data', JSON.stringify(data));
  }

  async login(data: {email: string, password: string}) {
    this.authData  = await lastValueFrom(this.http.post<LoginResponse>(environment.backendUrl +`/auth/login`, data));
    // this.postAuth();
  }

  async resetPassword(data: {email: string}) {
    await lastValueFrom(this.http.post(environment.backendUrl +`/auth/reset-password`, data));
    return;
  }

  private async refresh() {
    const data = {refreshToken: this.authData?.refreshToken};
    const refreshData = await lastValueFrom(this.http.post<RefreshResponse>(environment.backendUrl +`/auth/refresh`, data));
    this.authData!.refreshToken = refreshData.refreshToken;
    this.authData!.idToken = refreshData.idToken;
    this.authData!.expiresIn = refreshData.expiresIn;
    // this.postAuth();
  }

  postAuth(userId?: string) {
    if(!userId) return;
    this.loggedIn = true;
    this.userService.getUser(userId).then((user) => {
      this.loggedUser.set(user);
    }).catch(() => {
      this.logout();
    });
    this.cancelReAuth$.next(); // Emit a signal to cancel the timeout
  }

  async logout() {
    this.loggedIn = false;
    this.loggedUser.set(null);
    this.authData = null;
    localStorage.removeItem('auth-data');
    this.cancelReAuth$.next(); // Emit a signal to cancel the timeout
    signOut(this.auth);
  }

  ngOnDestroy(): void {
    // Cleanup on component destroy
    this.cancelReAuth$.next();
    this.cancelReAuth$.complete();
  }
}
