@if (!loading) {
  <mat-drawer-container class="example-container">
    <mat-drawer *ngIf="showSidenav" mode="side" opened>
      <app-sidebar>

      </app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet />
    </mat-drawer-content>
  </mat-drawer-container>
} @else {
  <div class="center-loader">
  <mat-progress-spinner class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
  </div>
}
