import {Component, effect, inject} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {NgIf} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {User} from '../../models/user';
import {UserTable} from './user-table/user-table.component';
import {MatDialog} from '@angular/material/dialog';
import {UserDetailsComponent} from './user-details/user-details.component';
import {UserService} from '../services/user.service';
import {AuthService} from '../services/auth.service';
import {ButtonWLoaderComponent} from '../common/button-w-loader/button-w-loader.component';
import {MatTooltip} from '@angular/material/tooltip';
import {toFilter} from '../utils';
import {Plan} from '../../models/plan';

@Component({
  selector: 'app-users',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    MatProgressSpinner,
    UserTable,
    ButtonWLoaderComponent,
    MatTooltip
  ],
  templateUrl: './users.component.html',
  standalone: true,
  styleUrl: './users.component.scss',
})
export class UsersComponent {
  searchValue: string = '';
  users: User[] = [];
  cantCreateDueGroup?: string;
  cantCreateDuePerms?: string;
  cantCreateDueLimit?: string;
  authService = inject(AuthService);
  userLimit = 15;

  allowedRoles = ['ADMIN'];

  constructor(
    private gmAuth: AuthService,
    private usersService: UserService) {
    this.usersService.updateList();
    effect(() => {
      this.users = this.usersService.users().filter((u) => !u.deleted);
      this.cantCreateDueLimit = this.gmAuth.orgPlan !== Plan.GOLD && this.users.length >= this.userLimit ? 'Limite de usuários atingido - assine o Plano Gold para adicionar mais usuários' : undefined;
      this.cantCreateDueGroup = this.gmAuth.orgType !== 'group' ? 'É necessário assinar o plano mensal para adicionar usuários' : undefined;
      this.cantCreateDuePerms = !this.allowedRoles.includes(this.authService.loggedUser()?.orgRole ?? '') ? 'Você não tem permissão para adicionar usuários' : undefined;
    });
  }


  readonly dialog = inject(MatDialog);

  openDialog(userId?: string): void {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '720px',
      maxWidth: '720px',
      height: userId ? '550px' : '750px',
      data: {
        admin: true,
        user: this.users.find((user) => user.id === userId)
      },
      panelClass: 'custom-dialog-container'
    });
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = toFilter(filterValue);
  }

  newUser() {
    this.openDialog();
  }

  openDetails(id: string) {
    this.openDialog(id);
  }
}
