import {AfterViewInit, Component, effect, EventEmitter, inject, input, OnInit, Output, ViewChild} from '@angular/core';
import {GMDatePipe} from "../../common/date.pipe";
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {PersonBadgeComponent} from "../../common/person-picker/person-badge/person-badge.component";
import {StatusBadgeComponent} from "../../common/status-badge/status-badge.component";
import {User} from '../../../models/user';
import {Bairro} from '../../../models/bairro';
import {Contact} from '../../../models/contact';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import dayjs from 'dayjs';
import {CS} from '../../../models/cs';
import {ContactService} from '../../services/contact.service';
import {EmptyViewPipe} from '../../common/empty-view.pipe';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {AssignPersonComponent} from '../../common/assign-person/assign-person.component';
import {MatDialog} from '@angular/material/dialog';
import {CSService} from '../../services/cs.service';
import {environment} from '../../../environments/environment';

export type CSData = CS & {
  bairro?: Bairro;
  contact?: Contact;
  loadingAssignee?: boolean;
  _address?: string;
  _assignee?: string;
}

@Component({
  selector: 'app-cs-table',
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, MatIcon, MatIconButton, PersonBadgeComponent, StatusBadgeComponent, GMDatePipe, EmptyViewPipe, MatProgressSpinner],
  templateUrl: './cs-table.component.html',
  styleUrl: './cs-table.component.scss'
})
export class CsTableComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['id', 'timestamp', 'type', '_address', 'contact', '_assignee', 'status', 'open'];
  dataSource: MatTableDataSource<CSData> = new MatTableDataSource([] as CSData[]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  values = input.required<CS[]>();

  @ViewChild(MatTable) table?: MatTable<CSData>;

  filterUsers = input<User[]>([]);
  toBeDeleted = input<string>();
  @Output() openDetails: EventEmitter<string> = new EventEmitter();
  @Output() filteredData: EventEmitter<CSData[]> = new EventEmitter();

  initial = true;

  searchValue = input.required<string>();
  contactService = inject(ContactService);

  filterToday = input<boolean>(false);
  filterUntil = input<Date>();

  constructor() {
    effect(() => {
      this.contacts = this.contactService.contactList();
      this.update();
      this.applyFilter();
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.values().map((value) => {
      return this.createNewTask(value);
    }));
  }

  update() {
    this.dataSource.data = this.values().map((value) => {
      return this.createNewTask(value);
    }).filter((value) => value.id !== this.toBeDeleted());
    this.table?.renderRows();
  }

  csService = inject(CSService);
  readonly dialog = inject(MatDialog);

  openDialog(cs: CSData): void {
    cs.loadingAssignee = true;
    const dialogRef = this.dialog.open(AssignPersonComponent, {
      width: '400px',
      height: '100px',
      data: {},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.assignee) {
        await this.csService.updateAssignee(cs.id, result.assignee);
        await this.csService.getAllServices();
      }
      cs.loadingAssignee = false;
    });
  }

  ngAfterViewInit() {
    this.update();
    this.dataSource.filterPredicate = ((data: CSData): boolean => {
      let r = true;
      if (this.filterUsers().length > 0) {
        if (data.assigneeUser == null) {
          r = false;
        }
        if (data.assigneeUser?.id != this.filterUsers().find((user) => user.id === data.assigneeUser?.id)?.id) {
          r = false;
        }
      }
      if (this.filterToday()) {
        if (data.timestamp == null) {
          r = false;
        } else if (!dayjs().isSame(dayjs.unix(data.timestamp), 'day')) {
          r = false;
        }
      }
      if (this.filterUntil()) {
        if (data.timestamp == null) {
          r = false;
        } else if (dayjs(this.filterUntil()).diff(dayjs.unix(data.timestamp), 'days') < 0) {
          r = false;
        }
      }

      if (this.searchValue() != '') {
        const contatcName = data.contact?.name ?? '';
        if (data.protocol.toLowerCase().indexOf(this.searchValue()) === -1 &&
          contatcName.toLowerCase().indexOf(this.searchValue()) === -1 &&
          data.type?.toLowerCase().indexOf(this.searchValue()) === -1) {
          r = false;
        }
      }
      return r;
    });

    setTimeout(() => {
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
      this.sort?.sort({id: 'id', start: 'asc', disableClear: false});
    }, 0);
  }

  contacts: Contact[] = [];

  applyFilter() {
    this.dataSource.filter = String(this.filterToday()) + String(this.filterUntil()) + this.searchValue() + String(this.filterUsers().length);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    if (!this.initial) {
      this.filteredData.emit(this.dataSource.filteredData);
    } else {
      this.initial = false;//gambi
    }
  }

  createNewTask(cs: CS): CSData {
    return {
      ...cs,
      bairro: undefined,
      contact: this.contacts.find((contact) => contact.id === cs.contactId),
      _address: cs.address?.toLowerCase(),
      _assignee: cs.assigneeUser?.name.toLowerCase(),
    };
  }

  protected readonly environment = environment;
  protected readonly window = window;
}
