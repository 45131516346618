import {AbstractControl, ValidationErrors} from '@angular/forms';
import {LaInformation} from '../models/la';

export const DUDU_DATE_FORMATS = {
  parse: {
    dateInput: null,
    timeInput: null,
  },
  display: {
    dateInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timeInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
    timeOptionLabel: {hour: 'numeric', minute: 'numeric'},
  },
};

export const sanitizeDelta = (delta?: any): string | undefined => {
  if (delta) {
    if (delta.ops) {
      return delta.ops;
    }
    return delta;
  }
  return undefined;
}

export const  formErrors: Record<string, string> = {
  required: 'Campo obrigatório',
  email: 'Email inválido',
  minlength: 'Senha deve ter no mínimo 6 caracteres',
}

export function parseFormErrors(errors: Record<string, any> | null) {
  const errorMessages: string[] = [];
  if(!errors) return errorMessages;
  for(const [key,data] of Object.entries(errors)) {
    switch (key){
        case 'required':
          errorMessages.push('Campo obrigatório');
          break;
        case 'email':
          errorMessages.push('Email inválido');
          break;
        case 'minlength':
          errorMessages.push(`No mínimo ${data.requiredLength} caracteres`)
          break;
    }
  }

  return errorMessages;
}

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  const isWhitespace = (control.value || '').trim().length === 0;
  return isWhitespace ? { whitespace: true } : null;
}

export function isEntityValidator(control: AbstractControl): ValidationErrors | null {
  const response = !control.value?.id;
  return response ? { validContact: true } : null;
}

export function findLabel(labels: (LaInformation | { id: string, name: string })[], key: string | null) {
  if(!key) return '--';
  return labels.find((label) => label.id === key)?.name;
}

export function toFilter(str?: string) {
  if (!str) return '';
  const  withDia =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž\'~˜\`';
  const withoutDia =
    'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz       ';
  for (let i = 0; i < withDia.length; i++) {
    str = str.replaceAll(withDia[i], withoutDia[i]);
  }
  return str.trim().toLowerCase();
}
