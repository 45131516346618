import { Pipe, PipeTransform } from '@angular/core';

export interface IStatusGroup {
  name: string;
  color: string
}

export class StatusGroup implements IStatusGroup {
  count: number = 0;
  showSeparator: boolean = false;
  invertTitleColor: boolean = false;
  constructor(public id: string, public name: string, public color: string) { }

  setShowSeparator(showSeparator: boolean): StatusGroup {
    this.showSeparator = showSeparator;
    return this;
  }

  setCount(count: number): StatusGroup {
    this.count = count;
    return this;
  }

  setInvertTitleColor(invertTitleColor: boolean): StatusGroup {
    this.invertTitleColor = invertTitleColor;
    return this;
  }

  static total = new StatusGroup('total', 'Total', '#D9D9D9');
  static open = new StatusGroup('open', 'Aberto', '#D9D9D9');
  static onHold = new StatusGroup('onHold', 'Em espera', '#FF7926');
  static inProgress = new StatusGroup('inProgress', 'Em progresso', '#00AAD999');
  static deferred = new StatusGroup('deferred', 'Deferido', '#4DBF8E');
  static rejected = new StatusGroup('rejected', 'Indeferido', '#DA2727').setInvertTitleColor(true);
  static late = new StatusGroup('rejected', 'Atrasado', '#DA2727').setInvertTitleColor(true);
  static incomplete = new StatusGroup('incomplete', 'Incompletos', '#DA2727').setInvertTitleColor(true);
  static done = new StatusGroup('done', 'Concluído', '#4DBF8E');
  static complete = new StatusGroup('complete', 'Completos', '#4DBF8E');
  static ok = new StatusGroup('ok', 'Em dia', '#4DBF8E');

  static all = [StatusGroup.open, StatusGroup.onHold, StatusGroup.inProgress, StatusGroup.deferred, StatusGroup.rejected, StatusGroup.done];
}

@Pipe({
  name: 'statusParser'
})
export class StatusParserPipe implements PipeTransform {

  transform(value: string, ...args: any[]): StatusGroup {
    return StatusGroup.all.find((status) => status.id === value) ?? new StatusGroup(value, value, '#000');
  }

}
