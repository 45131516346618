import {Injectable, signal} from '@angular/core';
import {RealtimeService} from './realtime.service';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom, startWith} from 'rxjs';
import {LA, LaInformation} from '../../models/la';
import {environment} from '../../environments/environment';
import {CS} from '../../models/cs';

@Injectable({
  providedIn: 'root'
})
export class LaService {

  laList = signal<LA[]>([])
  laThemeList = signal<LaInformation[]>([])
  laTypeList = signal<LaInformation[]>([])

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.la.pipe(startWith(null))
      .subscribe(()=>{
        this.getAllLA();
      })
  }

  async getAllLA(){
    const result = await lastValueFrom(this.http.get<LA[]>(`${environment.backendUrl}/lesgislative_actions`));
    this.laList.set(result);
    return result;
  }

  async getAllLaInformation(organizationId?: string){
    const result = await lastValueFrom(this.http.get<LaInformation[]>(`${environment.backendUrl}/la_information`));
    this.laThemeList.set(result.filter(la => la.type === 'theme'));
    this.laTypeList.set(result.filter(la => la.type === 'type'));
  }

  async upsertLa(id: string,cs: Partial<Omit<LA, 'id'>>) {
    const response = await lastValueFrom(this.http.put<LA>(`${environment.backendUrl}/lesgislative_actions/${id}`, cs));
    await this.getAllLA();
    return response;
  }

  async updateStatus(laId: string, status: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/lesgislative_actions/` + laId + '/status', {status}));
    await this.getAllLA();
  }

  async delete(laId: string) {
    await lastValueFrom(this.http.delete<{}>(`${environment.backendUrl}/lesgislative_actions/` + laId));
    await this.getAllLA();
  }

}
