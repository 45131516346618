<div class="container">
  <div class="left-pannel">
    <mat-card class="demo-inline-calendar-card">
      <mat-calendar [(selected)]="selectedDay" (selectedChange)="dayChange.emit($event)"></mat-calendar>
    </mat-card>
  </div>
  <div class="right-pannel">
    <div class="today-task-container">
      <div class="button-holder">

      </div>
      <div class="today-task-holder">
        @for (event of this.allDayEvents; track event.id) {
          <div class="day-event" [style.background-color]="event.color"
               (click)="event.isOpened = true"
               cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            {{ event.title }}
          </div>
          <app-event-overlay (edit)="openDialog(event.id)" [eventTile]="event" [trigger]="trigger"/>
        }
      </div>
    </div>
    <div id="day" class="day-container">
      <div class="labels">
        <div class="first-line"></div>
        @for (hour of hours; track hour) {
          <div class="hour">
            <span>{{ hour }}h </span>
            <div class="line-container">
              <div class="lines down"></div>
              <div class="lines"></div>
            </div>
          </div>
        }
      </div>
      <div class="day">
        <div id="now" class="now" [style]="{top: nowPosition}">
          <div class="now-line">
            <div class="now-ball"></div>
          </div>
        </div>
        <div class="lanes">
          @for (lane of eventArray; track lane) {
            <div class="lane">
              @for (event of lane; track event) {
                <div [style]="{
                top: event.position + 'px',
                height: event.height + 'px',
                background: event.color,
                }" class="event"
                     (click)="event.isOpened = true"
                     cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                  <span>{{ event.title }}</span>
                </div>
                <app-event-overlay (edit)="openDialog(event.id)"
                                   [eventTile]="event" [trigger]="trigger"/>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
