<div class="header">
  <app-delete-confirmation
    [hidden]="isNew"
    [deleting]="false"
    (deleteConfirmation)="deleteTask()"></app-delete-confirmation>

  @if (isNew) {
    <div class="task-name task-add">Adicionar Tarefa</div>
  } @else {
    <div class="task-name">{{ taskName }}</div>
  }
  <button mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="content">
    <div class="left" [style.height]="isNew ? '540px' : '598px'">
      <div style="padding:15px">
      <div class="entry">
        <p class="title">Nome:</p>
        @if (isNew) {
          <form [formGroup]="formGroup">
            <mat-form-field subscriptSizing="dynamic" style="width: 600px;">
              <mat-label>Nome</mat-label>
              <input matInput placeholder="Tarefa X" formControlName="nameControl">
            </mat-form-field>
          </form>
        } @else if (isEditingName || !taskName || taskName === '') {
          <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
            <form [formGroup]="formGroup">
              <mat-form-field subscriptSizing="dynamic" style="width: 520px;">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Tarefa X" formControlName="nameControl">
              </mat-form-field>
            </form>
            <div style="display: flex; flex-direction: row; gap: 5px">
              <div mat-ripple class="save-small" (click)="saveName()">
                <mat-icon fontIcon="check"></mat-icon>
              </div>
              <div mat-ripple class="cancel-small" (click)="isEditingName = false">
                <mat-icon fontIcon="close"></mat-icon>
              </div>
            </div>
          </div>
        } @else {
          <div class="task-name-editable" (click)="isEditingName = true">{{ taskName }}</div>
        }
      </div>
      <p class="title">Descrição:</p>
      @if (!isEditingDescription) {
        <p class="description" (click)="isEditingDescription = !isEditingDescription" [innerHTML]="html"></p>
      } @else {
        @if (editor && toolbar) {
          <div>
            <ngx-editor-menu
              [editor]="editor"
              [toolbar]="toolbar!"

            ></ngx-editor-menu>
            <ngx-editor [editor]="editor" [(ngModel)]="html"></ngx-editor>
          </div>
        }
        @if (!isNew) {
          <div style="display: flex; flex-direction: row-reverse; margin-bottom: 20px; gap: 5px; margin-top: 10px">
            <div class="save" mat-ripple (click)="saveDescription()">
              <div>Salvar</div>
            </div>
            <div class="cancel" mat-ripple (click)="isEditingDescription = false">
              <div>Cancelar</div>
            </div>
          </div>
        }
      }
      @if (taskId) {
        <div style="display: flex; flex-direction: row-reverse">
          <div mat-ripple class="upload" (click)="fileInput.click()">
            <mat-icon fontIcon="upload"></mat-icon>
            <div>Adicionar arquivos</div>
          </div>
          <input #fileInput type="file" hidden (change)="fileUpload($event)">

        </div>
        <p class="title">Documentos</p>

        <app-file-handler [referenceId]="taskId" [uploadFileList]="uploadFileList"
                          (fileUploaded)="fileUploadFinished($event)"
                          (fileRemoved)="fileRemoved($event) "/>

      }
    </div>
      @if (taskId) {
        <mat-expansion-panel
          class="expansion">
          <mat-expansion-panel-header>
            <mat-panel-title><p class="title">Histórico</p></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="history">
            @for (history of historyList; track history.id) {
              <app-history [history]="history"></app-history>
            }
          </div>
        </mat-expansion-panel>
      }
    </div>
    <div class="vertical-divider" [style.height]="isNew? '567px' : '629px'"></div>
    <div class="right">

      <section class="entry">
        <p class="subtitle">Responsável:</p>
        <section class="assignee-cell">
          @if (taskAssignee) {
            <app-person-badge [selected]="false" [user]="taskAssignee"></app-person-badge>
          }
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Responsável</mat-label>
            <mat-select [value]="taskAssignee?.id" (selectionChange)="selectAssignee($event)">
              <mat-select-trigger>
                @if (taskAssignee) {
                  <span>{{ taskAssignee.name }}</span>
                }
              </mat-select-trigger>
              @for (person of data.users; track person.id) {
                <mat-option [value]="person.id">
                  <app-person-badge [selected]="false" [user]="person"></app-person-badge>
                  <p>{{ person.name }}</p>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </section>
      </section>

      <section #status class="entry">
        <p class="subtitle">Status:</p>
        <section class="assignee-cell">
          <app-status-badge [nameId]="taskStatus" (click)="showOverlay()" [type]="'task'"></app-status-badge>
        </section>
      </section>


      <section class="entry">
        <p class="subtitle">Prazo:</p>
        <section class="assignee-cell">
          <mat-form-field>
            <mat-label>Selecione uma data</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="dateControl">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          @if (dateControl.value) {
            <div mat-ripple class="trash-small" (click)="removeDeadline()">
              <mat-icon fontIcon="delete_outline"></mat-icon>
            </div>
          }
        </section>
      </section>

      <section class="entry relation">
        <p class="subtitle relation-sub">Relacionado com:</p>
          <app-related-contac-cs [style]="{width: '240px'}" [selected]="relationships[0] && relationships[0].relationshipId"
                                 (selectedChange)="relatedChange($event)"/>
      </section>
      @if (taskId) {
        <p class="title" style="margin-top: 0">Comentários:</p>
        <app-comments *ngIf="taskId" [taskId]="taskId" [users]="data.users"></app-comments>
      }
    </div>
  </div>
  @if (isNew) {
    <div
      style="height: 61px;display: flex; flex-direction: row; gap: 5px; justify-content: space-evenly; border-top: 1px solid #D9D9D9;    align-items: center;">
      <div style="display: flex; flex-direction: row-reverse; gap: 5px">
        <div class="save" mat-ripple (click)="saveDescription()">
          <div>Salvar</div>
        </div>
        <div class="cancel" mat-ripple (click)="dialogRef.close()">
          <div>Cancelar</div>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>
