import {Component, effect, inject, input, output, signal} from '@angular/core';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {User} from '../../../models/user';
import {UserService} from '../../services/user.service';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-mention-list',
  imports: [
    PersonBadgeComponent,
    NgClass
  ],
  templateUrl: './mention-list.component.html',
  standalone: true,
  styleUrl: './mention-list.component.scss'
})
export class MentionListComponent {
  userService = inject(UserService);

  filteredUserList = this.userService.users();

  selectPerson = output<User | undefined>();

  filter = signal<string>('');
  selectedIndex = signal<number | null>(null);

  constructor() {
    effect(() => {
      if (this.filter() === '') {
        this.filteredUserList = this.userService.users();
      } else {
        this.filteredUserList = this.userService.users().filter((user) => user.name.toLowerCase().includes(this.filter()?.toLowerCase() ?? ''));
      }
    });
  }
}
