import {
  APP_INITIALIZER,
  ApplicationConfig,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors} from '@angular/common/http';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {MatIconRegistry} from '@angular/material/icon';
import {authInterceptor} from './http-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes), provideAnimationsAsync(), provideHttpClient(
    withInterceptors([authInterceptor])
  ), provideFirebaseApp(() => initializeApp({
    projectId: "nuvex-gm-development",
    appId: "1:204990423698:web:6bb53afe057d8ea574bc4b",
    storageBucket: "nuvex-gm-development.firebasestorage.app",
    apiKey: "AIzaSyBaSrqc6A9U9Hg7zZbj8RpXIYMd7cVA3fw",
    authDomain: "nuvex-gm-development.firebaseapp.com",
    messagingSenderId: "204990423698"
  })), provideAuth(() => getAuth()), provideAnalytics(() => getAnalytics()), ScreenTrackingService, UserTrackingService, provideFirestore(() => getFirestore()), provideMessaging(() => getMessaging()), provideStorage(() => getStorage()), provideRemoteConfig(() => getRemoteConfig()),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (iconRegistry: MatIconRegistry) => () => {
        const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
        const outlinedFontSetClasses = defaultFontSetClasses
          .filter((fontSetClass) => fontSetClass !== 'material-icons')
          .concat(['material-symbols-outlined']);
        iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
      },
      deps: [MatIconRegistry]
    }
  ]
};

// {
//   provide: APP_INITIALIZER,
//     multi: true,
//   useFactory: (iconRegistry: MatIconRegistry) => () => {
//   const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
//   const outlinedFontSetClasses = defaultFontSetClasses
//     .filter((fontSetClass) => fontSetClass !== 'material-icons')
//     .concat(['material-symbols-outlined']);
//   iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
// },
//   deps: [MatIconRegistry]
// }
