<div class="handler">
  @for (user of filteredUserList; track user.id; let i = $index) {
    <div class="item" [ngClass]="{selected: i === selectedIndex()}" (click)="selectPerson.emit(user)">
      <app-person-badge [disableTooltip]="true" [selected]="false" [user]="user"></app-person-badge> {{user.name}}
    </div>
  }
  @if(filteredUserList.length === 0){
    <div class="item">
      Nenhum usuário encontrado
    </div>
  }
</div>
