import {Pipe, PipeTransform} from '@angular/core';
import {ContactData} from './contact-table/contact-table.component';
import {Contact} from '../../models/contact';

export const condition = (value: ContactData | Contact) =>
  value.address?.trim() &&
  value.addressNumber?.trim() &&
  value.phone?.trim() &&
  value.email?.trim() &&
  (value.type !== 'PF' || value.birthday) &&
  (value.type !== 'PJ' || value.lineOfWork);

@Pipe({
  name: 'contactComplete'
})
export class ContactCompletePipe implements PipeTransform {
  transform(value: ContactData | Contact, ...args: unknown[]): string {
    if (condition(value)) {
      return 'Completo'
    }
    return 'Pendente'
  }

}

@Pipe({
  name: 'contactCompleteColor'
})
export class ContactCompleteColorPipe implements PipeTransform {

  transform(value: ContactData, ...args: unknown[]): string {
    if (condition(value)) {
      return '#4DBF8E'
    }
    return '#C83131'
  }

}
