<div class="task-container">
  <div class="page-header">
    <span class="page-title">Contatos</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Pesquise por nome ou identificador</mat-label>
      <input matInput (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <app-person-picker [people]="usersToShowBadges" (onSelectionChange)="personSelected($event)"></app-person-picker>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Bairro</mat-label>
      <mat-select [value]="selectedBairro" (selectionChange)="searchBairro($event)">
        <mat-option value="__all__">Todos</mat-option>
        @for (bairro of bairros; track bairro.id) {
          <mat-option [value]="bairro.name">{{ bairro.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div mat-ripple class="save" (click)="newContact()">
      <div>+ Novo contato</div>
    </div>
  </div>

  <div class="item-counter-list">
    @for (status of statusMap.keys(); track status) {
      <app-item-counter
        [title]="statusMap.get(status)?.name ?? ''"
        [value]="statusMap.get(status)?.count ?? 0"
        [titleColor]="statusMap.get(status)?.color ?? ''"
        [cardColor]="null"
        [textColor]="(statusMap.get(status)?.invertTitleColor ?? false) ? 'white' : 'black'"
      ></app-item-counter>
      @if (statusMap.get(status)?.showSeparator) {
        <div class="counter-separator"></div>
      }
    }
  </div>

  <app-contact-table [searchValue]="searchValue"  (filteredData)="filteredTableData($event)"
                     [searchBairro]="selectedBairro" (openDetails)="openDetails($event)"
                     [filterUsers]="usersToFilter"></app-contact-table>
</div>
