import {inject, Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NewUser, User} from '../../models/user';
import {getDownloadURL, ref, Storage} from '@angular/fire/storage';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';
import {OrganizationService} from './organization.service';

export type AdminUpdateUser = {
  name: string;
  phone?: string;
  birthday?: number;
  role: string;
  position: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //todo we shouldnt need this
  private organizationId = '01JDBB3NV8RCPJFQ11W6FXER1N';
  private currentUserId?: string;
  private url = environment.backendUrl;

  storage = inject(Storage);
  organizationService = inject(OrganizationService);

  public users = signal<User[]>([]);
  public activeUsers = signal<User[]>([]);

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient,
  ) {
    this.realtime.users.pipe(startWith(null))
      .subscribe(()=>{
        this.updateList();
      })
  }

  sortWithLogged(userId : string){
    this.currentUserId = userId;
    this.activeUsers.update((users) => {
      users.sort((a, b) => {
        if(a.id === userId) return -1;
        if(b.id === userId) return 1;
        return a.name.localeCompare(b.name);
      });
      return users;
    })
  }

  setOrg(orgId:string){
    this.organizationId = orgId;
  }

  async updateList() {
    const users = await this.getAllUsers();
    this.users.set(users);
    this.activeUsers.set(users.filter((user) => !user.deleted).sort((a, b) => {
      if(a.id === this.currentUserId) return -1;
      if(b.id === this.currentUserId) return 1;
      return a.name.localeCompare(b.name);
    }));
  }
  async getAllUsers() {
    const users = await lastValueFrom(this.http.get<User[]>(this.url+`/organizations/${this.organizationId}/users`));
    return this.getProfilePicture(users);
  }
  async getUser(userId: string) {
    const user = await lastValueFrom(this.http.get<User | null>(this.url+`/users/${userId}`));
    if (user == null) {
      throw new Error('User not found');
    }
    if (user.picture)
      user.picture = await getDownloadURL(ref(this.storage, user.picture));
    return user;
  }

  async createUser(user: NewUser) {
    const newUser = await lastValueFrom(this.http.post<User>(this.url+`/organizations/${this.organizationId}/user`, user));
    await this.updateList();
    return newUser;
  }

  async resetPassword(email: string) {
    await lastValueFrom(this.http.post<User>(this.url+`/auth/reset-password`, {email}));
    return;
  }

  async updateUser(id: string, user: Record<string, string | number> | User) {
    const newUser = await lastValueFrom(this.http.put<User>(this.url+`/users/${id}`, user));
    await this.getAllUsers()
    return newUser;
  }

  async updateUserBadgeColor(id: string, color: string) {
    await lastValueFrom(this.http.put<User>(this.url+`/users/${id}/badgeColor`, {badgeColor: color}));
    await this.getAllUsers()
    return;
  }

  async deleteUser(id: string) {
    const newUser = await lastValueFrom(this.http.delete<User>(this.url+`/users/${id}`));
    await this.updateList()
    return newUser;
  }

  async getProfilePicture(users: User[]) {
    const promises = [];
    for (const user of users) {
      if(user.picture) {
        promises.push(
        getDownloadURL(ref(this.storage, user.picture)).then((url) => {
          user.picture = url;
        }))
      }
    }
    await Promise.all(promises);
    return users;
  }

  async adminUpdateUser(id: string, user: AdminUpdateUser) {
    await lastValueFrom(this.http.put<User>(this.url+`/organizations/${this.organizationService.currentOrganizationId}/user/${id}`, user));
    await this.getAllUsers();
  }
}
