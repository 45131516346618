import {Injector, ApplicationRef, ComponentFactoryResolver, Injectable} from '@angular/core';
import {MentionListComponent} from '../mention-list/mention-list.component';

@Injectable({
  providedIn: 'root'
})
export class SuggestionRenderer {
  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private resolver: ComponentFactoryResolver
  ) {}
  close(){
    if(this.componentRef)
      this.componentRef.destroy();
  }
  componentRef: any;
  renderSuggestions(filter: string, selected: number | null, container: HTMLElement, callback: Function) {
    const factory = this.resolver.resolveComponentFactory(MentionListComponent);
    const componentRef = factory.create(this.injector);
    this.componentRef = componentRef;
    componentRef.instance.filter.set(filter);
    if(selected !== null) {
      componentRef.instance.selectedIndex.set(selected);
    }
    componentRef.instance.selectPerson.subscribe((selectedItem: any) => {
      callback(selectedItem);
    });

    this.appRef.attachView(componentRef.hostView);

    container.appendChild((componentRef.hostView as any).rootNodes[0]);
  }
}
