  <span class="title">{{ title() }}</span>
<section class="filter-container" [formGroup]="toppings">
  <!--  <p><mat-checkbox formControlName="today">Hoje</mat-checkbox></p>-->
<!--  @if (showLate()) {-->
<!--    <p><mat-checkbox formControlName="late">Atrasado</mat-checkbox></p>-->
<!--  }-->
  <p class="until"><mat-checkbox formControlName="until">Até</mat-checkbox></p>
  <mat-form-field subscriptSizing="dynamic" class="date-filter">
    <input [formControl]="dateControl" matInput [matDatepicker]="picker" placeholder="-- / -- / --">
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</section>
