import {UserRef} from './user';

export type EventType = "visit" | "meeting" | "get-together" | "out-of-office";

export const eventTypes = [
  {value: 'meeting', label: 'Reunião'},
  {value: 'get-together', label: 'Confraternização'},
  {value: 'out-of-office', label: 'Ausente'},
  {value: 'visit', label: 'Visita'}];

export const eventTypeEnum = {
  'meeting': 'Reunião',
  'get-together': 'Confraternização',
  'out-of-office': 'Ausente',
  'visit': 'Visita'
}

export type Event = {
  id: string;
  name: string;
  type: EventType;
  location?: string;
  description: string;
  date?: number;
  startTime?: number;
  endTime?: number;
  createdByUserId: string;
  users: UserRef[];
  // deletedAt?: number;
}

