import {Component, EventEmitter, input, Output, signal} from '@angular/core';
import {StatusBadgeComponent} from '../status-badge/status-badge.component';

@Component({
  selector: 'app-status-badge-list',
  imports: [
    StatusBadgeComponent
  ],
  templateUrl: './status-badge-list.component.html',
  styleUrl: './status-badge-list.component.scss'
})
export class StatusBadgeListComponent {
  statusList = signal<string[]>([]);
  @Output() closePanel = new EventEmitter<string>();
  protected readonly close = close;
}
