<div class="login-page">
  <div class="image-container">
    <div class="image">
      <img ngSrc="assets/images/jardim-botanico-login-page.png" height="1080" width="1089"/>
      <div class="overlay"></div>
    </div>
    <span class="title">GPC</span>
  </div>
  <div class="login-container">
    <span class="title" [style]="{width: mode==='forgot'? '470px' : '279px'}">{{title[mode]}}</span>
    <div class="form-container">
      @if( ['login', 'forgot'].includes(mode) ) {
      <span>Email:</span>
      <mat-form-field>
        <input (keyup.enter)="action()" [formControl]="email" matInput type="email" [placeholder]="'nome@email.com'" >
        @if (email.invalid) {
          <mat-error>{{errorMessage().email}}</mat-error>
        }
      </mat-form-field>
      }
      @if( ['login', 'resetPassword'].includes(mode) ) {
      <span>Senha:</span>
      <mat-form-field>
        <input (keyup.enter)="action()"  [formControl]="password" matInput [type]="passwordVisible ? 'text' : 'password'" placeholder="***********">
        <mat-icon (click)="passwordVisible = !passwordVisible"  matSuffix [fontIcon]="passwordVisible ? 'visibility_off' : 'visibility'"></mat-icon>
        @if (password.invalid) {
          <mat-error>{{errorMessage().password}}</mat-error>
        }
      </mat-form-field>
      }
    </div>
    <span *ngIf="mode === 'login'" class="forgot-password" (click)="forgotPassword()" >Esqueceu a senha?</span>
    <div class="button-container">
      <button *ngIf="mode !== 'login'" class="button back" (click)="back()">
          <span>Voltar</span>
      </button>
      <button type="submit" class="button action" (click)="action()">
        @if (isLoading()){
          <mat-spinner diameter="25" color="red"></mat-spinner>
        } @else {
          <span>{{mode === 'login' ? 'Entrar' : 'Enviar'}}</span>
        }
      </button>
    </div>
  </div>
</div>
