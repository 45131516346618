@if (profilePicture) {
  <div
    [ngStyle]="{'background-color': 'rgba(0,0,0,0.5)','border': selected ? '1px solid #000' : 'unset', width: circleSize, height: circleSize} "
    class="circle">
    <img ngSrc="{{profilePicture}}" fill>
  </div>
} @else {
  @if (placeholderNumber() > 0) {
    <div class="outer-circle" [ngStyle]="{'border': selected ? '1px solid #FBC00E' : 'unset'}">
      <div
        [ngStyle]="{'background-color': '#F5F5F5', 'border': selected ? '1px solid #fff' : 'unset', width: circleSize, height: circleSize, 'font-size': fontSize} "
        class="circle" style="color:#000;">+{{ placeholderNumber() }}
      </div>
    </div>
  } @else {
    <div class="outer-circle" [ngStyle]="{'border': selected ? '1px solid #FBC00E' : 'unset'}">
      <div [matTooltip]="user()?.name || 'Sistema'" [matTooltipShowDelay]="300" [matTooltipDisabled]="disableTooltip"
           [ngStyle]="{'background-color': user()?.badgeColor, 'border': selected ? '1px solid #fff' : 'unset', width: circleSize, height: circleSize, 'font-size': fontSize} "
           class="circle">{{ getInitials(user()?.name) }}
      </div>
    </div>
  }
}
