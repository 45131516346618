import {Component, effect, input, Input, output, Output, signal} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-toogle-select',
  imports: [
    NgClass
  ],
  templateUrl: './toogle-select.component.html',
  standalone: true,
  styleUrl: './toogle-select.component.scss'
})
export class ToogleSelectComponent {

  @Input({required: true}) options: string[] = [];
  change = output<boolean>();
  selected = input.required<boolean>();
  _selected: boolean = false;

  constructor() {
    effect(() => {
      this._selected = this.selected();
    });
  }

  select(value: boolean){
    if (this._selected === value) return;
    this._selected = value;
    this.change.emit(value);
  }

  toggle(){
    this._selected =  !this._selected;
    this.change.emit(this._selected );

  }
}
