<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th class="id-column" mat-header-cell *matHeaderCellDef mat-sort-header> Protocolo </th>
      <td class="id-column"  mat-cell *matCellDef="let row"> {{row.protocol}} </td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th class="timestamp-column" mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
      <td class="timestamp-column" mat-cell *matCellDef="let row"> {{row.timestamp | gm_date}} </td>
    </ng-container>


    <ng-container matColumnDef="type">
      <th class="type-column" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
      <td  class="type-column" mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="_address">
      <th class="address-column" mat-header-cell *matHeaderCellDef mat-sort-header> Endereço </th>
      <td class="address-column" mat-cell *matCellDef="let row"> {{row.address | emptyView}} </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contato </th>
      <td mat-cell *matCellDef="let row">
        <section class="relationship-cell">
        @if (row.contact?.name == null) {
          <p class="add-assignee">Contato removido</p>
        } @else {
          <mat-icon fontIcon="support_agent"></mat-icon>
          <a [href]="window.location.origin + '/contatos/' + row.contact?.id">{{row.contact?.name}}</a>
          <mat-icon class="open_new_new_icon" fontIcon="open_in_new"></mat-icon>
        }</section>
      </td>
    </ng-container>

    <ng-container matColumnDef="_assignee">
      <th class="assignee-column" mat-header-cell *matHeaderCellDef mat-sort-header> Responsável </th>
      <td class="assignee-column" mat-cell *matCellDef="let row">
        @if (row.assigneeUser != null) {
          <section class="assignee-cell">
            <app-person-badge [selected]="false" [user]="row.assigneeUser" [disableTooltip]="true"></app-person-badge>
            <p>{{row.assigneeUser.name}}</p>
          </section>
        } @else {
          @if(row.loadingAssignee) {
            <mat-progress-spinner [diameter]="25" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
          } @else {
            <p class="add-assignee" (click)="openDialog(row)">+ Clique para adicionar</p>
          }
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="status-column" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td class="status-column" mat-cell *matCellDef="let row"> <app-status-badge [nameId]="row.status" [type]="'cs'"></app-status-badge></td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th class="open-column"  mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td class="open-column"  mat-cell *matCellDef="let row" > <button mat-icon-button (click)="openDetails.emit(row.id)"> <mat-icon fontIcon="open_in_full"></mat-icon></button> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum atendimento encontrado</td>
    </tr>
  </table>
<!--  <div mat-row class="mat-mdc-row" style="display: none" ></div>-->
<!--  @for(a of [].constructor(6); let i =$index; track i) {-->
<!--    <div mat-row  class="mat-mdc-row"></div>-->
<!--  }-->
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página dos atendimentos"></mat-paginator>
</div>
