import {Component, effect, inject, input, OnDestroy, OnInit} from '@angular/core';
import {PersonBadgeComponent} from '../../../../common/person-picker/person-badge/person-badge.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommentService} from '../../../../services/comment.service';
import {Comment} from '../../../../../models/comment';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {GMDatePipe} from '../../../../common/date.pipe';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../../models/user';
import {ulid} from 'ulid';
import dayjs from 'dayjs';
import {SkeletonComponent} from './skeleton/skeleton.component';
import {Editor, NgxEditorModule, Toolbar} from 'ngx-editor';
import {RealtimeService} from '../../../../services/realtime.service';
import {getPlugins} from '../../../../common/ngx-editor-pluggins/get-plugins';
import schema from '../../../../common/ngx-editor-pluggins/schema';
import {SuggestionRenderer} from '../../../../common/ngx-editor-pluggins/suggestion-renderer';
import {user} from '@angular/fire/auth';

export type CommentData = Comment & {
  user?: User;
  loading: boolean;
}

@Component({
  selector: 'app-comments',
  imports: [
    PersonBadgeComponent,
    ReactiveFormsModule,
    MatProgressSpinner,
    GMDatePipe,
    SkeletonComponent,
    NgxEditorModule,
    FormsModule,
  ],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss'
})
export class CommentsComponent implements OnInit, OnDestroy {
  gmAuthService = inject(AuthService);

  currentUser?: User;

  html = '';

  loading = false;
  commentsService: CommentService = inject(CommentService);
  taskId = input.required<string>();
  users = input.required<User[]>();
  comments: CommentData[] = [];
  suggestionsHandler = inject(SuggestionRenderer);

  constructor(
    private realtime: RealtimeService
  ) {
    effect(() => {
      this.currentUser = this.gmAuthService.loggedUser() ?? undefined;
    })
    this.realtime.comments.subscribe(()=>{
      this.updateComments();
    })
  }

  editor?: Editor;
  toolbar?: Toolbar;

  ngOnInit(): void {
    this.editor = new Editor({
      schema,
      plugins: getPlugins(this.suggestionsHandler, this.users())
    });
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['text_color', 'background_color'],
    ];

    this.loading = true;
    this.updateComments();
  }

  cancelComment() {
    this.html = '';
    setTimeout(() => {
      this.html = '';
    }, 0);
  }

  async saveComment() {
    const comment: CommentData = {
      id: ulid(),
      relationshipId: this.taskId(),
      userId: this.currentUser?.id ?? '',
      message: this.html,
      metadata: this.html,
      timestamp: dayjs().unix(),
      loading: true,
      user: this.currentUser
    }
    this.cancelComment();
    this.comments.unshift(comment);
    this.commentsService.insertTaskComment(this.taskId(), comment.message).then(() => {
      this.updateComments();
    });
  }

  updateComments() {
    this.commentsService.getAllTaskComments(this.taskId()).then((comments) => {
      this.comments = comments.reverse().map((comment) => {
        return {
          ...comment,
          user: this.users().find((user) => user.id === comment.userId),
          loading: false
        };
      });
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.suggestionsHandler.close()
  }

}
