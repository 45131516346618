import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {MatGridList, MatGridListModule, MatGridTile} from '@angular/material/grid-list';
import {OverlayModule} from '@angular/cdk/overlay';
import dayjs from 'dayjs';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {TaskService} from '../services/task.service';
import {GMDatePipe} from '../common/date.pipe';
import {environment} from '../../environments/environment';
import {UserService} from '../services/user.service';
import {TaskData} from '../tasks/tasks/task-table/task-table.component';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {User} from '../../models/user';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ToogleSelectComponent} from '../common/toogle-select/toogle-select.component';
import {DayCalendarComponent} from './day-calendar/day-calendar.component';
import {NgClass} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {Event} from '../../models/event';
import {CreateEventComponent} from './create-event/create-event.component';
import {EventService} from '../services/event.service';
import {EventOverlayComponent} from './event-overlay/event-overlay.component';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ContactService} from '../services/contact.service';
import {Contact} from '../../models/contact';
import {AuthService} from '../services/auth.service';

export interface Tile {
  date?: dayjs.Dayjs;
  color: string;
  cols: number;
  rows: number;
  text: string;
  header?: string;
  tileEvents: TileEvent[];
  otherMonth: boolean;
  overflow?: number;
  today?: boolean;
}

export type TileEvent = {
  id: string;
  type: string;
  color?: string;
  date: dayjs.Dayjs;
  title: string;
  isOpened: boolean;
  link?: string;
  users: User[];
  allDay: boolean;
  meta: any;
}

export type CalendarTaskData = TaskData & {
  isOpened: boolean;
};

@Component({
  selector: 'app-calendar',
  imports: [
    MatGridList,
    MatGridTile,
    OverlayModule,
    MatIconButton,
    MatIcon,
    GMDatePipe,
    PersonPickerComponent,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ToogleSelectComponent,
    DayCalendarComponent,
    NgClass,
    EventOverlayComponent,
    MatGridTile,
    MatGridListModule
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements OnInit {
  firstRow: Tile[] = [];
  tiles: Tile[] = [];
  columns: Tile[] = [];
  typesOfEvent: {value:string, label:string}[] = [
    {value: 'all', label:'Todas'},
    {value: 'tasks', label:'Tarefas'},
    {value: 'birthdays', label:'Aniversarios'},
  ];
  selectedType = 'all';

  currentDate = dayjs();

  taskService = inject(TaskService);
  eventService = inject(EventService);
  userService = inject(UserService);
  contactService = inject(ContactService);
  activatedRoute = inject(ActivatedRoute);
  authService = inject(AuthService);
  location = inject(Location);
  allTasks: CalendarTaskData[] = [];
  allEvents: Event[] = [];
  users: User[] = [];
  allContacts: Contact[] = [];

  userFilter: string[] = [];
  idFromRoute: string | null = null;

  isDayView = false;
  todayEvents = signal<TileEvent[]>([])

  constructor() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.isDayView = params['mode'] === 'day';
    });

    this.activatedRoute.paramMap.subscribe((param) => {
      this.idFromRoute = param.get('eventId');
      if (this.idFromRoute != null) {
        setTimeout(() => {
          this.idFromRoute = null
        }, 5000) // xunxera
      }
    });
    const weekDays = [
      'DOM',
      'SEG',
      'TER',
      'QUA',
      'QUI',
      'SEX',
      'SAB',
    ]
    for (const weekDay of weekDays) {
      this.columns.push({
        text: weekDay,
        cols: 1,
        rows: 1,
        color: 'lightblue',
        tileEvents: [],
        otherMonth: false
      });
    }

    effect(() => {
      this.users = this.userService.users();
      this.allEvents = this.eventService.eventList();
      this.allContacts = this.contactService.contactList()
        .filter((contact) => contact.type === 'PF' && !contact.deletedAt && contact.birthday);
      this.allTasks = this.taskService.taskList().map((task) => {
        return {
          ...task,
          assignee: this.users.find((user) => user.id === task.assigneeUserId),
          isOpened: false
        }
      });
      this.updateMatrix();
      if(this.userFilter.length === 0 && this.authService.loggedUser()) {
        this.userFilter = [this.authService.loggedUser()?.id!];
      }
    });
  }

  readonly dialog = inject(MatDialog);

  openDialog(eventId?: string): void {
    const dialogRef = this.dialog.open(CreateEventComponent, {
      width: '840px',
      maxWidth: '60vw',
      height: '521px',
      maxHeight: '70vh',
      data: this.allEvents.find((event) => event.id === eventId),
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed();
  }


  changeView(selected: boolean) {
    this.isDayView = selected;this.location.go('/atendimentos')
    this.location.go('/calendario?mode=' + (selected ? 'day' : 'month'));
  }

  changeType(selected: MatSelectChange) {
    this.selectedType = selected.value;
    this.updateMatrix();
  }


  ngOnInit(): void {
    this.updateMatrix();
    if (this.idFromRoute) {
      const event = this.allEvents.find((event) => event.id === this.idFromRoute);
      this.setDay(dayjs(event?.date));
    }
  }

  updateMatrix() {
    const mm = this.generateMonthMatrix(this.currentDate.year(), this.currentDate.month() + 1);
    this.tiles = [];
    this.firstRow = [];
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 7; j++) {
        let tileEvents = this.parseDayEvents(mm[i][j]!);
        let overflow = 0;
        let today = false;
        if (dayjs(this.currentDate)?.isSame(mm[i][j]!, 'day')) {
          this.todayEvents.set(tileEvents);
          today = true;
        }
        if (tileEvents.length > 4) {
          overflow = tileEvents.length - 4;
          tileEvents = tileEvents.slice(0, 4);
        }
        const list = i === 0 ? this.firstRow : this.tiles;

        list.push({
          date: mm[i][j]!,
          text: String(mm[i][j]?.format('DD')),
          header: this.columns[j].text,
          cols: 1,
          rows: 1,
          color: 'lightblue',
          tileEvents,
          otherMonth: !dayjs(this.currentDate)?.isSame(mm[i][j]!, 'month'),
          overflow,
          today
        })
      }
    }
  }

  parseDayEvents(date: dayjs.Dayjs): TileEvent[] {
    const tileEvents: TileEvent[] = [];
    const noFilter = this.userFilter.length === 0;
    if (['all'].includes(this.selectedType)) {
      tileEvents.push(...this.allEvents.filter((event) => {
        return event.date &&
          event.name &&
          [event.type, 'all'].includes(this.selectedType) &&
          dayjs.unix(event.date)?.isSame(date, 'day') &&
          (noFilter || event.users.some((user) => this.userFilter.includes(user.userId)));
      }).map((a) => this.parseEvents(a)))
    }

    if (['all', 'birthdays'].includes(this.selectedType)) {
      tileEvents.push(...this.userService.users().filter((user) => {
        return user.birthday &&
          dayjs(user.birthday as string).isSame(date, 'day') &&
          dayjs(user.birthday as string).isSame(date, 'month') &&
          (noFilter || this.userFilter.includes(user.id));
      }).map((a) => this.parseBirthday(a)))
      tileEvents.push(...this.allContacts.filter((contact) => {
        return contact.birthday &&
          dayjs(contact.birthday).isSame(date, 'day') &&
          dayjs(contact.birthday).isSame(date, 'month');
      }).map((a) => this.parseContactBirthday(a)))
    }

    if (['all', 'tasks'].includes(this.selectedType)) {
      tileEvents.push(...this.allTasks.filter((task) => {
        return task.deadline &&
          task.name &&
          dayjs(task.deadline)?.isSame(date, 'day') &&
          (!task.assigneeUser?.id || noFilter || this.userFilter.includes(task.assigneeUser!.id!));
      }).map((a) => this.parseTask(a)))
    }

    return tileEvents;
  }

  parseTask(task: CalendarTaskData): TileEvent {
    return {
      id: task.id,
      type: "task",
      color: task.assigneeUser?.badgeColor,
      date: dayjs(task.deadline),
      title: 'Tarefa: ' + task.name,
      isOpened: false,
      link: '/tarefas/' + task.id,
      users: task.assigneeUser ? [task.assigneeUser] : [],
      meta: task,
      allDay: true
    }
  }

  parseBirthday(user: User): TileEvent {
    return {
      id: user.id,
      type: "birthday",
      color: user.badgeColor,
      date: dayjs(user.birthday),
      title: 'Aniversário: ' + user.name,
      isOpened: false,
      users: [user],
      meta: user,
      allDay: true
    }
  }

  parseContactBirthday(contact: Contact): TileEvent {
    return {
      id: contact.id,
      type: "birthday",
      color: this.authService.loggedUser()?.badgeColor,
      date: dayjs(contact.birthday),
      title: 'Aniversário Contato: ' + contact.name,
      isOpened: false,
      users: [contact.assigneeUser!],
      link: '/contatos/' + contact.id,
      meta: contact,
      allDay: true
    }
  }

  parseEvents(event: Event): TileEvent {
    const users = this.users
      .filter((user) => event.users.find(u => u.userId === user.id))
    let title = event.name;
    if (event.startTime) {
      const startTime = dayjs.unix(event.startTime);
      if (startTime.minute() === 0) {
        title = `${startTime.format('HH')} ${title}`;
      } else {
        title = `${startTime.format('HH:mm')} ${title}`;
      }
    }

    if (event.id === this.idFromRoute) {

    }
    return {
      id: event.id,
      type: "event",
      color: users[0]?.badgeColor,
      date: dayjs.unix(event.date!),
      title: title,
      isOpened: event.id === this.idFromRoute,
      users: users,
      allDay: !event.startTime,
      meta: event
    }
  }

  generateMonthMatrix(year: number, month: number) {
    const startOfMonth = dayjs(`${year}-${month}-01`);
    const daysInMonth = startOfMonth.daysInMonth();
    const firstDayOfWeek = startOfMonth.day(); // 0 for Sunday, 1 for Monday, etc.
    const prevMonthDays = startOfMonth.clone().subtract(1, 'month').daysInMonth();
    // const nextMonthStart = startOfMonth.clone().add(1, 'month');

    const matrix: (dayjs.Dayjs | null)[][] = Array.from({length: 5}, () => Array(7).fill(null));

    let dayCounter = 1;
    let row = 0;

    for (let col = firstDayOfWeek - 1; col >= 0; col--) {
      const prevMonth = startOfMonth.clone().subtract(1, 'month');
      matrix[row][col] = dayjs(`${prevMonth.year()}-${prevMonth.month() + 1}-${prevMonthDays - (firstDayOfWeek - 1 - col)}`);
      if (row === 0) {
        this.columns[col].otherMonth = true;
      }
    }

    for (let col = firstDayOfWeek; col < 7; col++) {
      matrix[row][col] = dayjs(`${year}-${month}-${dayCounter++}`);
      // dayCounter++;
    }
    row++;

    while (dayCounter <= daysInMonth) {
      for (let col = 0; col < 7 && dayCounter <= daysInMonth; col++) {
        matrix[row][col] = dayjs(`${year}-${month}-${dayCounter++}`);
      }
      row++;
    }

    let nextMonthDay = 1;
    for (let col = 0; col < 7; col++) {
      if (matrix[row - 1] && matrix[row - 1][col] === null) {
        const nextMonth = startOfMonth.clone().add(1, 'month');
        matrix[row - 1][col] = dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${nextMonthDay++}`);
      }
    }

    return matrix;
  }

  previous() {
    const time = this.isDayView ? 'day' : 'month';
    this.currentDate = this.currentDate.subtract(1, time);
    this.updateMatrix();
  }

  next() {
    const time = this.isDayView ? 'day' : 'month';
    this.currentDate = this.currentDate.add(1, time);
    this.updateMatrix();
  }

  setDay(date?: dayjs.Dayjs) {
    if (!date) {
      date = dayjs();
    }
    this.currentDate = dayjs(date);
    this.updateMatrix();
  }

  goToDay(tile: any) {
    this.setDay(tile.date);
    this.isDayView = true;
  }

  personSelected(users: BadgeUserData[]) {
    const selected = [];
    for (const user of users) {
      if (user.selected) {
        selected.push(user.id);
      }
    }
    this.userFilter = selected;
    this.updateMatrix();
  }

  protected readonly environment = environment;
  protected readonly dayjs = dayjs;
  protected readonly Math = Math;
}
