<div class="task-container">
  <div class="page-header">
    <span class="page-title">Atendimentos</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Pesquise por protocolo, contato ou tipo</mat-label>
      <input matInput [value]="filterValue" (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <app-person-picker [people]="usersToShowBadges" (onSelectionChange)="personSelected($event)"></app-person-picker>
    <app-deadline-filter [title]="'Cadastro:'" [showLate]="false" (onChange)="timestampChange($event)"
                         (onDateChange)="timestampDateChange($event)"></app-deadline-filter>
    <div mat-ripple class="save" (click)="newTask()">
      <div>+ Novo atendimento</div>
    </div>
  </div>

  <div class="item-counter-list">
    @for (status of statusMap.keys(); track status) {
      <app-item-counter
        [title]="statusMap.get(status)?.name ?? ''"
        [value]="statusMap.get(status)?.count ?? 0"
        [titleColor]="statusMap.get(status)?.color ?? ''"
        [textColor]="(statusMap.get(status)?.invertTitleColor ?? false) ? 'white' : 'black'"
      ></app-item-counter>
      @if (statusMap.get(status)?.showSeparator) {
        <div class="counter-separator"></div>
      }
    }
  </div>
  <app-cs-table [values]="services" [searchValue]="searchValue" [filterToday]="filterToday" [filterUntil]="filterUntil ? filterDate : undefined"
                  (openDetails)="openDetails($event)" (filteredData)="filteredTableData($event)"
                  [filterUsers]="usersToFilter" [toBeDeleted]="toBeDeleted"></app-cs-table>
</div>
