<script src="user-details.component.ts"></script>
<div class="header">
  <app-delete-confirmation
    [hidden]="newUser || authService.loggedUser()?.id === data?.id"
    [deleting]="deletingUser()"
    (deleteConfirmation)="deleteUser()"
  />
  <H3>
    {{ newUser ? 'Novo Usuário' : data?.name }}
  </H3>

  <button class="close" mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
  <div>
  </div>
</div>
<mat-dialog-content>

  <div class="content">
    <div class="picture">
      <app-person-badge [profilePicture]="data?.picture" [size]="171" [selected]="false"
                        [user]="data"></app-person-badge>
<!--      @if (this.data) {-->
<!--        <div mat-ripple class="upload" (click)="fileInput.click()">-->
<!--          <mat-icon fontIcon="upload"></mat-icon>-->
<!--          <div>{{ newUser ? 'Adicionar Imagem' : 'Substituir' }}</div>-->
<!--        </div>-->
<!--      }-->

<!--      <input #fileInput type="file" hidden (change)="fileUpload($event)" accept=".png,.jpg,.jpeg">-->
      <div class="color-picker">
        <div class="color-grid">
          @for (color of badgeColors; track color) {
            <div class="color-holder">
              <div class="color-option" (click)="selectColor(color)"
                   [style]="{background: color, 'border-color': color}"
                   [ngClass]="{'color-selected': data?.badgeColor === color}"></div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="info">
      @for (dataPoint of dataPoints(); track dataPoint.label) {
        <div class="info-line" [style.height]="data?.id ? '55px': '76px'">
          <div class="info-label">{{ dataPoint.label }}:</div>
          @if (!data?.id || dataPoint.editing) {
            @if (!data?.id) {
              <div class="form-field-holder">
                <mat-form-field>
                  <mat-label>{{ newUser ? '' : 'Editar' }} {{ dataPoint.label }}</mat-label>
                  <input matInput [formControl]="dataPoint.control" #input
                         [required]="dataPoint.required">
                  @for (error of parseFormErrors(dataPoint.control.errors); track error) {
                    <mat-error>
                      {{ error }}
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            } @else {
              <app-editable-input [value]="dataPoint.control.value || ''" [formName]="'Editar ' + dataPoint.label"
                                  (onSave)="toggleEdit(dataPoint.label, $event)" [validators]="dataPoint.validators"
                                  (onCancel)="toggleEdit(dataPoint.label)"></app-editable-input>
            }
          } @else {
            <div class="info-value" (click)="toggleEdit(dataPoint.label)"
                 [style]="{color: dataPoint.editable ? 'black' : '#8F9295'}">{{ dataPoint?.control?.value || '--' }}
            </div>
          }
        </div>
      }
      <!--      @if (!newUser) {-->
      <div class="info-line" style="height: 73px">
        <div class="info-label">Aniversário:</div>
        <div class="form-field-holder">
          <mat-form-field>
            <mat-label>Selecione uma data</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="datePicker">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!--      }-->
      @if (!newUser) {
        <div mat-ripple class="reset-password" (click)="resetPassword()">
          @if (resetingPassword()) {
            <mat-spinner diameter="25" color="black"></mat-spinner>
          } @else {
            <mat-icon class="icon" fontIcon="lock_reset"></mat-icon>
          }
          <div>Resetar Senha</div>
        </div>
      }

      @if (newUser) {
        <div style="display: flex; flex-direction: row-reverse; margin: 20px; gap: 5px">
          <app-button-w-loader
            [disabled]="!formGroup.valid"
            [loading]="saving"
            (click)="save()"
            type="yellow" label="Salvar"
          />
          <app-button-w-loader
            (click)="cancel()"
            type="outline" label="Cancelar"
          />
        </div>
      }
    </div>
  </div>

</mat-dialog-content>
