import {inject, Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NewUser, User} from '../../models/user';
import {getDownloadURL, ref, Storage} from '@angular/fire/storage';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private organizationId = '01JDBB3NV8RCPJFQ11W6FXER1N';
  private url = environment.backendUrl;

  storage = inject(Storage);

  public users = signal<User[]>([]);

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.users.pipe(startWith(null))
      .subscribe(()=>{
        this.updateList();
      })
  }

  async updateList() {
    const users = await this.getAllUsers();
    this.users.set(users);
  }
  async getAllUsers() {
    const users = await lastValueFrom(this.http.get<User[]>(this.url+`/organizations/${this.organizationId}/users`));
    return this.getProfilePicture(users);
  }
  async getUser(userId: string) {
    const user = await lastValueFrom(this.http.get<User | null>(this.url+`/users/${userId}`));
    if (user == null) {
      throw new Error('User not found');
    }
    if (user.picture)
      user.picture = await getDownloadURL(ref(this.storage, user.picture));
    return user;
  }

  async createUser(user: NewUser) {
    const newUser = await lastValueFrom(this.http.post<User>(this.url+`/organizations/${this.organizationId}/user`, user));
    this.users.update((users) => {
      users.push(newUser);
      return [...users];
    });
    return newUser;
  }

  async resetPassword(email: string) {
    await lastValueFrom(this.http.post<User>(this.url+`/auth/reset-password`, {email}));
    return;
  }

  async updateUser(id: string, user: Record<string, string | number>) {
    const newUser = await lastValueFrom(this.http.put<User>(this.url+`/users/${id}`, user));
    this.users.update((users) => {
      const index = users.findIndex((user) => user.id === id);
      users[index] = newUser;
      return users;
    });
    return newUser;
  }

  async deleteUser(id: string) {
    const newUser = await lastValueFrom(this.http.delete<User>(this.url+`/users/${id}`));
    this.users.update((users) => {
      const index = users.findIndex((user) => user.id === id);
      users.splice(index, 1);
      return [...users];
    });
    return newUser;
  }

  async getProfilePicture(users: User[]) {
    const promises = [];
    for (const user of users) {
      if(user.picture) {
        promises.push(
        getDownloadURL(ref(this.storage, user.picture)).then((url) => {
          user.picture = url;
        }))
      }
    }
    await Promise.all(promises);
    return users;
  }
}
