<section class="type-comment">
  <app-person-badge [user]="currentUser" [selected]="false"></app-person-badge>
  <!--  <mat-form-field>-->
  <!--    <mat-label>Adicione um comentário</mat-label>-->
  <!--    <input [mentions]="['a', 'b', 'c']" (keyup.enter)="saveComment()" matInput placeholder="Ex: aqui estão os detalhes da tarefa" #input [formControl]="fieldControl">-->
  <!--  </mat-form-field>-->
  @if (editor && toolbar) {
    <div style="width: 100%;">
      <ngx-editor-menu
        [editor]="editor"
        [toolbar]="toolbar!"

      ></ngx-editor-menu>
      <ngx-editor [editor]="editor" [(ngModel)]="html"></ngx-editor>
    </div>
  }
</section>
@if (html.length > 0) {
  <div class="buttons">
    <div type="submit" (keyup.enter)="saveComment()" class="save" (click)="saveComment()">
      <div>Salvar</div>
    </div>
    <div class="cancel" (click)="cancelComment()">
      <div>Cancelar</div>
    </div>
  </div>
}
@if (loading) {
  <app-skeleton></app-skeleton>
}
<div class="list-container" [style.max-height]="html.length > 0 ? '160px' : '230px'">
  @for (comment of comments; track comment.id) {
    <div class="list-item">
      <app-person-badge [user]="comment.user" [selected]="false"></app-person-badge>
      <div class="item-text">
        <span class="item-title">{{ comment.user?.name }}
          @if (comment.loading) {
            <mat-spinner class="date" diameter="20" color="black"></mat-spinner>
          } @else {
            <span class="date">{{ comment.timestamp | gm_date:'timestamp' }}</span>
          }
        </span>
        <span class="item-description" [innerHTML]="comment.message"></span>
      </div>
    </div>
  }
</div>
