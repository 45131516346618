import { Injectable } from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Task} from '../../models/task';
import {Comment} from '../../models/comment';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) {

  }

  getAllTaskComments(taskId: string) {
    return lastValueFrom(this.http.get<Comment[]>(`${environment.backendUrl}/tasks/${taskId}/comments`));
  }

  insertTaskComment(taskId: string, comment: string) {
    return lastValueFrom(this.http.post<{id: string}>(`${environment.backendUrl}/tasks/${taskId}/comments`, {message: comment, metadata: ''}));
  }
}
