export const environment = {
  production: false,
  disableAuth: false,
  backendUrl: "https://gm-development.nuvex.workers.dev",
  frontendUrl: "https://gm-staging.nuvex.dev",
  firebaseConfig: {
    apiKey: "AIzaSyBaSrqc6A9U9Hg7zZbj8RpXIYMd7cVA3fw",
    authDomain: "nuvex-gm-development.firebaseapp.com",
    projectId: "nuvex-gm-development",
    storageBucket: "nuvex-gm-development.appspot.com",
    appId: "nuvex-gm-development",
  },
};
