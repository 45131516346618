import {AfterViewInit, Component, EventEmitter, Input, model, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {MatCard} from '@angular/material/card';
import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS} from '../../utils';
import {interval, map} from 'rxjs';
import dayjs, {Dayjs} from 'dayjs';
import {TileEvent} from '../calendar.component';
import {EventOverlayComponent} from '../event-overlay/event-overlay.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';

type DayEvent = TileEvent & {
  position: number;
  height: number;
}


@Component({
  selector: 'app-day-calendar',
  imports: [
    MatCalendar,
    MatCard,
    EventOverlayComponent,
    CdkOverlayOrigin,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './day-calendar.component.html',
  standalone: true,
  styleUrl: './day-calendar.component.scss'
})
export class DayCalendarComponent implements AfterViewInit, OnChanges {

  @Input({required: true}) day: Dayjs = dayjs();
  @Input({required: true}) todayEvents: TileEvent[] = [];
  @Output() dayChange = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();

  selectedDay = model<Date | null>(new Date);

  allDayEvents: TileEvent[] = [];

  hours = Array.from({length: 24}, (_, i) => i);

  nowPosition: string = '0px';

  clock$ = interval(1000);

  constructor() {
    this.positionNow();
    this.updateTasks();
    this.clock$.subscribe((minuteCount) => {
      this.positionNow()
    });
  }

  eventArray: DayEvent[][] = [[]];

  positionNow() {
    const now = new Date();
    let a = now.getHours() * 60 + now.getMinutes() + 60;
    a = a * 1.6666;
    if (a > 2400) {
      a = a - 2400;
    }
    this.nowPosition = `${a}px`;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const container = document.getElementById('day');
      const element = document.getElementById('now');
      if (container && element) {
        const offset = 100; // Adjust the offset
        const elementPosition = element.offsetTop; // Position of the element relative to its container
        const offsetPosition = elementPosition - offset;
        container.scrollTo({top: offsetPosition, behavior: 'instant'});
      }
    }, 1)
  }

  ngOnChanges(changes:SimpleChanges): void {
    if(changes['day']){
      this.selectedDay.set(dayjs(this.day).toDate());
    }
    this.updateTasks();
  }

  asDayEvent(event: TileEvent): DayEvent {
    const start = this.timeToVertical(event.meta.startTime!);
    const end = this.timeToVertical(event.meta.endTime!);
    return {
      ...event,
      position: start,
      height: end - start
    }
  }

  timeToVertical(time: number) {
    const timeJs = dayjs.unix(time);
    let a = timeJs.hour() * 60 + timeJs.minute() + 60;
    a = a * 1.6666;
    if (a > 2400) {
      a = a - 2400;
    }
    return a;
  }

  openDialog(eventId: string) {
    this.edit.emit(eventId);
  }

  updateTasks() {
    this.allDayEvents = [];
    this.eventArray = [[], []];
    this.todayEvents.forEach((event) => {
      if (event.allDay) {
        this.allDayEvents.push(event);
        return;
      }
      let laneIndex = 0;
      let eventIndex = 0;
      while (true){
        if (this.eventArray[laneIndex] === undefined) {
          this.eventArray[laneIndex] = [];
        }
        const lane = this.eventArray[laneIndex];
        const laneLength = lane.length;
        if (laneLength === 0) {
          lane.push(this.asDayEvent(event));
          return;
        }
        const eventInLane = lane[eventIndex];
        if (eventInLane.meta.startTime! <= event.meta.endTime! &&
          eventInLane.meta.endTime! >= event.meta.startTime!){
          laneIndex++;
          continue;
        }
        lane.push(this.asDayEvent(event));
        return;
      }
    })
  }
}
