import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject, Input,
  input,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {PersonBadgeComponent} from './person-badge/person-badge.component';
import {User} from '../../../models/user';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatCheckbox} from '@angular/material/checkbox';
import {AuthService} from '../../services/auth.service';

export type BadgeUserData = User & {
  selected: boolean;
}

@Component({
  selector: 'app-person-picker',
  imports: [
    PersonBadgeComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatCheckbox,
  ],
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss', './person-badge/person-badge.component.scss']
})
export class PersonPickerComponent {

  people = input<User[]>();
  selectedPeople = input<string[]>();
  peopleToShow: BadgeUserData[] = [];
  @Input() title?: string;
  @Output() onSelectionChange: EventEmitter<BadgeUserData[]> = new EventEmitter();
  @ViewChild('plus') plus!: ElementRef;
  hiddenSelected = signal(false);

  constructor(
    private authService: AuthService,
  ) {
    effect(() => {
      const people= [];
      for (let person of this.people() ?? []) {
        people.push({
          ...person,
          selected: this.selectedPeople()?.includes(person.id) ?? false,
        });
      }
      if(this.authService.loggedUser()) {
        this.peopleToShow = people.sort((a, b) =>
          this.authService.loggedUser()!.id === a.id ? -1 : a.name.localeCompare(b.name));
      }
    });
  }

  isOpen = false;

  selectBadge(person: User) {
    this.peopleToShow = this.peopleToShow.map((p) => {
      if (p.id === person.id) {
        p.selected = !p.selected;
      }
      return p;
    });
    this.hiddenSelected.set(this.peopleToShow.slice(4).some((p) => p.selected));
    this.onSelectionChange.emit(this.peopleToShow);
  }

  showOverlay() {
    this.isOpen = !this.isOpen;
  }
}
