import {Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {badgeColors, NewUser, User} from '../../../models/user';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption, MatRipple} from '@angular/material/core';
import {ref, Storage, uploadBytes} from '@angular/fire/storage';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {SnackbarService} from '../../services/snackbar.service';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from '@angular/material/sidenav';
import {NgClass, NgStyle} from '@angular/common';
import {user} from '@angular/fire/auth';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS, noWhitespaceValidator, parseFormErrors} from '../../utils';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import dayjs from 'dayjs';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-user-details',
  imports: [
    MatIcon,
    MatDialogContent,
    MatIconButton,
    PersonBadgeComponent,
    EditableInputComponent,
    MatRipple,
    ReactiveFormsModule,
    MatProgressSpinner,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatInput,
    MatLabel,
    NgClass,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    ButtonWLoaderComponent,
    MatError,
    DeleteConfirmationComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './user-details.component.html',
  standalone: true,
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {
  @ViewChild(MatDrawer) drawer?: MatDrawer;
  readonly dialogRef = inject(MatDialogRef<UserDetailsComponent>);
  usersService: UserService = inject(UserService);
  readonly snackBar = inject(SnackbarService);
  authService = inject(AuthService);
  data? = inject<User>(MAT_DIALOG_DATA);
  fb = inject(FormBuilder);
  formGroup = this.fb.group({
    name: new FormControl(this.data?.name, [Validators.required, noWhitespaceValidator]),
    email: new FormControl(this.data?.email,[Validators.required, Validators.email]),
    phone: new FormControl(this.data?.phone),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  colorList = badgeColors;
  selectColor(color: string) {
    if (this.data) {
      this.data.badgeColor = color;
      this.usersService.updateUser(this.data.id, {badgeColor: color});
    }
  }

  // isEditing = false;
  newUser = false;
  saving = false;
  resetingPassword = signal(false);
  deletingUser = signal(false);

  dataPoints = signal([
    {
      label: 'Nome',
      id: 'name',
      editable: true,
      editing: false,
      required: true,
      control: this.formGroup.controls.name,
      validators: [Validators.required]
    },
    {
      label: 'Email',
      id: 'email',
      editable: false,
      editing: false,
      required: true,
      control:  this.formGroup.controls.email,
      validators: [Validators.required, Validators.email]
    },
    {
      label: 'Telefone',
      id: 'phone',
      editable: true,
      editing: false,
      required: false,
      control: this.formGroup.controls.phone,
      validators: []
    },
  ]);

  datePicker = new FormControl(this.data?.birthday || '')

  storage = inject(Storage);

  ngOnInit() {
    this.newUser = !this.data;
    if (this.newUser) {
      this.data =
        {
          id: '',
          name: '',
          email: '',
          phone: '',
          badgeColor: badgeColors[Math.floor(Math.random() * badgeColors.length)]
        }
      this.dataPoints().push(
        {
          id: 'password',
          label: 'Senha',
          editable: true,
          editing: false,
          required: true,
          control: this.formGroup.controls.password,
          validators: []
        });
    } else {
      this.formGroup.controls.password.disable();
    }
    this.dataPoints()[0].control.valueChanges.subscribe(value => {
      this.data!.name = value || '';
    });
    if (this.data && this.data.birthday){
      this.datePicker.setValue(this.data.birthday);
    }
    this.datePicker.valueChanges.subscribe(value => {
      if (this.data?.id) {
        this.data.birthday = value || '';
        this.usersService.updateUser(this.data.id, {birthday: dayjs(this.datePicker.value).unix()});
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  deleteUser() {
    if(this.drawer?.opened) {
      this.deletingUser.set(true);
      this.usersService.deleteUser(this.data?.id!).then(() => {
        this.deletingUser.set(false);
        this.dialogRef.close();
      });
    } else {
      this.drawer?.open();
    }
  }

  toggleEdit(label: string, newValue?: string) {
    this.dataPoints.update(dataPoints => {
      if(dataPoints){
        dataPoints.forEach(point => {
          if(point.label === label && point.editable){
            if(point.editing && newValue){
              point.control.setValue(newValue);
              this.usersService.updateUser(this.data?.id!, {[point.id]: newValue});
            }
            point.editing = !point.editing;
          }
        });
      }
      return dataPoints;
    })
  }

  resetPassword() {
    if (this.resetingPassword() || !this.data) return;
    this.resetingPassword.set(true);
    this.usersService.resetPassword(this.data.email).then(() => {
      this.resetingPassword.set(false);
      this.snackBar.openSnackBar('success', 'Email de redefinição de senha enviado', 'Ok');
    }).catch(() => {
      this.resetingPassword.set(false);
      this.snackBar.openSnackBar('error', 'Erro ao redefinir senha', 'Ok');
    });
  }

  save() {
    const form = this.dataPoints();
    if(this.saving){
      return;
    }
    let invalid = false;
    for(const field of form) {
      if (field.control.invalid) {
        field.control.markAsTouched();
        invalid = true;
      }
    }
    if(invalid) return;
    this.saving = true;
    const data: NewUser = {
      name: form[0].control.value!,
      email: form[1].control.value!,
      phone: form[2].control.value!,
      password: form[3]?.control.value!,
      badgeColor: this.data!.badgeColor!,
      birthday: dayjs(this.datePicker.value).unix(),
  }
    form.forEach(field => field.control.disable())
    this.usersService.createUser(data).then(() => {
      this.saving = false;
      this.dialogRef.close();
    }).catch(() => {
      this.saving = false;
      form.forEach(field => field.control.enable())
      this.snackBar.openSnackBar('error', 'Erro ao criar usuário', 'Ok');
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  async fileUpload(event: any) {
    if(!this.data) return;
    const picture: File = event.target.files[0];
    const path = `/users/${this.data.id}/${picture.name}`;
    await uploadBytes(ref(this.storage, path), await picture.arrayBuffer());
    this.usersService.updateUser(this.data.id, {profilePicture: path});
  }

  protected readonly event = event;
  protected readonly badgeColors = badgeColors;
  protected readonly user = user;
  protected readonly parseFormErrors = parseFormErrors;
}
