<div class="header">
  <app-delete-confirmation
    [hidden]="isNew"
    [deleting]="deleteLoading"
    (deleteConfirmation)="deleteContact()"/>

  @if (isNew) {
    <div class="task-name container">Adicionar Contato</div>
  } @else {
    <div class="task-name container">{{ contactName }}</div>
  }

  <button mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>

<div class="contact-modal">
  <div class="info">
    <!--    <div class="arq-up">-->
    <!--      <div class="picture">-->
    <!--        @if(this.pictureUrl) {-->
    <!--          <img [ngSrc]="this.pictureUrl" fill alt="">-->
    <!--        } @else {-->
    <!--          <mat-icon fontIcon="person"></mat-icon>-->
    <!--        }-->
    <!--      </div>-->
    <!--      <div mat-ripple class="upload" (click)="fileInput.click()">-->
    <!--        <mat-icon fontIcon="upload"></mat-icon>-->
    <!--        <div>Adicionar arquivo</div>-->
    <!--      </div>-->
    <!--      <input #fileInput type="file" hidden (change)="fileUpload($event)" accept=".png,.jpg,.jpeg">-->
    <!--    </div>-->
    <form [formGroup]="formGroup">
      <app-toogle-select class="toggle" [options]="['PF', 'PJ']" [selected]="contactType==='PJ'"
                         (change)="changeType($event)"/>
      <div class="data">
        <div class="list-item name"><span>Nome Completo: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Nome</mat-label>
              <input matInput placeholder="Nome da Silva" formControlName="nameControl">
            </mat-form-field>
          } @else if (isEditingName) {
            <app-editable-input [validators]="[Validators.required, noWhitespaceValidator]" [value]="contactName"
                                formName="Editar nome"
                                (onSave)="saveName($event)"
                                (onCancel)="isEditingName = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingName = true">{{ contactName }}</span>
          }
        </div>
        <div class="list-item phone"><span>Telefone: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Telefone</mat-label>
              <input matInput placeholder="(41) 99999-9898" formControlName="phoneControl">
            </mat-form-field>
          } @else if (isEditingPhone) {
            <app-editable-input [value]="contactPhone" formName="Editar telefone" (onSave)="savePhone($event)"
                                (onCancel)="isEditingPhone = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingPhone = true">{{ contactPhone }}</span>
          }
        </div>
        <div class="list-item address"><span>Rua: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Endereço</mat-label>
              <input matInput placeholder="Rua das flores" formControlName="addressControl">
            </mat-form-field>
          } @else if (isEditingAddress) {
            <app-editable-input [value]="contactAddress" formName="Editar endereço" (onSave)="saveAddress($event)"
                                (onCancel)="isEditingAddress = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingAddress = true">{{ contactAddress }}</span>
          }
        </div>
        <div class="list-item addressNumber"><span>Número: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Número</mat-label>
              <input matInput placeholder="1200" formControlName="addressNumberControl">
            </mat-form-field>
          } @else if (isEditingAddressNumber) {
            <app-editable-input [value]="contactAddressNumber" formName="Editar número" (onSave)="saveAddressNumber($event)"
                                (onCancel)="isEditingAddressNumber = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingAddressNumber = true">{{ contactAddressNumber }}</span>
          }
        </div>
        <div class="list-item addressComp"><span>Complemento: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Complemento</mat-label>
              <input matInput placeholder="sala 102" formControlName="addressCompControl">
            </mat-form-field>
          } @else if (isEditingAddressComp) {
            <app-editable-input [value]="contactAddressComp" formName="Editar complemento" (onSave)="saveAddressComp($event)"
                                (onCancel)="isEditingAddressComp = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingAddressComp = true">{{ contactAddressComp }}</span>
          }
        </div>
<!--        <div class="list-item city"><span>Cidade: </span>-->
<!--            @if (!isNew) {-->
<!--            <span mat-ripple class="content" >{{ contactCity }}</span>-->
<!--          }-->
<!--        </div>-->
        <div class="list-item email"><span>Email: </span>
          @if (isNew) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Email</mat-label>
              <input matInput placeholder="email@exemplo.com" formControlName="emailControl">
            </mat-form-field>
          } @else if (isEditingEmail) {
            <app-editable-input [validators]="[Validators.email]" [value]="contactEmail" formName="Editar email"
                                (onSave)="saveEmail($event)" (onCancel)="isEditingEmail = false"></app-editable-input>
          } @else {
            <span mat-ripple class="content" (click)="isEditingEmail = true">{{ contactEmail }}</span>
          }
        </div>

        @if (!isPJ()) {
          <div class="list-item profession"><span>Profissão: </span>
            @if (isNew) {
              <mat-form-field>
                <mat-label>Profissão</mat-label>
                <mat-select formControlName="professionControl">
                  @for (option of professionOptions; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            } @else if (isEditingProfession) {
              <app-editable-input type="select" [options]="professionOptions" [value]="contactProfession" formName="Editar profissão"
                                  (onSave)="saveProfession($event)"
                                  (onCancel)="isEditingProfession = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingProfession = true">{{ contactProfession }}</span>
            }
          </div>
          <div class="list-item birthday"><span>Data de nascimento: </span>
            @if (isNew) {
              <mat-form-field>
                <mat-label>Data de nascimento</mat-label>
                <input matInput [matDatepicker]="picker" [value]="contactBirthday" formControlName="birthdayControl">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            } @else if (isEditingBirthday) {
              <app-editable-input type="date" [value]="contactBirthday?.toISOString()" formName="Editar data de nascimento"
                                  (onSave)="saveBirthday($event)"
                                  (onCancel)="isEditingBirthday = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingBirthday = true">{{ contactBirthday | gm_date }}</span>
            }
          </div>
          <div class="list-item gender"><span>Sexo: </span>
            @if (isNew) {
              <mat-form-field>
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="genderControl">
                  @for (option of genderOptions; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            } @else if (isEditingGender) {
              <app-editable-input type="select" [options]="genderOptions" [value]="contactGender" formName="Editar sexo"
                                  (onSave)="saveGender($event)"
                                  (onCancel)="isEditingGender = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingGender = true">{{ contactGender }}</span>
            }
          </div>
        } @else {
          <div class="list-item site"><span>Site: </span>
            @if (isNew) {
              <mat-form-field subscriptSizing="dynamic">
                <mat-label>Site</mat-label>
                <input matInput placeholder="www.site.com" formControlName="siteControl">
              </mat-form-field>
            } @else if (isEditingSite) {
              <app-editable-input [value]="contactSite" formName="Editar endereço" (onSave)="saveSite($event)"
                                  (onCancel)="isEditingSite = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingSite = true">{{ contactSite }}</span>
            }
          </div>

          <div class="list-item cnpj"><span>CNPJ: </span>
            @if (isNew) {
              <mat-form-field subscriptSizing="dynamic">
                <mat-label>CNPJ</mat-label>
                <input matInput placeholder="00.000.000/0000-00" formControlName="cnpjControl">
              </mat-form-field>
            } @else if (isEditingCnpj) {
              <app-editable-input [value]="contactCnpj" formName="Editar CNPJ"
                                  (onSave)="saveCnpj($event)" (onCancel)="isEditingCnpj = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingCnpj = true">{{ contactCnpj }}</span>
            }
          </div>
          <div class="list-item gender"><span>Ramo: </span>
            @if (isNew) {
              <mat-form-field>
                <mat-label>Ramo</mat-label>
                <mat-select formControlName="lineOfWorkControl">
                  @for (option of lineOfWorkOptions; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            } @else if (isEditingLineOfWork) {
              <app-editable-input type="select" [value]="contactLineOfWork" formName="Editar Ramo"
                                  (onSave)="saveLineOfWork($event)"
                                  [options]="lineOfWorkOptions"
                                  (onCancel)="isEditingLineOfWork = false"></app-editable-input>
            } @else {
              <span mat-ripple class="content" (click)="isEditingLineOfWork = true">{{ contactLineOfWork }}</span>
            }
          </div>
        }
      </div>
    </form>
  </div>

  <div class="more-data">
    <div class="list-item assignee"><span>Responsável: </span>
      <section class="assignee-cell">
        @if (contactAssignee) {
          <app-person-badge [selected]="false" [user]="contactAssignee"></app-person-badge>
        }
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Responsável</mat-label>
          <mat-select [value]="contactAssignee?.id" (selectionChange)="selectAssignee($event)">
            <mat-select-trigger>
              @if (contactAssignee) {
                <span>{{ contactAssignee.name }}</span>
              }
            </mat-select-trigger>
            @for (person of userService.users(); track person.id) {
              <mat-option [value]="person.id">
                <app-person-badge [selected]="false" [user]="person"></app-person-badge>
                <p>{{ person.name }}</p>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>
    </div>
    <form [formGroup]="formGroup">
      <div class="list-item metadata"><span>Anotações: </span>
        @if (isNew) {
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Anotações</mat-label>
            <input matInput placeholder="Lorem ipsum" formControlName="metadataControl">
          </mat-form-field>
        } @else if (isEditingMetadata) {
          <app-editable-input [value]="contactMetadata" formName="Editar anotações" (onSave)="saveMetadata($event)"
                              (onCancel)="isEditingMetadata = false"></app-editable-input>
        } @else {
          <span mat-ripple class="content" (click)="isEditingMetadata = true">{{ contactMetadata }}</span>
        }
      </div>
    </form>
  </div>
  <div style="width: fit-content" [matTooltip]="'Este contato não possui atendimentos'"
       [matTooltipDisabled]="hasCs()">
    <div mat-ripple
         [matRippleDisabled]="!hasCs()"
         (click)="openCs()"
         class="upload related" [ngClass]="{disabled: !hasCs()}">
      <div>Ver atendimentos relacionados</div>
      <mat-icon fontIcon="open_in_new"></mat-icon>
    </div>
  </div>
  @if (isNew) {
    <div style="display: flex; flex-direction: row-reverse; gap: 5px">
      <app-button-w-loader
        [disabled]="!formGroup.valid"
        [loading]="loading"
        (click)="saveAll()"
        type="yellow"
        label="Salvar"
      />
      <div class="cancel" (click)="close()">
        <div>Cancelar</div>
      </div>
    </div>
  }
</div>
