import {Component, effect, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from '@angular/material/sidenav';
import {SidebarComponent} from './common/sidebar/sidebar.component';
import {NgIf} from '@angular/common';
import {Auth, authState, User} from '@angular/fire/auth';
import {lastValueFrom, Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    MatDrawerContainer,
    MatDrawer,
    MatDrawerContent,
    SidebarComponent,
    NgIf,
    MatProgressSpinner,
  ],
  templateUrl: './app.component.html',
  standalone: true,
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'gm-frontend';
  showSidenav = true;

  private auth: Auth = inject(Auth);
  private gmAuthService = inject(AuthService);
  authState$ = authState(this.auth);
  authStateSubscription?: Subscription;

  lastAuthState?: User | null;

  userService = inject(UserService);
  loading = true;

  constructor(private router: Router) {
    effect(() => {
      if (this.auth.currentUser) {
        this.loading = this.userService.users().length === 0
      } else {
        this.loading = false;
      }
    });
  }
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      const hiddenRoutes = ['/login', '/signup']; // Routes where sidenav is hidden
      this.showSidenav = !hiddenRoutes.includes(this.router.url.split('?')[0]);
    });

    this.authStateSubscription = this.authState$.subscribe(async (aUser: User | null) => {
      if (aUser) {
        this.gmAuthService.postAuth(aUser.uid);
        if (this.lastAuthState === null) {
          await this.router.navigate(aUser ? ['/tarefas'] : ['/login']);
        }
      } else {
        await this.router.navigate(['/login']);
      }
      this.lastAuthState = aUser;
    })
  }

  ngOnDestroy() {
    this.authStateSubscription?.unsubscribe();
  }

}
