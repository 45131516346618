import {ComponentRef, Directive, ElementRef, inject, Input, output} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {MentionListComponent} from './mention-list/mention-list.component';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {User} from '../../models/user';

@Directive({
  selector: '[mentions]',
  standalone: true,
  host: {
    '(keydown)': 'keyHandler($event)',
    '(input)': 'inputHandler($event)',
    // '[style.background-color]': 'color',
    // '[innerHTML]': '<b>Test</b>',
    // '(blur)': 'blurHandler($event)',
    autocomplete: 'off'
  },
})
export class MentionsDirective {
  @Input() mentions!: string[];
  addMention = output<User>();
  color = 'yellow';


  element: ElementRef = inject(ElementRef);
  overlay: any = inject(Overlay);

  overlayRef: OverlayRef | null = null;
  componentRef?: ComponentRef<MentionListComponent>;

  currentFilter = '';
  emptyCount = 0;

  constructor() {
  }

  keyHandler(event: KeyboardEvent) {
    if (this.componentRef) {
      if (event.key === 'Backspace') {
        this.currentFilter = this.currentFilter.slice(0, -1);
        this.componentRef.instance.filter.set(this.currentFilter);
        if (this.currentFilter === '') {
          this.emptyCount++;
        }
        if (this.emptyCount > 1) {
          this.detachOverlay();
        }
      } else if (event.key === 'Escape') {
        this.detachOverlay();
      } else if (event.key === 'Enter') {
        this.detachOverlay();
      } else if (event.key === 'Shift') {

      } else if (event.key === 'ArrowDown') {

      } else if (event.key === 'ArrowUp') {

      } else {
        this.currentFilter += event.key;
        this.componentRef.instance.filter.set(this.currentFilter);
        // this.color = 'red'
      }
    }
  }

  inputHandler(event: InputEvent) {
    if (event.data === '@') {
      if (this.overlayRef) {
        this.detachOverlay();
      }
      this.attachOverlay();
    } else if (event.data === ' ') {
      this.detachOverlay();
    } else {
      if (this.componentRef) {
      }
    }
  }

  detachOverlay() {
    this.overlayRef?.detach();
    this.currentFilter = '';
    this.emptyCount = 0;
    this.overlayRef = null;
    this.componentRef = undefined;
  }

  attachOverlay() {
    const pos = this.getCaretPosition();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.element.nativeElement)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: pos * 5,
      }]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const userProfilePortal = new ComponentPortal(MentionListComponent);
    this.componentRef = this.overlayRef?.attach(userProfilePortal);
    this.componentRef?.instance.selectPerson.subscribe((person) => {
      if (person) {
        this.element.nativeElement.value = this.element.nativeElement.value.slice(0, pos) + person.name + ' ' + this.element.nativeElement.value.slice(pos);
        this.addMention.emit(person);
      }
      this.detachOverlay();
    });
    this.overlayRef?.backdropClick().subscribe(() => this.detachOverlay());
  }

  getCaretPosition(): number {
    const val = this.element.nativeElement.value;
    return val.slice(0, this.element.nativeElement.selectionStart).length;
  }

}
