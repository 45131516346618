<div class="map-main">
  <div class="header">
    <div class="title">
      <span>Mapa</span>
    </div>
    <div class="filters">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Entidades</mat-label>
        <mat-select [value]="selectedType" (selectionChange)="selecetionChange($event)">
          @for (tot of typesOfTasks; track tot) {
            <mat-option [value]="tot">
              <p>{{ tot }}</p>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <app-button-w-loader
        label="Centralizar"
        (buttonClick)="centerMap()"
      />
    </div>
  </div>
  <div class="map match-parent" id="map"></div>
</div>
