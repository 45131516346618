import {Component, effect, EventEmitter, inject, OnInit, Output, signal, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import {ContactService} from '../../services/contact.service';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {User} from '../../../models/user';
import {Contact, genderOptions, lineOfWorkOptions, professionOptions} from '../../../models/contact';
import {MatInput, MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {NgClass} from '@angular/common';
import {SnackbarService} from '../../services/snackbar.service';
import {ToogleSelectComponent} from '../../common/toogle-select/toogle-select.component';
import dayjs from 'dayjs';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from '@angular/material/datepicker';
import {CSService} from '../../services/cs.service';
import {MatTooltip} from '@angular/material/tooltip';
import {DUDU_DATE_FORMATS, findLabel, noWhitespaceValidator, sanitizeDelta} from '../../utils';
import {GMDatePipe} from '../../common/date.pipe';
import {Platform} from '@angular/cdk/platform';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {TitleElipisisLastWordComponent} from '../../common/title-elipisis-last-word/title-elipisis-last-word.component';
import {HighlightErrorDirective} from '../../common/error-label.directive';
import {FormFieldParserDirective} from '../../common/formFieldParser.directive';
import {AssigneeSelectorComponent} from '../../common/assignee-selector/assignee-selector.component';
import {ContentChange, QuillEditorComponent, QuillViewComponent} from 'ngx-quill';
import {convertDeltaToHtml, convertHtmlToDelta} from 'quill-converter';

export type ContactDialogData = {
  contactData?: Contact,
}

@Component({
  selector: 'app-create-contact',
  imports: [
    MatIcon,
    MatIconButton,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    DeleteConfirmationComponent,
    ButtonWLoaderComponent,
    ToogleSelectComponent,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    NgClass,
    MatTooltip,
    GMDatePipe,
    TitleElipisisLastWordComponent,
    HighlightErrorDirective,
    FormFieldParserDirective,
    AssigneeSelectorComponent,
    QuillEditorComponent,
    QuillViewComponent
  ],
  templateUrl: './create-contact.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    },
  ],
  styleUrl: './create-contact.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class CreateContactComponent implements OnInit {

  readonly dialogRef = inject(MatDialogRef<CreateContactComponent, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<ContactDialogData>(MAT_DIALOG_DATA);
  @Output() tmp: EventEmitter<string | undefined> = new EventEmitter();

  contactType: string = this.data.contactData?.type ?? 'PF';
  contactAssignee?: User;
  createdByUser?: User;
  contactPicture?: string;

  isPJ = signal<boolean>(this.data.contactData?.type === 'PJ');
  isEditing = signal(true);

  changed = false;

  contactService = inject(ContactService);
  csService = inject(CSService);
  authService = inject(AuthService);
  snack = inject(SnackbarService);

  csCount = signal<number>(0);

  fb = inject(FormBuilder);

  formGroup = this.fb.group({
      nameControl: new FormControl('', [Validators.required, noWhitespaceValidator]),
    birthdayControl: new FormControl<Date | null>(null, []),
    addressControl: new FormControl('', []),
    phoneControl: new FormControl('', [Validators.required, noWhitespaceValidator]),
    genderControl: new FormControl('', []),
    emailControl: new FormControl('', [Validators.email]),
    metadataControl: new FormControl('', []),
    cnpjControl: new FormControl('', []),
    lineOfWorkControl: new FormControl('', []),
    addressNumberControl: new FormControl('', []),
    addressCompControl: new FormControl('', []),
    siteControl: new FormControl('', []),
    professionControl: new FormControl('', []),
  })

  isNew = true;
  richControl: FormControl = new FormControl([]);

  changeType(event: boolean) {
    this.isPJ.set(event);
    this.contactType = event ? 'PJ' : 'PF';
  }

  delta: any;
  commentTextChanges(event: ContentChange) {
    this.delta = event.content;
  }

  constructor() {
    effect(() => {
      this.dialogRef.disableClose = this.isEditing();
      this.csCount.set(this.csService.csList().filter((cs) => !cs.deletedAt && cs.contactId === this.data.contactData?.id).length);
    });
  }

  ngOnInit(): void {
    const data = this.data.contactData

    if (data) {
      this.isEditing.set(false);
      this.delta = data.delta;
      this.isNew = false;
      this.fillForm();
      this.contactAssignee = this.userService.users().find(user => user.id === data.assigneeUserId);
      this.createdByUser = this.userService.users().find(user => user.id === data.createdByUserId);
    } else {
      this.dialogRef.disableClose = true;
      this.contactAssignee = this.authService.loggedUser()!;
    }
  }

  fillForm() {
    const data = this.data.contactData;
    const formData = {
      nameControl: data?.name ?? null,
      birthdayControl: data?.birthday ? dayjs.unix(data?.birthday).toDate() : null,
      addressControl: data?.address ?? null,
      phoneControl: data?.phone ?? null,
      genderControl: data?.gender ?? null,
      emailControl: data?.email ?? null,
      metadataControl: data?.metadata ?? null,
      cnpjControl: data?.cnpj ?? null,
      lineOfWorkControl: data?.lineOfWork ?? null,
      addressNumberControl: data?.addressNumber ?? null,
      addressCompControl: data?.addressComp ?? null,
      siteControl: data?.site ?? null,
      professionControl: data?.profession ?? null,
    }
    this.formGroup.setValue(formData);
    if (data?.delta == null && data?.metadata != null) {
      this.richControl.setValue(convertHtmlToDelta(data.metadata));
    } else {
      this.richControl.setValue(data?.delta);
    }
  }

  userService = inject(UserService);

  async selectAssignee(event: string) {
    this.changed = true;
    if (this.data.contactData == null) {
      if (this.isNew) {
        this.contactAssignee = this.userService.users().find(user => user.id === event);
      }
      return;
    }
    this.contactAssignee = this.userService.users().find(user => user.id === event);
    this.tmp.emit(undefined);
    await this.contactService.updateContactAssignee(this.data.contactData.id, event);
  }

  close() {
    this.dialogRef.close({changed: this.changed, isTemp: false});
  }

  deleteLoading = false;

  deleteContact() {
    this.deleteLoading = true;
    this.contactService.deleteContact(this.data.contactData?.id ?? '').then(() => {
      this.snack.openSnackBar('success', `Contato deletado com sucesso`, 'Ok');
      this.dialogRef.close({delete: true});
    }).catch(() => {
      this.snack.openSnackBar('error', `Erro ao deletar contato`, 'Ok');
    });
  }

  loading = false;

  objectType: 'object' | 'html' = 'object';

  async saveAll() {
    if (this.loading) {
      return;
    }
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.loading = true;
      const name = this.formGroup.get('nameControl')?.value ?? '';
      const address = this.formGroup.get('addressControl')?.value ?? '';
      const email = this.formGroup.get('emailControl')?.value ?? '';
      const phone = this.formGroup.get('phoneControl')?.value ?? '';
      const birthday = dayjs(this.formGroup.get('birthdayControl')?.value).unix() ?? null;
      const gender = this.formGroup.get('genderControl')?.value ?? '';
      const addressNumber = this.formGroup.get('addressNumberControl')?.value ?? '';
      const addressComp = this.formGroup.get('addressCompControl')?.value ?? '';
      const city = this.formGroup.get('cityControl')?.value ?? '';
      const site = this.formGroup.get('siteControl')?.value ?? '';
      const profession = this.formGroup.get('professionControl')?.value ?? '';
      const cnpj = this.formGroup.get('cnpjControl')?.value ?? '';
      const lineOfWork = this.formGroup.get('lineOfWorkControl')?.value ?? '';
      const type = this.contactType;
      if (this.isNew) {
        const metadata = convertDeltaToHtml(this.delta);
        this.contactService.createContact(name,
          type,
          address,
          email,
          phone,
          birthday ?? undefined,
          gender,
          metadata,
          this.contactAssignee?.id,
          this.contactPicture,
          cnpj,
          lineOfWork,
          addressNumber,
          addressComp,
          city,
          site,
          profession,
          sanitizeDelta(this.delta)
        ).then(async (response) => {
          await this.contactService.getAllContacts()
          this.isEditing.set(false);
          this.isNew = false;
          this.loading = false;
          this.delta = response.delta;
          this.data.contactData = {
            id: response.id,
            name,
            type,
            address,
            email,
            phone,
            bairro: response.bairro,
            birthday: birthday ?? undefined,
            gender,
            metadata,
            assigneeUserId: this.contactAssignee?.id,
            pictureId: this.contactPicture,
            cnpj,
            lineOfWork,
            addressNumber,
            addressComp,
            city: response.city ?? city,
            site,
            profession,
            delta: response.delta
          }

          setTimeout(() => {
            this.objectType = 'object';
            this.delta = response.delta;
            this.richControl.setValue(response.delta);
            // console.log(this.delta, response.delta);acho
          }, 200);

          this.fillForm();
          if (this.authService.loggedUser() != null) {
            this.createdByUser = this.authService.loggedUser()!;
          }
        }).catch(() => {
          this.snack.openSnackBar('error', `Erro ao criar contato`, 'Ok');
        })
      } else {
        const metadata = convertDeltaToHtml(this.delta);
        this.contactService.updateContact(this.data.contactData?.id!,
          name,
          type,
          address,
          email,
          phone,
          birthday ?? undefined,
          gender,
          metadata,
          this.contactAssignee?.id,
          this.contactPicture,
          cnpj,
          lineOfWork,
          addressNumber,
          addressComp,
          city,
          site,
          profession,
          sanitizeDelta(this.delta)
        ).then(async (response) => {
          await this.contactService.getAllContacts()
          this.isEditing.set(false);
          this.data.contactData = response;
          this.fillForm();
          this.loading = false;
          this.snack.openSnackBar('success', `Contato Editado com sucesso`, 'Ok');
        }).catch(() => {
          this.snack.openSnackBar('error', `Erro ao editar contato`, 'Ok');
        })
      }
    }
  }

  onMaskApplied(maskedValue: string) {
    if (maskedValue.length === 15) {
      this.formGroup.get('phoneControl')?.setValue(maskedValue)
    }
  }

  cancel() {
    if (this.isNew) {
      this.dialogRef.close();
    } else {
      this.isEditing.set(false);
      this.fillForm();
    }
  }

  openCs() {
    if (this.csCount())
      this.window.open(`/atendimentos?contactId=${this.data.contactData?.id}`, '_blank', 'noopener');
  }

  protected readonly dayjs = dayjs;
  protected readonly window = window;
  protected readonly genderOptions = genderOptions;
  protected readonly lineOfWorkOptions = lineOfWorkOptions;
  protected readonly professionOptions = professionOptions;
  protected readonly findLabel = findLabel;
}
