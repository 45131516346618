import {Component, effect, inject} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatRipple} from '@angular/material/core';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {PersonBadgeComponent} from '../person-picker/person-badge/person-badge.component';
import {MatIconButton} from '@angular/material/button';
import {AuthService} from '../../services/auth.service';
import {User} from '../../../models/user';
import {NotificationsService} from '../../services/notifications.service';
import {MatBadge} from '@angular/material/badge';
import {UserDetailsComponent} from '../../users/user-details/user-details.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar',
  imports: [
    MatIcon,
    MatRipple,
    RouterLink,
    RouterLinkActive,
    PersonBadgeComponent,
    MatIconButton,
    MatBadge
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  standalone: true
})
export class SidebarComponent {
  router: Router = inject(Router);
  user?: User;
  count = 0;
  constructor(
    private authService: AuthService,
    private notificationService: NotificationsService
  ) {
    effect(() => {
      this.user = this.authService.loggedUser() ?? undefined;
      this.count = this.notificationService.unredCount();
    });
  }

  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }
  readonly dialog = inject(MatDialog);

  openDialog(): void {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '720px',
      maxWidth: '50vw',
      height: '450px',
      maxHeight: '70vh',
      data: this.authService.loggedUser(),
      panelClass: 'custom-dialog-container'
    });
  }
}
