import {Component, Input, input, output, ViewChild} from '@angular/core';
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from '@angular/material/sidenav';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatIconButton} from '@angular/material/button';
import {NgStyle} from '@angular/common';

@Component({
  selector: 'app-delete-confirmation',
  imports: [
    MatDrawerContainer,
    MatDrawerContent,
    MatIcon,
    MatProgressSpinner,
    MatIconButton,
    MatDrawer,
    NgStyle
  ],
  templateUrl: './delete-confirmation.component.html',
  standalone: true,
  styleUrl: './delete-confirmation.component.scss'
})
export class DeleteConfirmationComponent {
  @ViewChild(MatDrawer) drawer?: MatDrawer;

  @Input() hidden = false
  deleteConfirmation = output();
  deleting = input(false)

  delete() {
    if(this.drawer?.opened) {
      this.deleteConfirmation.emit();
      // this.deleting.set(true);
      // this.usersService.deleteUser(this.data?.id!).then(() => {
      //   this.deletingUser.set(false);
      //   this.dialogRef.close();
      // });
    } else {
      this.drawer?.open();
    }
  }

}
