import {
  Component,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatInput, MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import dayjs from 'dayjs';
import {MatSelect, MatSelectChange, MatSelectModule} from '@angular/material/select';
import {map, Observable, of, startWith} from 'rxjs';
import {useAnimation} from '@angular/animations';
import {StatusBadgeComponent} from '../../common/status-badge/status-badge.component';
import {FileHandlerComponent} from '../../common/file-handler/file-handler.component';
import {HistoryComponent, HistoryData} from '../../common/history/history.component';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {ContactService} from '../../services/contact.service';
import {GmFile} from '../../../models/gm-file';
import {User} from '../../../models/user';
import {StatusBadgeListComponent} from '../../common/status-badge-list/status-badge-list.component';
import {environment} from '../../../environments/environment';
import {Contact} from '../../../models/contact';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AsyncPipe, NgClass, NgIf, NgStyle} from '@angular/common';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from '@angular/material/datepicker';
import {
  MatTimepicker,
  MatTimepickerInput,
  MatTimepickerModule,
  MatTimepickerToggle
} from '@angular/material/timepicker';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {CSService} from '../../services/cs.service';
import {CS} from '../../../models/cs';
import {GMDatePipe} from '../../common/date.pipe';
import {MatTooltip} from '@angular/material/tooltip';
import {HistoryService} from '../../services/history.service';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TaskService} from '../../services/task.service';
import {ulid} from 'ulid';
import {DUDU_DATE_FORMATS, findLabel, isEntityValidator, noWhitespaceValidator, sanitizeDelta} from '../../utils';
import {Platform} from '@angular/cdk/platform';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {HighlightErrorDirective} from '../../common/error-label.directive';
import {FormFieldParserDirective} from '../../common/formFieldParser.directive';
import {RelateContactCsComponent} from '../../common/relate-contact-cs/relate-contact-cs.component';
import {ScrollToMiddleTimepickerDirective} from '../../common/scroll-to-option.directice';
import {NgxMaskDirective} from 'ngx-mask';
import {CommentsComponent} from '../../tasks/tasks/create-task/comments/comments.component';
import {ContentChange, QuillEditorComponent, QuillViewComponent} from 'ngx-quill';
import {convertDeltaToHtml} from 'quill-converter';
import {Plan} from '../../../models/plan';

export type CreateCSData = {
  csData: CS,
}

@Component({
  selector: 'app-create-cs-v2',
  imports: [
    MatFormField,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIcon,
    StatusBadgeComponent,
    ReactiveFormsModule,
    OverlayModule,
    ReactiveFormsModule,
    FileHandlerComponent,
    MatOption,
    MatSelectModule,
    MatInput,
    MatSelect,
    HistoryComponent,
    DeleteConfirmationComponent,
    EditableInputComponent,
    MatAutocompleteTrigger,
    MatAutocomplete,
    AsyncPipe,
    MatDatepickerInput,
    MatDatepicker,
    MatDatepickerToggle,
    MatTimepickerInput,
    MatTimepickerModule,
    MatDatepickerModule,
    MatTimepicker,
    MatTimepickerToggle,
    PersonBadgeComponent,
    GMDatePipe,
    MatTooltip,
    ButtonWLoaderComponent,
    NgClass,
    MatExpansionModule,
    NgStyle,
    HighlightErrorDirective,
    FormFieldParserDirective,
    RelateContactCsComponent,
    ScrollToMiddleTimepickerDirective,
    NgxMaskDirective,
    CommentsComponent,
    NgIf,
    QuillEditorComponent,
    QuillViewComponent
  ],
  templateUrl: './create-cs-v2.component.html',
  styleUrl: './create-cs-v2.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    },
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class CreateCsV2Component implements OnInit {
  isEditingAssignee = false;
  isEditingSplInsert = false;
  isEditingProtocol = false;

  csStatus: string = 'open';
  csSplStatus?: string;
  csId?: string;
  csAssignee?: User;
  isNew = false;

  readonly dialogRef = inject(MatDialogRef<CreateCsV2Component, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<CreateCSData>(MAT_DIALOG_DATA);
  readonly csService = inject(CSService);
  readonly contactService = inject(ContactService);
  readonly tasksService = inject(TaskService);
  readonly userService = inject(UserService);
  readonly authService = inject(AuthService);

  files: GmFile[] = [];

  relatedTasksCount = 0;

  allUsers: User[] = []

  constructor(private overlay: Overlay,) {
    effect(() => {
      this.allUsers = this.userService.activeUsers();
      this.allContacts = this.contactService.contactList().filter(
        contact => !contact.deletedAt
      );
      this.dialogRef.disableClose = this.isEditing();
      this.updateHistory();
      if (!!this.data.csData?.id) {
        this.relatedTasksCount = this.tasksService.taskList().filter(task => task.relationships?.find(rel => rel.relationshipId === this.data.csData?.id)).length;
      }
    });
  }

  @ViewChild('status') status!: ElementRef;

  uploadFileList?: File[];

  isEditing = signal(!this.data?.csData);

  csType?: string;
  csProtocol?: string;
  csContact?: string;
  csCity?: string;
  csBairro?: string;

  historyService = inject(HistoryService);
  isCreatingContact = false;
  newContractName = '';

  async updateContact(event: MatAutocompleteSelectedEvent) {
    if (event.option.value.id.startsWith('__new_contact__')) {
      this.formGroup.get('phoneControl')?.enable();
      this.formGroup.get('contactControl')?.setValidators([Validators.required]);
      this.isCreatingContact = true;
      this.csContact = event.option.value.id;
      this.newContractName = event.option.value.name;
      this.hasContactAddress = undefined;
    } else {
      this.formGroup.get('phoneControl')?.disable();
      this.formGroup.get('contactControl')?.setValidators([isEntityValidator]);
      this.isCreatingContact = false;
      this.csContact = event.option.value.id;
      this.setContacAddres(event.option.value);
    }
    if (!this.isNew) {
      await this.csService.updateContact(this.data.csData.id, this.csContact ?? '');
      await this.contactService.getAllContacts();
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  displayFn(contact?: Contact): string {
    return contact && contact.name ? contact.name : '';
  }

  async updateType(event: MatSelectChange) {
    this.csType = event.value;
    if (!this.isNew) {
      await this.csService.updateType(this.data.csData.id, this.csType ?? '');
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  async updateProtocol(event: string = '') {
    this.isEditingProtocol = false;
    if (this.csProtocol !== event) {
      this.csProtocol = event;
      if (this.data.csData == null) {
        return;
      }
      await this.csService.updateProtocol(this.data.csData.id, event);
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  fb = inject(FormBuilder);

  formGroup = this.fb.group({
    contactControl: new FormControl<Contact | undefined | null>(null, [isEntityValidator]),
    phoneControl: new FormControl<string | null>({value: null, disabled: true}, [Validators.required]),
    addressControl: new FormControl<string | null>(null, []),
    numberControl: new FormControl<string | null>(null, [Validators.pattern(/^\d+$/)]),
    subjectControl: new FormControl<string | null>(null, [Validators.required, noWhitespaceValidator]),
    compControl: new FormControl<string | null>(null, []),
    typeControl: new FormControl<string | null>(null, [Validators.required]),
    entityControl: new FormControl<string | null>(null, []),
    dateControl: new FormControl<Date | null>(new Date(), []),
    timeControl: new FormControl<Date | null>(new Date()),
  })

  splInsertControl = new FormControl<boolean | undefined>(undefined, [Validators.required]);
  protocolControl = new FormControl<string | undefined>(undefined, []);

  showOverlay() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.status)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 115
      }]);
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const ref = overlayRef.attach(new ComponentPortal(StatusBadgeListComponent));
    ref.instance.statusList.set(['open', 'inProgress', 'delivered', 'denied']);
    ref.instance.closePanel.subscribe(async (value) => {
      overlayRef.detach();
      if (this.csStatus !== value) {
        this.csStatus = value;
        if (this.data.csData == null) {
          return;
        }
        await this.csService.updateStatus(this.data.csData.id, value);
        await this.historyService.getHistoryForRelation(this.data.csData.id);
      }
    });
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  allContacts: Contact[] = []

  contactOptions: Observable<Contact[]> = of([]);

  private _filterGroup(value?: Contact | any): Contact[] {
    if (value) {
      if (value.name) {
        return this.allContacts
          .filter(c => c.name.toLowerCase().includes(value.name.toLowerCase()));
      } else {
        return this.allContacts
          .filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
      }
    }
    return this.allContacts;
  }

  historyList: HistoryData[] = [];

  richControl: FormControl = new FormControl([]);

  ngOnInit(): void {
    const data = this.data.csData;
    this.isNew = !data;
    this.contactOptions = of(this.allContacts);

    if (data) {
      this.csId = data.id;
      this.fillForm();
      this.csStatus = data.status;
      this.splInsertControl.setValue(!!data.splInsert);
      this.csProtocol = data.protocol;
      this.csAssignee = data.assigneeUser;
      this.csCity = data.city;
      this.csBairro = data.bairro;
      this.historyService.getHistoryForRelation(this.csId);
      this.csSplStatus = data.splStatus ?? undefined;
    } else {
      this.csId = ulid();
      this.isEditingSplInsert = true;
      this.isEditingProtocol = true;
      this.dialogRef.disableClose = true;
      this.csAssignee = this.authService.loggedUser()!;
    }
    this.splInsertControl.valueChanges.subscribe(async (value) => {
      this.isEditingSplInsert = false;
      if (!value) {
        this.csProtocol = undefined;
      }
      if (this.data.csData?.id) {
        await this.csService.updateSplInsert(this.data.csData.id, value!);
        await this.historyService.getHistoryForRelation(this.data.csData.id);
      }
    })
    this.contactOptions = this.formGroup.get('contactControl')!.valueChanges.pipe(
      startWith(''),
      map(value => {
        if(this.isCreatingContact && value && typeof value === 'string'){
          this.newContractName = value;
        }
        return this._filterGroup(value)
      }));
  }
  delta: any;
  commentTextChanges(event: ContentChange) {
    this.delta = event.content;
  }

  fillForm() {
    const data = this.data.csData;
    this.delta = data.delta;
    this.setContacAddres(data.contact);
    const formValue = {
      contactControl: data.contact,
      phoneControl: null,
      addressControl: data.address ?? null,
      numberControl: data.addressNumber ?? null,
      compControl: data.addressComp ?? null,
      subjectControl: data.subject ?? null,
      typeControl: data.type ?? null,
      entityControl: data.entity ?? null,
      dateControl: data.attendDate ? dayjs.unix(data.attendDate).toDate() : null,
      timeControl: data.attendTime ? dayjs.unix(data.attendTime).toDate() : null,
    }
    this.formGroup.setValue(formValue);
    if (this.delta == null) {
      this.richControl.setValue(data.description);
    } else {
      this.richControl.setValue(data.delta);
    }

  }

  async selectAssignee(event: MatSelectChange) {
    this.csAssignee = this.userService.users().find(user => user.id === event.value);
    if (!this.isNew) {
      this.isEditingAssignee = false;
      if (this.data.csData == null) {
        return;
      }
      await this.csService.updateAssignee(this.data.csData.id, event.value);
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  docChanges(){
    this.historyService.getHistoryForRelation(this.data.csData.id);
  }

  updateHistory() {
    if (this.data.csData?.id == null) {
      return;
    }
    this.historyList = [];
    this.historyService.historyList().get(this.data.csData!.id!)?.forEach(value => {
      if (value != null) {
        let assigneeChange, contactChange;
        if (value.data.dataChanged === 'assigneeUserId') {
          assigneeChange = {
            oldAssignee: this.userService.users().find(user => user.id === value.data.oldData),
            newAssignee: this.userService.users().find(user => user.id === value.data.newData)
          }
        }
        if (value.data.dataChanged === 'contactId') {
          contactChange = {
            oldContact: this.contactService.contactList().find(contact => contact.id === value.data.oldData),
            newContact: this.contactService.contactList().find(contact => contact.id === value.data.newData)
          }
        }
        this.historyList.push({
          ...value,
          user: this.userService.users().find(user => user.id === value.data.callerUserId),
          assigneeChange,
          contactChange
        });
      }
    });
  }

  deleteCS() {
    if (this.csId) {
      this.csService.delete(this.csId);
    }
    this.dialogRef.close({delete: this.csId});
  }

  loading = false;

  async saveAll() {
    if (this.loading) {
      return;
    }
    this.formGroup.markAllAsTouched();
    this.splInsertControl.markAsTouched();
    if (this.formGroup.invalid || this.splInsertControl.invalid) {
      return;
    }
    const formValue = this.formGroup.value;
    let date, time;
    if (formValue.dateControl){
      date = dayjs(formValue.dateControl).startOf('day');
    }
    if (formValue.timeControl) {
      time = date ? date
        .add(dayjs(formValue.timeControl).hour(), 'hour')
        .add(dayjs(formValue.timeControl).minute(), 'minute') : dayjs(formValue.timeControl);
    }
    this.loading = true;
    const cs: Omit<CS, 'id' | 'timestamp'> = {
      subject: formValue.subjectControl!,
      contactId: !this.isCreatingContact ? formValue.contactControl?.id! :  '__new_contact__' + this.newContractName,
      contactPhone: formValue.phoneControl ?? undefined,
      protocol: this.csProtocol ?? this.protocolControl.value ?? undefined,
      type: formValue.typeControl!,
      status: this.csStatus,
      description: convertDeltaToHtml(this.delta),
      address: formValue.addressControl ?? undefined,
      addressNumber: formValue.numberControl ?? undefined,
      addressComp: formValue.compControl ?? undefined,
      assigneeUserId: this.csAssignee?.id,
      splInsert: this.splInsertControl.value,
      attendDate: date ? date.unix() : undefined,
      attendTime: time ? time.unix() : undefined,
      delta: sanitizeDelta(this.delta),
    };

    let csId = this.data.csData?.id ?? this.csId;
    this.csService.updateCS(csId, cs).then((res) => {
      this.contactService.getAllContacts();
      this.isEditingProtocol = false;
      this.isNew = false;
      this.data.csData = res;
      this.csCity = res.city;
      this.csBairro = res.bairro;
      this.loading = false;
      this.isCreatingContact = false;
      this.historyService.getHistoryForRelation(this.data.csData.id);
      this.formGroup.controls['contactControl'].setValue({
        id: res.contactId,
        name: this.formGroup.controls['contactControl'].value?.name ?? this.newContractName
      } as any);
      this.isEditing.set(false);
    }).catch((e) => {
      this.isCreatingContact = false;
      this.loading = false;
    })
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    if (this.isNew) {
      this.close();
    } else {
      this.isEditing.set(false);
      this.fillForm();
    }
  }

  fileUpload(event: any) {
    const files: FileList = event.target.files;
    this.uploadFileList = Array.from(files);
  }

  @ViewChild('splInsertField') splInsertField!: MatSelect;

  editSpl() {
    this.isEditingSplInsert = true;
    setTimeout(
      () => {
        this.splInsertField.focus()
        this.splInsertField.open()
      },
      10
    );
  }

  @ViewChild('assigneeField') assigneeField!: MatSelect;

  editAssignee() {
    this.isEditingAssignee = true;
    setTimeout(
      () => {
        this.assigneeField.focus()
        this.assigneeField.open()
      },
      10
    );
  }


  hasContactAddress?: {
    address?: string,
    number?: string,
    comp?: string
  };

  setContacAddres(contact?: Contact) {
    if(!contact) return
    if(!contact.address && !contact.addressComp && !contact.addressNumber){
      this.hasContactAddress = undefined;
      return;
    }
    this.hasContactAddress = {
      address: contact.address,
      number: contact.addressNumber,
      comp: contact.addressComp
    }
  }

  copyContactAddress() {
    if (this.hasContactAddress?.address || this.hasContactAddress?.number || this.hasContactAddress?.comp) {
      this.formGroup.get('addressControl')?.setValue(this.hasContactAddress?.address ?? null);
      this.formGroup.get('numberControl')?.setValue(this.hasContactAddress?.number ?? null);
      this.formGroup.get('compControl')?.setValue(this.hasContactAddress?.comp ?? null);
    }
  }

  protected readonly useAnimation = useAnimation;
  protected readonly environment = environment;
  protected readonly window = window;
  protected readonly findLabel = findLabel;
  protected readonly Plan = Plan;
}
