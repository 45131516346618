<mat-drawer-container
  [ngStyle]="{visibility: hidden ? 'hidden' : 'unset'}"
  class="example-container"
                      [hasBackdrop]="false">
  <mat-drawer #drawer mode="push">
    <div>Opa! Se quiser mesmo deletar esse usuário, clique na lixeira novamente.</div>
  </mat-drawer>
  <mat-drawer-content>
    <button mat-icon-button (click)="delete()">
      @if (deleting()) {
        <mat-spinner diameter="25" color="red"></mat-spinner>
      } @else {
        <mat-icon style="color: #DA2727" fontIcon="delete"></mat-icon>
      }
    </button>
  </mat-drawer-content>
</mat-drawer-container>
