import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Self,
  OnDestroy, Input, Output, output,
} from '@angular/core';
import {NgControl, Validators} from '@angular/forms';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ standalone: true, selector: '[formMask]' })
export class FormFieldParserDirective implements OnInit, OnDestroy {
  @Input('formMask') type?: 'cnpj' | 'cpf' | 'date' | 'phone' | 'time';
  onMaskApplied = output<string>();
  private el: HTMLInputElement;
  format!: (input: string) => string;

  constructor(
    private elementRef: ElementRef,
    @Self() private formControl: NgControl
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    if(!this.type) return
    switch (this.type) {
      case 'cnpj':
        this.el.maxLength = 18;
        this.format = this.formatCNPJ;
        break;
      case 'cpf':
        this.el.maxLength = 14;
        this.format = this.formatCPF;
        break;
      case 'date':
        this.el.maxLength = 10;
        this.format = this.formatDate
        break;
      case 'phone':
        this.el.maxLength = 15;
        this.formControl.control?.addValidators([Validators.minLength(15)]);
        this.format = this.formatPhoneNumber
        break;
      case 'time':
        this.el.maxLength = 5;
        this.format = this.formatTime
        break;
      default:
        this.el.maxLength = 18;
        break;
    }
    this.el.value = this.format(this.el.value);
  }

  @HostListener('keyup', ['$event.target.value'])
  onChange(value: string) {
    if(!this.type) return
    this.el.value = this.format(value);
    this.onMaskApplied.emit(this.el.value);
  }

  unformatCNPJ(cnpj: string): string {
    return cnpj.replace(/[\D]/g, '');
  }

  formatCNPJ(cnpj: string): string {
    const cleanValue = cnpj.replace(/\D/g, '');
    const match = cleanValue.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    if (!match) return cleanValue;

    const [, part1, part2, part3, part4, part5] = match;
    let formattedCNPJ = '';
    if (part1) {
      formattedCNPJ += part1;
    }
    if (part2) {
      formattedCNPJ += '.' + part2;
    }
    if (part3) {
      formattedCNPJ += '.' + part3;
    }
    if (part4) {
      formattedCNPJ += '/' + part4;
    }
    if (part5) {
      formattedCNPJ += '-' + part5;
    }

    return formattedCNPJ;
  }
  formatDate(date: string): string {
    const cleanValue = date.replace(/\D/g, '');
    const match = cleanValue.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    if (!match) return cleanValue;

    const [, day, month, year] = match;
    let formattedDate = '';
    if (day) {
      formattedDate += day;
    }
    if (month) {
      formattedDate += '/' + month;
    }
    if (year) {
      formattedDate += '/' + year;
    }

    return formattedDate;
  }

  formatCPF(cpf: string): string {
    const cleanValue = cpf.replace(/\D/g, '');
    const match = cleanValue.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    if (!match) return cleanValue;

    const [, part1, part2, part3, part4] = match;
    let formattedCPF = '';
    if (part1) {
      formattedCPF += part1;
    }
    if (part2) {
      formattedCPF += '.' + part2;
    }
    if (part3) {
      formattedCPF += '.' + part3;
    }
    if (part4) {
      formattedCPF += '-' + part4;
    }

    return formattedCPF;
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleanValue = phoneNumber.replace(/\D/g, '');
    const match = cleanValue.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    if (!match) return cleanValue;

    const [, areaCode, firstPart, secondPart] = match;
    let formattedPhone = '';
    if (areaCode) {
      formattedPhone += '(' + areaCode;
    }
    if (firstPart) {
      formattedPhone += ') ' + firstPart;
    }
    if (secondPart) {
      formattedPhone += '-' + secondPart;
    }

    return formattedPhone;
  }

  formatTime(time: string): string {
    const cleanValue = time.replace(/\D/g, '');
    const match = cleanValue.match(/(\d{0,2})(\d{0,2})/);
    if (!match) return cleanValue;

    const [, hours, minutes] = match;
    let formattedTime = '';
    if (hours) {
      formattedTime += hours;
    }
    if (minutes) {
      formattedTime += ':' + minutes;
    }

    return formattedTime;
  }

  ngOnDestroy(): void {
    // console.log('Directive Destroy');
  }
}
