import {inject, Injectable, signal} from '@angular/core';
import {SnackBarData} from '../../models/snackbarData';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../common/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private _snackBar = inject(MatSnackBar);

  constructor() {}
  openSnackBar(type: 'success' | 'error' | 'info' | 'warning' | 'notification',
               message: string, action: string, duration?: number, position: 'top' | 'bottom' = 'top') {
    return this._snackBar.openFromComponent(SnackBarComponent, {
      data: {type, message, action},
      duration: duration || 5000,
      horizontalPosition:'end',
      verticalPosition: position,
      panelClass: type+'-snackbar'
    });
  }
}
