<div class="main">
  <div class="header">
    <app-delete-confirmation
      [hidden]="!(event && event.id)"
      [deleting]="deleteLoading()"
      (deleteConfirmation)="deleteEvent()"/>
    <H3>
      {{ event ? 'Editar Evento' : 'Novo Evento' }}
    </H3>

    <button autofocus class="close" mat-icon-button (click)="close()">
      <mat-icon class="close-icon" fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="info">
      <form [formGroup]="formGroup">
        <div class="info-line">
          <div class="info-label">Nome:</div>
          <mat-form-field style="width: 100%;" subscriptSizing="dynamic">
            <input matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="info-line">
          <div class="info-label">Data:</div>
          <mat-form-field style="width: 181px;">
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div class="all-day">
            <mat-checkbox [formControl]="formGroup.controls.allDay">Dia inteiro</mat-checkbox>
          </div>
        </div>
        <div class="info-line time">
          <div class="info-label">Horário:</div>
          <mat-form-field style="width: 145px; pointer-events: none">
            <input matInput
                   [matTimepicker]="timepicker1"
                   formControlName="startTime">
            <mat-timepicker interval="20min" #timepicker1/>
            @if (!formGroup.controls.allDay.value) {
              <mat-timepicker-toggle [for]="timepicker1" matSuffix/>
            }
          </mat-form-field>
          <div class="until">
            <span> até </span>
          </div>
          <mat-form-field style="width: 145px;">
            <input matInput
                   [matTimepicker]="timepicker2"
                   [matTimepickerMin]="minHour"
                   formControlName="endTime">
            <mat-timepicker interval="20min" #timepicker2/>
            @if (!formGroup.controls.allDay.value) {
              <mat-timepicker-toggle [for]="timepicker2" matSuffix/>
            }
          </mat-form-field>
        </div>
        <div class="info-line">
          <div class="info-label">Local:</div>
          <mat-form-field style="width: 100%;" subscriptSizing="dynamic">
            <input matInput formControlName="location">
          </mat-form-field>
        </div>
        <div class="rich-text-holder">
          @if (editor && toolbar) {
            <div class="rich-header">
              <div class="info-label">Descrição:</div>
              <ngx-editor-menu
                [editor]="editor!"
                [toolbar]="toolbar!"
              ></ngx-editor-menu>
            </div>
            <ngx-editor formControlName="description" [ngClass]="'text-box'" [editor]="editor!"></ngx-editor>
          }
        </div>
      </form>
    </div>
    <mat-divider vertical="true"/>
    <div class="invitees-vertical">
      <div class="invitees">
        <div class="info-label">Convidados:</div>
        <div class="invitees-container">
          <mat-form-field>
            <input type="text"
                   matInput
                   [formControl]="inviteeSearch"
                   [matAutocomplete]="autoGroup">
            <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="updateInvitees($event)"
                              [displayWith]="displayFn">
              @for (user of users | async; track user.id) {
                <div [matTooltip]="'Somente a '+ secretaryName +' pode adicionar o vereadora'"
                     [matTooltipDisabled]="!(user.orgRole === 'VEREADOR' && currentUserOrgRole !== 'SECRETARIO' && user.id !== currentUserId)">
                  <mat-option
                    [value]="user" [disabled]="user.orgRole === 'VEREADOR' && currentUserOrgRole !== 'SECRETARIO' && user.id !== currentUserId">
                    <div style="display: flex;align-items: center; gap: 10px;">
                      <app-person-badge [user]="user" [selected]="false"/>
                      {{ user.name }}
                    </div>
                  </mat-option>
                </div>
              }
            </mat-autocomplete>
          </mat-form-field>
          <div class="invitee-list-container">
            @for (invitee of invitees; track invitee.id) {
              <div class="invitee-list">
                <app-person-badge [user]="invitee" [selected]="false"/>
                <span>{{ invitee.name }}</span>
                <div [ngClass]="{disabled: this.invitees.length === 1}" class="remove"
                     (click)="removeInvitee(invitee.id)">
                  <mat-icon fontIcon="close"></mat-icon>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row-reverse; margin: 10px; gap: 5px">
        <div class="save" (click)="save()">
          @if (saving()) {
            <mat-spinner diameter="25" color="black"></mat-spinner>
          } @else {
            <div>Salvar</div>
          }
        </div>
        <div class="cancel" (click)="cancel()">
          <div>Cancelar</div>
        </div>
      </div>
    </div>
  </div>
</div>
