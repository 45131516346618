import { Pipe, PipeTransform } from '@angular/core';
import dayjs, {Dayjs} from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';

@Pipe({
  standalone: true,
  name: 'gm_date'
})
export class GMDatePipe implements PipeTransform {

  transform(value?: number | string | Date | Dayjs, ...args: any[]): string {
    const [type] = args;
    if (value == null) {
      if (type === 'full_date') {
        return '-----';
      } else if (type === 'timestamp') {
        return '--/--/---- --:--:--';
      }
      return '--/--/----';
    }
    let format = 'DD/MM/YYYY';
    if (type === 'timestamp') {
      format = 'DD/MM/YYYY HH:mm:ss';
    }
    if (type === 'date-time') {
      format = 'DD/MM/YYYY HH:mm';
    }
    if (type === 'full_date') {
      format = 'dddd, DD [de] MMMM [de] YYYY';
    }
    if (type === 'hour-minute') {
      format = 'HH:mm';
    }
    if (typeof value === 'number') {
      return dayjs.unix(value).locale('pt-br').format(format);
    }
    return dayjs(value).locale('pt-br').format(format);

  }

}
