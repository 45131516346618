import {Component, effect, inject} from '@angular/core';
import {DeadlineFilterChange, DeadlineFilterComponent} from '../common/deadline-filter/deadline-filter.component';
import {ItemCounterComponent} from '../common/item-counter/item-counter.component';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatRipple} from '@angular/material/core';
import {BadgeUserData, PersonPickerComponent} from '../common/person-picker/person-picker.component';
import {User} from '../../models/user';
import {MatDialog} from '@angular/material/dialog';
import {CsTableComponent} from './cs-table/cs-table.component';
import {CS} from '../../models/cs';
import {CSService} from '../services/cs.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateCsComponent} from './create-cs/create-cs.component';
import {StatusGroup} from '../common/status-parser.pipe';
import {Location} from '@angular/common';
import {ContactService} from '../services/contact.service';

@Component({
  selector: 'app-customer-service',
  imports: [
    DeadlineFilterComponent,
    ItemCounterComponent,
    MatFormField,
    MatInput,
    MatLabel,
    MatRipple,
    PersonPickerComponent,
    CsTableComponent,
  ],
  templateUrl: './customer-service.component.html',
  styleUrl: './customer-service.component.scss'
})
export class CustomerServiceComponent {
  services: CS[] = [];

  statusMap = new Map<string, StatusGroup>([
    ['total', StatusGroup.total.setShowSeparator(true)],
    ['open', StatusGroup.open],
    ['onHold', StatusGroup.onHold],
    ['inProgress', StatusGroup.inProgress],
    ['deferred', StatusGroup.deferred],
    ['rejected', StatusGroup.rejected],
  ]);

  filterToday: boolean = false;
  filterUntil: boolean = false;

  filterDate?: Date;
  filterValue: string = '';

  csId?: string | null;
  contactId?: string

  usersToShowBadges?: User[];
  activatedRoute = inject(ActivatedRoute);
  location = inject(Location);
  contactService = inject(ContactService);


  constructor(private csService: CSService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.contactId = params['contactId'];
      this.location.go('/atendimentos')
    });
    this.activatedRoute.paramMap.subscribe((param) => {
      this.csId = param.get('csId') ?? undefined;
      this.update();
    });
    effect(() => {
      this.services = this.csService.csList();
      if(this.contactId && this.contactService.contactList().length) {
        this.filterValue = this.contactService.contactList()
          .find((contact) => contact.id === this.contactId)?.name ?? '';
        this.searchValue = this.filterValue.toLowerCase();
      }
      this.update();
    });
  }

  static updateUsers(css: CS[]): User[] | undefined {
    if (css.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of css) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  async update() {
    this.usersToShowBadges = CustomerServiceComponent.updateUsers(this.services);
    this.services = this.csService.csList();
    this.setStatusMap(this.services);
    if(this.csId && this.services.length) {
      this.openDialog(this.csId);
      this.csId = null;
    }
  }

  filteredTableData(taskData: CS[]) {
    if (taskData.length < this.services.length) {
      this.setStatusMap(taskData);
    } else {
      this.setStatusMap(this.services);
    }
  }

  setStatusMap(taskData: CS[]) {
    this.statusMap.get('total')?.setCount(taskData.length);
    this.statusMap.get('open')?.setCount(taskData.filter((task) => task.status === 'open').length);
    this.statusMap.get('onHold')?.setCount(taskData.filter((task) => task.status === 'onHold').length);
    this.statusMap.get('inProgress')?.setCount(taskData.filter((task) => task.status === 'inProgress').length);
    this.statusMap.get('deferred')?.setCount(taskData.filter((task) => task.status === 'deferred').length);
    this.statusMap.get('rejected')?.setCount(taskData.filter((task) => task.status === 'rejected').length);
  }

  readonly dialog = inject(MatDialog);
  usersToFilter: User[] = []
  toBeDeleted: string = '';

  openDialog(csId?: string): void {
    const dialogRef = this.dialog.open(CreateCsComponent, {
      minWidth: '1120px',
      height: '600px',
      data: {
        csData: csId ?  this.csService.csList().find((cs) => cs.id === csId) : undefined,
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.delete) {
        this.toBeDeleted = result.delete;
      }
      this.location.go('atendimentos');
      this.update();
    });
  }

  timestampChange(event: DeadlineFilterChange) {
    this.filterToday = event.today ?? false;
    this.filterUntil = event.until ?? false;
  }

  timestampDateChange(event?: Date) {
    this.filterDate = event;
  }

  searchValue: string = '';

  newTask() {
    this.openDialog();
  }

  openDetails(taskId: string) {
    this.openDialog(taskId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
  }

  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
  }
}
