export type User = {
  id: string;
  name: string;
  picture?: string;
  badgeColor: string;
  email: string;
  phone: string;
  password?: string;
  birthday?: number | string;
  orgRole?: string;
}

export type UserRef = {
  userId: string;
  role: string;
}

export type NewUser = {
  name: string;
  email: string;
  phone: string;
  password: string;
  badgeColor: string;
  birthday: number;
}

export const badgeColors: string[] = [
  '#00A906', // Bright green
  '#DA40D0', // Vibrant purple
  '#1D3BB5', // Rich blue
  '#F28C28', // Warm orange
  '#5C3C92', // Deep violet
  '#209F5F', // Fresh teal
  '#B82058', // Bold magenta
  '#3272F6', // Bright royal blue
  '#E64535', // Vibrant red
  '#88C723', // Lime green
  '#C074E6', // Soft lavender
  '#2867A5'
]
