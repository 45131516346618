<div class="header">
  <app-delete-confirmation
    [hidden]="isNew"
    [deleting]="false"
    (deleteConfirmation)="deleteCS()"/>

  @if (isNew) {
    <div class="task-name">Adicionar Atendimento</div>
  } @else {
    <div class="task-name">{{ csProtocol }} - {{ csType }} - {{ csTimestamp | gm_date }}</div>
  }
  <button mat-icon-button (click)="close()" autofocus>
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="content">
    <div class="left" [style.height]="isNew ? '435px' : '510px'" [style.width]="isNew ? '654px' : '590px'">
      <div class="left-entry">
        <div class="title">Contato:</div>
        @if (!isEditingContact) {
          <div class="contact"
               (click)="isEditingContact = !isEditingContact">{{ formGroup.controls['contactControl'].value?.name ?? '' }}
          </div>
          <button mat-icon-button>
            <mat-icon class="go-icon" fontIcon="open_in_new"></mat-icon>
          </button>
        } @else {
          <form [formGroup]="formGroup">
            <mat-form-field>
              <mat-label>Contatos</mat-label>
              <input type="text"
                     matInput
                     formControlName="contactControl"
                     [matAutocomplete]="autoGroup">
              <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="updateContact($event)"
                                [displayWith]="displayFn">
                @for (contact of contactOptions | async; track contact.id) {
                  <mat-option [value]="contact">{{ contact.name }}</mat-option>
                }
                @if (formGroup.controls['contactControl'].value ?? '' !== '') {
                  <mat-option
                    [value]="{id: '__new_contact__' + (formGroup.controls['contactControl'].value ?? ''), name: (formGroup.controls['contactControl'].value ?? '')}">
                    Novo
                    contato: {{ (formGroup.controls['contactControl'].value?.name ?? formGroup.controls['contactControl'].value ?? '') }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </form>
          <mat-icon #tooltip="matTooltip"
                    matTooltip="Digite o nome e clique em 'Novo contato: nome' para adicionar um contato novo."
                    fontIcon="info"></mat-icon>
        }
      </div>
      <div class="left-entry">
        <div class="title">Tipo:</div>
        @if (!isEditingType) {
          <p class="contact" (click)="isEditingType = !isEditingType">{{ csType }}</p>
        } @else {
          <form [formGroup]="formGroup">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Tipo</mat-label>
              <mat-select [value]="csType" (selectionChange)="updateType($event)" formControlName="typeControl">
                @for (t of typesOfCS; track t) {
                  <mat-option [value]="t">
                    <p>{{ t }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </form>
        }
      </div>
      <div class="left-entry-edit-date-time">
        <div class="title">Data e horário:</div>
        @if (!isEditingDateAndTime) {
          <p class="contact"
             (click)="isEditingDateAndTime = !isEditingDateAndTime">{{ csDateTime | gm_date:'timestamp' }}</p>
        } @else {
          <mat-form-field>
            <mat-label>Dia</mat-label>
            <input matInput [matDatepicker]="datepicker" [(ngModel)]="csDateTime" (dateChange)="updateDateTime()">
            <mat-datepicker #datepicker/>
            <mat-datepicker-toggle [for]="datepicker" matSuffix/>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horário</mat-label>
            <input matInput
                   [matTimepicker]="timepicker"
                   [(ngModel)]="csDateTime"

                   [ngModelOptions]="{updateOn: 'blur'}">
            <mat-timepicker #timepicker/>
            <mat-timepicker-toggle [for]="timepicker" matSuffix/>
          </mat-form-field>
        }
      </div>

      <div class="left-entry">
        <div class="title">Endereço:</div>
        @if (isNew) {
          <form [formGroup]="formGroup">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Endereço</mat-label>
              <input matInput placeholder="Rua das Ruas" formControlName="addressControl">
            </mat-form-field>
          </form>
        } @else if (!isEditingAddress) {
          <p class="contact" (click)="isEditingAddress = !isEditingAddress">{{ csAddress | emptyView }}</p>
        } @else {
          <app-editable-input [formName]="'Endereço'" [value]="csAddress"
                              (onCancel)="closeAddress()" (onSave)="updateAddress($event)"></app-editable-input>
        }
      </div>

      <div class="left-entry">
        <p class="title">Responsável:</p>
        @if (!isEditingAssignee) {
          @if (csAssignee) {
            <app-person-badge [selected]="false" [user]="csAssignee"></app-person-badge>
          }
          <p class="contact" (click)="isEditingAssignee = !isEditingAssignee">{{ csAssignee?.name | emptyView }}</p>
        } @else {
          <section class="assignee-cell">
            @if (csAssignee) {
              <app-person-badge [selected]="false" [user]="csAssignee"></app-person-badge>
            }
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Responsável</mat-label>
              <mat-select [value]="csAssignee?.id" (selectionChange)="selectAssignee($event)">
                <mat-select-trigger>
                  @if (csAssignee) {
                    <span>{{ csAssignee.name }}</span>
                  }
                </mat-select-trigger>
                @for (person of userService.users(); track person.id) {
                  <mat-option [value]="person.id">
                    <app-person-badge [selected]="false" [user]="person"></app-person-badge>
                    <p>{{ person.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </section>
        }
      </div>

      <div class="title" style="padding-top: 10px">Descrição:</div>

      <div class="left-entry-edit-description">
        @if (!isEditingDescription) {
          @if (html === '' || html == null) {
            <div class="description"
                 (click)="isEditingDescription = !isEditingDescription">{{ undefined | emptyView }}
            </div>
          } @else {
            <div class="description" (click)="isEditingDescription = !isEditingDescription" [innerHTML]="html"></div>
          }
        } @else {
          @if (editor && toolbar) {
            <div [style.width]="isNew ? '654px' : '654px'">
              <ngx-editor-menu
                [editor]="editor"
                [toolbar]="toolbar!"

              ></ngx-editor-menu>
              <ngx-editor [editor]="editor" [(ngModel)]="html"></ngx-editor>
            </div>
            @if (!isNew) {
              <div class="button-edit">
                <div class="save btn" mat-ripple (click)="saveDescription()">
                  <div>Salvar</div>
                </div>
                <div class="cancel btn" mat-ripple (click)="closeDescription()">
                  <div>Cancelar</div>
                </div>
              </div>
            }

          }

        }

      </div>
      @if (csId) {
        <div style="display: flex; flex-direction: row-reverse; margin-top: 10px;">
          <div mat-ripple class="upload" (click)="fileInput.click()">
            <mat-icon fontIcon="upload"></mat-icon>
            <div>Adicionar arquivos</div>
          </div>
          <input #fileInput type="file" hidden (change)="fileUpload($event)">

        </div>
        <p class="title">Documentos</p>

        <app-file-handler [referenceId]="csId" [uploadFileList]="uploadFileList"></app-file-handler>

      }

    </div>
    <div class="vertical-divider" [style.height]="isNew? '465px' : '530px'"></div>
    <div class="right">

      <section #status class="entry-status">
        <section class="status-cell">
          <app-status-badge size="large" [nameId]="csStatus" (click)="showOverlay()" [type]="'cs'"></app-status-badge>
        </section>
      </section>

      <section class="entry">
        <p class="title small-title">Protocolo:</p>
        @if (isNew) {
          <form [formGroup]="formGroup">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Nome</mat-label>
              <input matInput placeholder="Tarefa X" #input formControlName="nameControl">
            </mat-form-field>
          </form>
        } @else {
          <div class="task-name">{{ csProtocol }}</div>
        }
      </section>

      <section class="entry">
        <p class="title small-title">Órgão:</p>
        @if (!isEditingEntity) {
          <p class="description entity" (click)="isEditingEntity = !isEditingEntity">{{ csEntity | emptyView }}</p>
        } @else {
          @if (isNew) {
            <form [formGroup]="formGroup">
              <mat-form-field subscriptSizing="dynamic">
                <mat-label>Órgão</mat-label>
                <input matInput placeholder="SPL" formControlName="entityControl">
              </mat-form-field>
            </form>
          } @else {
            <app-editable-input [formName]="'Órgão'" [value]="csEntity"
                                (onCancel)="isEditingEntity = false"
                                (onSave)="updateEntity($event)"></app-editable-input>
          }
        }
      </section>
      @if (csId) {
        <p class="title">Histórico</p>
        <div class="history">
          @for (history of historyList; track history.id) {
            <app-history [history]="history"></app-history>
          }
        </div>
        @if (historyList.length === 0) {
          <div class="empty-history">Nenhum histórico encontrado.</div>
        }
      }
    </div>
  </div>
  @if (isNew) {
    <div class="footer">
      <div class="buttons">
        @if (loading) {
          <mat-progress-spinner [diameter]="25" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
        }
        <div class="save" mat-ripple (click)="saveAll()">
          <div>Salvar</div>
        </div>
        <div class="cancel" mat-ripple (click)="dialogRef.close()">
          <div>Cancelar</div>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>
