import {ChangeDetectionStrategy, Component, effect, inject} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {NgIf} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatRipple} from '@angular/material/core';
import {User} from '../../models/user';
import {UserTable} from './user-table/user-table.component';
import {MatDialog} from '@angular/material/dialog';
import {UserDetailsComponent} from './user-details/user-details.component';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-users',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    MatProgressSpinner,
    MatRipple,
    UserTable
  ],
  templateUrl: './users.component.html',
  standalone: true,
  styleUrl: './users.component.scss',
})
export class UsersComponent {
  searchValue: string = '';
  users: User[] = [];

  constructor(
    private usersService: UserService) {
    //trigger update user list
    this.usersService.updateList();
    // Subscribe to the signal using an effect
    effect(() => {
     this.users = this.usersService.users();
    });
  }


  readonly dialog = inject(MatDialog);

  openDialog(userId?: string): void {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '720px',
      maxWidth: '50vw',
      height: userId ? '450px' : '550px',
      maxHeight: '70vh',
      data: this.users.find((user) => user.id === userId),
      panelClass: 'custom-dialog-container'
    });
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
  }

  newUser() {
    this.openDialog();
  }

  openDetails(id: string) {
    this.openDialog(id);
  }
}
