import {Injectable, signal} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {History} from '../../models/history';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  historyList = signal<Map<string, History[]>>(new Map());

  constructor(private http: HttpClient) {

  }

  async getHistoryForRelation(relationshipId: string) {
    const history = await lastValueFrom(this.http.get<History[]>(`${environment.backendUrl}/history/relationship/${relationshipId}`));
    this.historyList().set(relationshipId, history);
    this.historyList.set(new Map(this.historyList()));
    return history;
  }
}
