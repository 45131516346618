import {User} from './user';

export type OrganizationPositions = 'VEREADOR' | 'SECRETARIO' | 'CHEFE_DE_GABINETE' | 'ASSESSOR' | 'ESTAGIARIO';
export type OrganizationRole = 'ADMIN' | 'NORMAL';

export type OrganizationRolePositions = {
  id: string,
  name: string
}

export const OrganizationPositions: {[key: string]: OrganizationRolePositions} = {
  VEREADOR: {
    id: 'VEREADOR',
    name: 'Vereador(a)'
  },
  SECRETARIO: {
    id: 'SECRETARIO',
    name: 'Secretário(a)'
  },
  CHEFE_DE_GABINETE: {
    id: 'CHEFE_DE_GABINETE',
    name: 'Chefe de Gabinete'
  },
  ASSESSOR: {
    id: 'ASSESSOR',
    name: 'Assessor(a)'
  },
  ESTAGIARIO: {
    id: 'ESTAGIARIO',
    name: 'Estagiário(a)'
  },
}

export const OrganizationRoles: {[key: string]: OrganizationRolePositions} = {
  ADMIN: {
    id: 'ADMIN',
    name: 'Administrador(a)'
  },
  NORMAL: {
    id: 'NORMAL',
    name: 'Usuário(a)'
  }
}

export class UsersOnOrganizationEntity {
  user_id: string;
  organization_id: string;
  role: OrganizationRole;
  position: OrganizationPositions;
  organization?: OrganizationEntity;

  constructor(user_id: string, organization_id: string, role: OrganizationRole, position: OrganizationPositions) {
    this.user_id = user_id;
    this.organization_id = organization_id;
    this.role = role;
    this.position = position;
  }
}

export class OrganizationEntity {
  id: string;
  name: string;
  description: string;
  type: string;
  domain: string;
  plan?: string;
  users: User[];
  subscriptions?: SubscriptionEntity[];
  style: {[key: string]: string | number | boolean | null};

  constructor(id: string, name: string, description: string, type: string, domain: string, users: User[] = [], style: {[key: string]: string | number | boolean | null} = {}, plan?: string) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
    this.domain = domain;
    this.users = users
    this.style = style
    this.plan = plan
  }
}

export class SubscriptionEntity {
  id: string;
  userId: string;
  organizationId: string;
  productId: string;
  transactionId: string;
  purchaseDate: number;
  status: string;
  receiptData: string;
  validationStatus: boolean;
  startDate: number;
  endDate: number;
  autoRenewal: boolean;
  platform: string;

  constructor(id: string,
              userId: string,
              organizationId: string,
              productId: string,
              transactionId: string,
              purchaseDate: number,
              status: string,
              receiptData: string,
              validationStatus: boolean,
              startDate: number,
              endDate: number,
              autoRenewal: boolean,
              platform: string
  ) {
    this.id = id;
    this.userId = userId;
    this.organizationId = organizationId;
    this.productId = productId;
    this.transactionId = transactionId;
    this.purchaseDate = purchaseDate;
    this.status = status;
    this.receiptData = receiptData;
    this.validationStatus = validationStatus;
    this.startDate = startDate;
    this.endDate = endDate;
    this.autoRenewal = autoRenewal;
    this.platform = platform;
  }
}
