import {Component, effect, Input, input, OnInit, output} from '@angular/core';
import {MatError, MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';
import {MatInput, MatInputModule} from '@angular/material/input';
import {MatOption, MatRipple} from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from '@angular/material/datepicker';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-editable-input',
  imports: [
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatRipple,
    ReactiveFormsModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelect,
    MatOption,
  ],
  templateUrl: './editable-input.component.html',
  standalone: true,
  styleUrl: './editable-input.component.scss'
})
export class EditableInputComponent implements OnInit{
  value = input.required<number | string | undefined>();
  formName = input.required<string>();
  placeHolder = input<string>();
  type = input<string>('text');
  @Input() validators: ValidatorFn[] =[];
  @Input() options: ({value: string, label: string} | string)[] = [];
  onSave = output<string | undefined>();
  onCancel = output<void>();
  control= new FormControl<any>( '');
  _placeHolder = '';
  _options :{value: string, label: string}[] = [];

  constructor() {
    effect(() => {
      let value:any = this.value();
      if(this.type() === 'date' && value){
        value = new Date(value!);
      }
      this.control.setValue( value || '');
      this._placeHolder = this.placeHolder() ||  this.formName();
    });
  }


  saveClick() {
    if(this.control.valid){
      let value = this.control.value;
      if(this.type() === 'text'){
        value = value.trim();
      }
      this.onSave.emit(value);
    }
  }

  cancelClick () {
    this.onCancel.emit();
  }

  ngOnInit(): void {
    if (this.validators && this.validators?.length) {
      this.control.setValidators(this.validators!)
    }

    if (this.options) {
      this._options = this.options.map((option) => {
        if (typeof option === 'string') {
          return {value: option, label: option}
        } else {
          return option
        }
      })
    }
  }
}
