<div class="user-container">
  <div class="page-header">
    <span class="page-title">Gerenciar Usuários</span>
  </div>
  <span class="h2" style="font-weight: bold">Filtros</span>
  <div class="filters">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Pesquise por nome, telefone ou email</mat-label>
      <input matInput (keyup)="search($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <div mat-ripple class="save"  (click)="newUser()">
      <div>+ Novo Usuário</div>
    </div>
  </div>
  <div style="display: flex; width: 100%; align-items: center; justify-content: center">
    <mat-spinner *ngIf="this.users.length === 0"></mat-spinner>
  </div>
  <app-user-table *ngIf="this.users.length > 0" [values]="this.users" [searchValue]="searchValue"
                  (openDetails)="openDetails($event)" ></app-user-table>
</div>
