<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="eventTile.isOpened"
  (backdropClick)="eventTile.isOpened = false"
>
  <div class="example-list">
    <div class="header">
      <button mat-icon-button (click)="eventTile.isOpened = false">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="task-name">
        <div class="task-color" [style.background-color]="eventTile.color"></div>
        <p class="name">{{ eventTile.title }}</p>
        @if(eventTile.type === 'event') {
          <button mat-icon-button (click)="triggerDetails()">
            <mat-icon fontIcon="edit"/>
          </button>
        }
      </div>
      <div class="task-deadline">
        <div class="task-deadline-empty"></div>
        <p>{{ eventTile.date | gm_date:'full_date' }}</p>
      </div>
      @if (eventTile.link) {
        <div class="task-origin">
          <div>Origem:</div>
          <a [href]="window.location.origin + eventTile.link">{{ eventTile.title }}</a>
        </div>
      }
      @if (eventTile.type === 'task') {
        <div class="task-assignee">
          <div>Responsável:</div>
          <app-person-badge [selected]="false" [user]="eventTile.users[0]"></app-person-badge>
          <p>{{ eventTile.users.length && eventTile.users[0].name || '--' }}</p>
        </div>
      }
      @if (eventTile.type === 'event') {
        <div class="description">
          <div class="info-label">Local: {{eventTile.meta.location}}</div>
        </div>
        <div class="description">
          <div class="info-label">Horario: {{eventTile.meta.startTime | gm_date : 'hour-minute' }} até {{eventTile.meta.endTime | gm_date : 'hour-minute'}}</div>
        </div>
        @if (eventTile.meta.description) {
          <div class="description">
            <div class="info-label">Descrição:</div>
            <div class="description-content" [innerHTML]="eventTile.meta.description"></div>
          </div>
        }
        <div class="invitees">
          <div>Convidados:</div>
          <div class="invitee-list">
            @for (user of eventTile.users; track user.id) {
              <div class="invitee-item">
                <app-person-badge [user]="user" [selected]="false"/>
                <span>{{ user.name }}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
