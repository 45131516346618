import {ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter} from '@angular/material/core';
import {Platform} from '@angular/cdk/platform';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {DUDU_DATE_FORMATS} from '../../utils';

export type DeadlineFilterChange = Partial<{
  today: boolean | null;
  late: boolean | null;
  until: boolean | null
}>

@Component({
  selector: 'app-deadline-filter',
  imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule, MatFormField, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatIconModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './deadline-filter.component.html',
  styleUrl: './deadline-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeadlineFilterComponent {
  private readonly _formBuilder = inject(FormBuilder);
  @Output() onChange: EventEmitter<DeadlineFilterChange> = new EventEmitter();
  @Output() onDateChange: EventEmitter<Date | undefined> = new EventEmitter();
  readonly dateControl :FormControl<Date | undefined> = new FormControl();

  showLate = input<boolean>(true);
  title = input<string>('Prazo:');

  readonly toppings = this._formBuilder.group({
    today: false,
    late: false,
    until: false,
  });

  constructor() {
    this.toppings.valueChanges.subscribe((value) => {
      this.onChange.emit(value);
    });
    this.dateControl.valueChanges.subscribe((value) => {
      if (value) {
        this.toppings.controls.until.setValue(true);
      }
      this.onDateChange.emit(value);
    } );
  }
}
