import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Task} from '../../models/task';
import {environment} from '../../environments/environment';
import {TaskRelationship} from '../../models/task-relationship';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  taskList = signal<Task[]>([]);
  allRelationships = signal<TaskRelationship[]>([]);

  url = environment.backendUrl;

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.tasks.pipe(startWith(null))
      .subscribe(() => {
        this.getAllTasks();
        this.getAllTaskRelationships();
    })
  }

  async getAllTasks() {
    const results = await lastValueFrom(this.http.get<Task[]>(this.url + '/tasks'));
    this.taskList.set(results);
    return results;
  }

  async updateTaskDescription(id: string, description: string) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/description`, {description}));
    await this.getAllTasks();
  }

  async updateTaskAssignee(id: string, assigneeUserId: string) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/assigneeUserId`, {assigneeUserId}));
    await this.getAllTasks();
  }

  async updateTaskName(id: string, name: string) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/name`, {name}));
    await this.getAllTasks();
  }

  async updateTaskStatus(id: string, status: string) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/status`, {status}));
    await this.getAllTasks();
  }

  async updateTaskDeadline(id: string, deadline: number | null) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/deadline`, {deadline}));
    await this.getAllTasks();
  }

  async updateTaskDocuments(id: string, documentName: string, action: 'add' | 'remove') {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${id}/documents`, {documentName, action}));
    await this.getAllTasks();
  }

  async createTask(name: string, description: string, status: string, assigneeUserId?: string, relationshipId?: string, deadline?: number) {
    const response = await lastValueFrom(this.http.post<{ id: string }>(`${this.url}/tasks`, {
      name,
      description,
      status,
      relationshipId: relationshipId ?? null,
      assigneeUserId: assigneeUserId ?? null,
      deadline: deadline ?? null
    }));
    await this.getAllTasks();
    return response;
  }

  async deleteTask(id: string) {
    await lastValueFrom(this.http.delete<{ id: string }>(`${this.url}/tasks/${id}`));
    await this.getAllTasks();
  }

  async updateRelationship(taskId: string, relationshipId: string, type: string) {
    await lastValueFrom(this.http.put<Task>(`${this.url}/tasks/${taskId}/relationships/${relationshipId}`, {type}));
    await this.getAllTasks();
  }

  async clearRelationships(taskId: string) {
    await lastValueFrom(this.http.delete<Task>(`${this.url}/tasks/${taskId}/relationships`));
    await this.getAllTasks();
  }

  async getAllTaskRelationships(taskId: string = '*') {
    const result = await lastValueFrom(this.http.get<TaskRelationship[]>(this.url + `/tasks/${taskId}/relationships`));
    this.allRelationships.set(result);
    return result;
  }
}
