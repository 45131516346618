import {Component, effect, EventEmitter, inject, input, OnInit, Output} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatRipple} from '@angular/material/core';
import {
  uploadBytes,
  ref,
  Storage,
  list,
  deleteObject,
  getDownloadURL,
  getMetadata
} from '@angular/fire/storage';
import {GmFile} from '../../../models/gm-file';
import {GMDatePipe} from '../date.pipe';
import dayjs from 'dayjs';

@Component({
  selector: 'app-file-handler',
  imports: [
    MatIconButton,
    MatIcon,
    MatRipple,
    GMDatePipe
  ],
  templateUrl: './file-handler.component.html',
  styleUrl: './file-handler.component.scss'
})
export class FileHandlerComponent implements OnInit {
  referenceId = input.required<string>();
  uploadFileList = input<File[]>();
  @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileRemoved = new EventEmitter<GmFile>();

  files: GmFile[] = [];

  removeFile(file: GmFile) {
    deleteObject(ref(this.storage, `uploads/${this.referenceId()}/${file.id}`)).then(() => {
      this.files = this.files.filter((f) => f.id !== file.id);
      this.fileRemoved.emit(file);
    } );
  }

  async download(file: GmFile) {
    const url = await getDownloadURL(ref(this.storage, `uploads/${this.referenceId()}/${file.id}`));
    window.open(url, '_blank');
  }

  storage = inject(Storage);

  constructor() {
    effect(() => {
      const files = this.uploadFileList();
      this.files.push(...(files ?? []).map((file) => {
        return {
          id: file.name,
          name: file.name,
          url: '', timestamp: ''
        }
      }));
      if (files) {
        files.forEach(async (file) => {
          uploadBytes(ref(this.storage, `uploads/${this.referenceId()}/${file.name}`), await file.arrayBuffer())
          .then(() => {
            this.fileUploaded.emit(file);
            console.log('file uploaded');
          });
        });
      }

      this.files = this.files.sort((a, b) => {
        return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1;
      })
    });
  }

  ngOnInit() {
    list(ref(this.storage, `uploads/${this.referenceId()}`)).then((res) => {
      res.items.forEach(async (item) => {
        const metadata = await getMetadata(item);
        this.files.push({
          id: item.name,
          name: item.name,
          url: '',
          timestamp: metadata.timeCreated
        })
        console.log(item);
      });
    }).catch((err) => {
      console.error(err);
    }   );
  }

  protected readonly dayjs = dayjs;
}
