import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatRipple} from '@angular/material/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-button-w-loader',
  imports: [
    MatProgressSpinner,
    MatRipple,
    NgClass
  ],
  templateUrl: './button-w-loader.component.html',
  standalone: true,
  styleUrl: './button-w-loader.component.scss'
})
export class ButtonWLoaderComponent {

  @Output() buttonClick = new EventEmitter();
  @Input() loading = false;
  @Input() disabled = false;
  @Input() type: 'primary' | 'yellow' | 'outline' = 'primary';
  @Input() label!: string;

  _click(){
    if(!this.loading && !this.disabled){
      this.buttonClick.emit();
    }
  }

}
