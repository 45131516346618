import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Contact} from '../../models/contact';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  contactList = signal<Contact[]>([])

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.contacts.pipe(startWith(null))
      .subscribe(()=>{
        this.getAllContacts();
      })
  }

  async getAllContacts(): Promise<Contact[]> {
    const contacts = await lastValueFrom(this.http.get<Contact[]>(`${environment.backendUrl}/contacts`));
    this.contactList.set(contacts);
    return contacts;
  }

  async updateContactName(id: string, name: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/name`, {name}));
    await this.getAllContacts();
  }

  async updateContactEmail(id: string, email: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/email`, {email}));
    await this.getAllContacts();
  }

  async updateContactAddress(id: string, address: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/address`, {address}));
    await this.getAllContacts();
  }

  async updateContactPhone(id: string, phone: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/phone`, {phone}));
    await this.getAllContacts();
  }

  async updateContactBirthday(id: string, birthday: number) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/birthday`, {birthday}));
    await this.getAllContacts();
  }

  async updateContactGender(id: string, gender: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/gender`, {gender}));
    await this.getAllContacts();
  }

  async updateContactMetadata(id: string, metadata: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/metadata`, {metadata}));
    await this.getAllContacts();
  }

  async updateContactAssignee(id: string, assigneeUserId: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/assigneeUserId`, {assigneeUserId}));
    await this.getAllContacts();
  }

  async updateContactCnpj(id: string, cnpj: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/cnpj`, {cnpj}));
    await this.getAllContacts();
  }

  async updateContactLineOfWork(id: string, lineOfWork: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/lineOfWork`, {lineOfWork}));
    await this.getAllContacts();
  }

  async updateContactPicture(id: string, pictureId: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/picture`, {pictureId}));
    await this.getAllContacts();
  }

  async updateContactType(id: string, type: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/type`, {type}));
    await this.getAllContacts();
  }

  async updateContactAddressNumber(id: string, addressNumber: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/addressNumber`, {addressNumber}));
    await this.getAllContacts();
  }

  async updateContactAddressComp(id: string, addressComp: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/addressComp`, {addressComp}));
    await this.getAllContacts();
  }

  async updateContactCity(id: string, city: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/city`, {city}));
    await this.getAllContacts();
  }

  async updateContactSite(id: string, site: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/site`, {site}));
    await this.getAllContacts();
  }

  async updateContactProfession(id: string, profession: string) {
    await lastValueFrom(this.http.put<Contact>(`${environment.backendUrl}/contacts/${id}/profession`, {profession}));
    await this.getAllContacts();
  }

  async createContact(name: string, type: string, address?: string, email?: string,
                      phone?: string, birthday?: number, gender?: string, metadata?: string,
                      assigneeUserId?: string, pictureId?: string, cnpj?: string, lineOfWork?: string,
                      addressNumber?: string, addressComp?: string, city?: string, site?: string, profession?: string
                      ) {
    const c = await lastValueFrom(this.http.post<{id: string}>(`${environment.backendUrl}/contacts`, {
      name, type, bairro: address, email, phone, birthday, gender, metadata, assigneeUserId, address, pictureId,
      cnpj, lineOfWork, addressNumber, addressComp, city, site, profession
    }));
    await this.getAllContacts();
    return c;
  }

  async deleteContact(id: string) {
    await lastValueFrom(this.http.delete<{id: string}>(`${environment.backendUrl}/contacts/${id}`));
    await this.getAllContacts();
  }
}
