import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter, inject,
  Input,
  model, ModelSignal,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {MatCard} from '@angular/material/card';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';
import {Platform} from '@angular/cdk/platform';
import {DUDU_DATE_FORMATS} from '../../utils';
import {interval} from 'rxjs';
import dayjs, {Dayjs} from 'dayjs';
import {TileEvent} from '../calendar.component';
import {EventOverlayComponent} from '../event-overlay/event-overlay.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '../../services/event.service';

type DayEvent = TileEvent & {
  position: number;
  height: number;
}


@Component({
  selector: 'app-day-calendar',
  imports: [
    MatCalendar,
    MatCard,
    EventOverlayComponent,
    CdkOverlayOrigin,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    }],
  templateUrl: './day-calendar.component.html',
  standalone: true,
  styleUrl: './day-calendar.component.scss'
})
export class DayCalendarComponent implements AfterViewInit, OnChanges {

  @Input({required: true}) day: Dayjs = dayjs();
  @Input({required: true}) todayEvents: TileEvent[] = [];
  @Input() selectedId?: string;
  @Output() dayChange = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @ViewChild('calendar', {static: false}) calendar?: MatCalendar<Date>;

  selectedDay = model<Date | null>(new Date);

  allDayEvents: TileEvent[] = [];

  hours = Array.from({length: 24}, (_, i) => i);

  nowPosition: string = '0px';
  showNow = false;

  clock$ = interval(1000);

  currentUserId?: string

  activatedRoute = inject(ActivatedRoute);
  eventService = inject(EventService);

  idFromRoute: string | null = null;

  constructor(
    private authService: AuthService,
  ) {
    this.positionNow();
    this.updateTasks();
    this.clock$.subscribe((minuteCount) => {
      this.positionNow()
    });
    effect(() => {
      this.currentUserId = this.authService.loggedUser()?.id;
      this.updateTasks();
    });

    this.eventService.eventId$.subscribe(id => {
      if (id) {
        this.idFromRoute = id;
      }
    });
  }

  emitDay(event: ModelSignal<Date | null> | null) {
    if (!event) {
      return
    }
    this.dayChange.emit(dayjs(event.toString()));
  }

  eventArray: DayEvent[][] = [[]];

  positionNow() {
    const now = new Date();
    this.showNow = dayjs(this.selectedDay() ?? now).isSame(dayjs(now), 'day');
    let a = now.getHours() * 60 + now.getMinutes() + 60;
    a = a * 1.6666;
    if (a > 2400) {
      a = a - 2400;
    }
    this.nowPosition = `${a}px`;
  }

  container: HTMLElement | null = null;

  ngAfterViewInit(): void {
      this.container = document.getElementById('day');
    setTimeout(() => {
      const element = document.getElementById('now');
      if (this.container && element) {
        const offset = 100; // Adjust the offset
        const elementPosition = element.offsetTop; // Position of the element relative to its container
        const offsetPosition = elementPosition - offset;
        this.container.scrollTo({top: offsetPosition, behavior: 'instant'});
      }
    }, 1)
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes['day']) {
        this.selectedDay.set(dayjs(this.day).toDate());
        this.updateCalendarMonth();
        this.positionNow();
      }
      this.updateTasks();
    })
  }

  asDayEvent(event: TileEvent): DayEvent {
    const start = this.timeToVertical(event.meta.startTime!);
    const end = this.timeToVertical(event.meta.endTime!);
    if ( this.container && (event.id === this.idFromRoute)) {
      this.idFromRoute = null;
      this.eventService.resetEvent();
      event.isOpened = true;
      this.updateCalendarMonth();
      this.container.scrollTo({top: start - 100, behavior: 'instant'});
    }
    return {
      ...event,
      position: start,
      height: end - start - 1
    }
  }

  updateCalendarMonth() {
    if (this.calendar && this.selectedDay()) {
      this.calendar._goToDateInView(this.selectedDay()!, 'month')
    }
  }

  timeToVertical(time: number) {
    const timeJs = dayjs.unix(time);
    let a = timeJs.hour() * 60 + timeJs.minute() + 60;
    a = a * 1.6666;
    if (a > 2400) {
      a = a - 2400;
    }
    return a;
  }

  openDialog(eventId: string) {
    this.edit.emit(eventId);
  }


  updateTasks() {
    this.allDayEvents = [];
    this.eventArray = [[], []];

    this.todayEvents.forEach((event) => {
      if (event.allDay) {
        if (this.idFromRoute && event.meta.id === this.idFromRoute && this.container) {
          this.idFromRoute = null;
          this.eventService.resetEvent();

          event.isOpened = true;
          this.updateCalendarMonth();
        }
        this.allDayEvents.push(event);
        return;
      }
      let laneIndex = 0;
      let eventIndex = 0;
      while (true) {
        if (this.eventArray[laneIndex] === undefined) {
          this.eventArray[laneIndex] = [];
        }
        const lane = this.eventArray[laneIndex];
        const laneLength = lane.length;
        if (laneLength === 0) {
          lane.push(this.asDayEvent(event));
          return;
        }
        const eventInLane = lane[eventIndex];

        if (
          eventInLane.meta.startTime! < event.meta.endTime! &&
          eventInLane.meta.endTime! > event.meta.startTime!
        ) {
          laneIndex++;
          eventIndex = 0;
          continue;
        }
        if ((laneLength - 1) > eventIndex) {
          eventIndex++;
          continue;
        }
        lane.push(this.asDayEvent(event));
        return;
      }
    })
    this.allDayEvents.sort((a, b) => a.meta.id! - b.meta.id!);
  }

  protected readonly dayjs = dayjs;
}
