import {Component, effect, ElementRef, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatOption, MatRipple, provideNativeDateAdapter,} from '@angular/material/core';
import dayjs from 'dayjs';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {BehaviorSubject, filter, Observable, of} from 'rxjs';
import {useAnimation} from '@angular/animations';
import {Editor, NgxEditorModule, Toolbar} from 'ngx-editor';
import {StatusBadgeComponent} from '../../common/status-badge/status-badge.component';
import {FileHandlerComponent} from '../../common/file-handler/file-handler.component';
import {HistoryComponent, HistoryData} from '../../common/history/history.component';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {ContactService} from '../../services/contact.service';
import {GmFile} from '../../../models/gm-file';
import {User} from '../../../models/user';
import {StatusBadgeListComponent} from '../../common/status-badge-list/status-badge-list.component';
import {environment} from '../../../environments/environment';
import {Contact} from '../../../models/contact';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AsyncPipe} from '@angular/common';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatTimepicker, MatTimepickerInput, MatTimepickerToggle} from '@angular/material/timepicker';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {CSService} from '../../services/cs.service';
import {CS} from '../../../models/cs';
import {GMDatePipe} from '../../common/date.pipe';
import {EmptyViewPipe} from '../../common/empty-view.pipe';
import {MatTooltip} from '@angular/material/tooltip';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {HistoryService} from '../../services/history.service';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';

export type CreateCSData = {
  csData: CS,
}

@Component({
  selector: 'app-create-cs',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIcon,
    StatusBadgeComponent,
    ReactiveFormsModule,
    OverlayModule,
    ReactiveFormsModule,
    MatRipple,
    FileHandlerComponent,
    MatOption,
    MatSelectModule,
    HistoryComponent,
    NgxEditorModule,
    DeleteConfirmationComponent,
    EditableInputComponent,
    MatAutocompleteTrigger,
    MatAutocomplete,
    AsyncPipe,
    MatDatepickerInput,
    MatDatepicker,
    MatDatepickerToggle,
    MatTimepickerInput,
    MatTimepicker,
    MatTimepickerToggle,
    PersonBadgeComponent,
    GMDatePipe,
    EmptyViewPipe,
    MatTooltip,
    MatProgressSpinner
  ],
  templateUrl: './create-cs.component.html',
  styleUrl: './create-cs.component.scss',
  providers: [provideNativeDateAdapter()],
  encapsulation: ViewEncapsulation.None
})
export class CreateCsComponent implements OnInit, OnDestroy {
  isEditingDescription = false;
  isEditingContact = false;
  isEditingType = false;
  isEditingAssignee = false;
  isEditingAddress = false;
  isEditingDateAndTime = false;
  isEditingEntity = false;
  csStatus: string = '';
  csTimestamp?: number;
  csId?: string;
  csAssignee?: User;
  isNew = false;

  readonly dialogRef = inject(MatDialogRef<CreateCsComponent, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<CreateCSData>(MAT_DIALOG_DATA);
  readonly csService = inject(CSService);
  readonly contactService = inject(ContactService);
  readonly userService = inject(UserService);
  readonly authService = inject(AuthService);

  typesOfCS: string[] = ['Asfalto', 'Corte de Árvore', 'Emprego', 'Retirada de materiais', 'Retirada de entulho'];
  csDateTime?: Date;

  files: GmFile[] = [];

  constructor(private overlay: Overlay,) {
    effect(() => {
      this.updateHistory();
    });
  }

  @ViewChild('status') status!: ElementRef;

  uploadFileList?: File[];

  html = '';
  editor?: Editor;
  toolbar?: Toolbar;

  ngOnDestroy(): void {
    this.editor?.destroy();
  }

  csType?: string;
  csProtocol?: string;
  csContact?: string;
  csAddress?: string;
  csEntity?: string;

  historyService = inject(HistoryService);

  async updateContact(event: MatAutocompleteSelectedEvent) {
    if (event.option.value.id.startsWith('__new_contact__')) {
      this.csContact = event.option.value.id;
    } else {
      this.csContact = event.option.value.id;
    }
    if (!this.isNew) {
      this.isEditingContact = false;
      await this.csService.updateContact(this.data.csData.id, this.csContact ?? '');
      await this.contactService.getAllContacts();
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  displayFn(contact?: Contact): string {
    return contact && contact.name ? contact.name : '';
  }

  async updateType(event: MatSelectChange) {
    this.csType = event.value;
    if (!this.isNew) {
      await this.csService.updateType(this.data.csData.id, this.csType ?? '');
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  async updateDateTime() {
    this.isEditingDateAndTime = false;
    if (this.csDateTime) {
      if (this.data.csData == null) {
        return;
      }
      await this.csService.updateAttendDate(this.data.csData.id, dayjs(this.csDateTime).unix());
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  fb = inject(FormBuilder);

  formGroup = this.fb.group({
    nameControl: new FormControl('', [Validators.required]),
    contactControl: new FormControl<Contact | undefined | null>(null, [Validators.required]),
    addressControl: new FormControl('', []),
    typeControl: new FormControl('', [Validators.required]),
    entityControl: new FormControl('', []),
  })

  closeAddress() {
    this.isEditingAddress = false;
  }

  async updateAddress(value?: string) {
    this.isEditingAddress = false
    if (this.csAddress !== value) {
      this.csAddress = value
      if (this.data.csData == null) {
        return
      }
      await this.csService.updateAddress(this.data.csData.id, value ?? '')
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  showOverlay() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.status)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 50
      }]);
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    const ref = overlayRef.attach(new ComponentPortal(StatusBadgeListComponent));
    ref.instance.statusList.set(['open', 'onHold', 'inProgress', 'deferred', 'rejected']);
    ref.instance.closePanel.subscribe(async (value) => {
      overlayRef.detach();
      if (this.csStatus !== value) {
        this.csStatus = value;
        if (this.data.csData == null) {
          return;
        }
        await this.csService.updateStatus(this.data.csData.id, value);
        await this.historyService.getHistoryForRelation(this.data.csData.id);
      }
    });
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  allContacts: Contact[] = []

  contactOptions: Observable<Contact[]> = of([]);

  private _filterGroup(value?: Contact | any): Contact[] {
    if (value) {
      if (value.name) {
        return this.allContacts
          .filter(c => c.name.toLowerCase().includes(value.name.toLowerCase()));
      } else {
        return this.allContacts
          .filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
      }
    }
    return this.allContacts;
  }

  historyList: HistoryData[] = [];

  ngOnInit(): void {
    this.formGroup.get('contactControl')!.valueChanges.subscribe((value) => {
      this.contactOptions = of(this._filterGroup(value));
    });
    this.formGroup.get('addressControl')!.valueChanges.subscribe((value) => {
      this.csAddress = value ?? undefined;
    });
    this.formGroup.get('typeControl')!.valueChanges.subscribe(async (value) => {
      if (!this.isNew) {
        this.isEditingType = false;
        this.csType = value ?? undefined;
        if (this.data.csData == null) {
          return;
        }
      }
    });
    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['text_color', 'background_color'],
      ['ordered_list', 'bullet_list'],
    ];
    this.contactService.getAllContacts().then((contacts) => {
      this.allContacts = contacts;
      this.contactOptions = of(contacts);
      if (this.data?.csData?.contactId != null) {
        this.csContact = this.data.csData.contactId;
        const c = this.allContacts.find(contact => contact.id === this.data.csData.contactId);
        this.isEditingContact = false;
        this.formGroup.get('contactControl')?.setValue(c);
      }
    });
    if (this.data?.csData?.id != null) {
      this.isNew = false;
      this.csId = this.data.csData.id;
      this.historyService.getHistoryForRelation(this.csId);
      if (this.data?.csData.description != null) {
        this.html = this.data.csData.description;
      }
      if (this.data?.csData.type != null) {
        this.csType = this.data.csData.type;
        this.formGroup.get('typeControl')?.setValue(this.csType);
      }
      if (this.data?.csData.timestamp != null) {
        this.csTimestamp = this.data.csData.timestamp;
      }

      if (this.data?.csData.assigneeUserId != null) {
        this.csAssignee = this.userService.users().find(user => user.id === this.data.csData?.assigneeUserId);
      }
      this.csDateTime = this.data.csData.attendDate ? new Date(this.data.csData.attendDate * 1000) : undefined;
      this.csProtocol = this.data.csData.protocol;
      this.csStatus = this.data.csData.status;
      this.csEntity = this.data?.csData.entity;
      this.csAddress = this.data?.csData.address;

    } else {
      this.isNew = true;
      this.csStatus = 'open';
      this.csAssignee = this.authService.loggedUser()!;
      this.isEditingDescription = true;
      this.isEditingContact = true;
      this.isEditingDateAndTime = true;
      this.isEditingAssignee = false;
      this.isEditingEntity = true;
      this.isEditingType = true;
    }
  }

  async updateEntity(value?: string) {
    this.isEditingEntity = false;
    if (this.csEntity !== value) {
      this.csEntity = value;
      if (this.data.csData == null) {
        return;
      }
      await this.csService.updateEntity(this.data.csData.id, value ?? '');
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  async selectAssignee(event: MatSelectChange) {
    this.csAssignee = this.userService.users().find(user => user.id === event.value);
    if (!this.isNew) {
      this.isEditingAssignee = false;
      if (this.data.csData == null) {
        return;
      }
      await this.csService.updateAssignee(this.data.csData.id, event.value);
      await this.historyService.getHistoryForRelation(this.data.csData.id);
    }
  }

  updateHistory() {
    if (this.data.csData?.id == null) {
      return;
    }
    this.historyList = [];
    this.historyService.historyList().get(this.data.csData!.id!)?.forEach(value => {
      if (value != null) {
        let assigneeChange;
        if (value.data.dataChanged === 'assigneeUserId') {
          assigneeChange = {
            oldAssignee: this.userService.users().find(user => user.id === value.data.oldData),
            newAssignee: this.userService.users().find(user => user.id === value.data.newData)
          }
        }
        this.historyList.push({
          ...value,
          user: this.userService.users().find(user => user.id === value.data.callerUserId),
          assigneeChange
        });
      }
    });
  }

  deleteCS() {
    if (this.csId) {
      this.csService.delete(this.csId);
    }
    this.dialogRef.close({delete: this.csId});
  }

  async saveDescription() {
    this.isEditingDescription = false;
    if (this.csId == null) {
      return;
    }
    await this.csService.updateDescription(this.csId, this.html);
    await this.historyService.getHistoryForRelation(this.csId);
  }

  closeDescription() {
    this.html = this.data.csData?.description ?? '';
    this.isEditingDescription = false;
  }

  loading = false;

  async saveAll() {
    if (this.loading) {
      return;
    }
    if (this.isNew) {
      this.formGroup.markAllAsTouched();
      if (!this.formGroup.valid) {
        return;
      }
      this.loading = true;
      const cs: Omit<CS, 'id' | 'timestamp'> = {
        contactId: this.csContact!,
        protocol: this.formGroup.get('nameControl')?.value ?? this.csProtocol ?? '',
        type: this.csType!,
        status: this.csStatus,
        description: this.html,
        address: this.csAddress,
        assigneeUserId: this.csAssignee?.id,
        attendDate: this.csDateTime ? dayjs(this.csDateTime).unix() : undefined,
        entity: this.formGroup.get('entityControl')?.value ?? undefined
      };
      this.csService.insertCS(cs).then(() => {
        this.contactService.getAllContacts();
      });
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);

    } else {
      if (this.csId) {
        this.isEditingDescription = false;
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  fileUpload(event: any) {
    const files: FileList = event.target.files;
    this.uploadFileList = Array.from(files);
  }

  protected readonly useAnimation = useAnimation;
  protected readonly environment = environment;
}
