@if (isEditing()) {
  <mat-form-field class="example-full-width">
    <input matInput
           placeholder="contato/atentimento"
           aria-label="State"
           [matAutocomplete]="auto"
           [formControl]="control">
    <mat-icon matIconSuffix fontIcon="close" [style.color]="'#DA2727'" (click)="clear()"/>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (entity of filtered | async; track entity) {
        <mat-option [value]="entity">
          <mat-icon [fontIcon]="entity.icon"/>
          <span>{{ entity.name }}</span>
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
} @else {
  @if (selectedEntity) {
    <div class="relation-div" [ngClass]="{'relation-editable': editable}" (click)="edit()">
      <mat-icon style="width: 30px;"
        [fontIcon]="selectedEntity.icon"></mat-icon>
      <a [matTooltip]="'Deletado'"
         [matTooltipDisabled]="!selectedEntity.deleted"
         class="text-overflow">{{selectedEntity.name}}</a>
      @if(!selectedEntity.deleted) {
        <mat-icon class="open_new_new_icon" fontIcon="open_in_new"
                  (click)="window.open((selectedEntity.type === 'contacts'? '/contatos/': '/atendimentos/') + selectedEntity.id)"
        ></mat-icon>
      }
    </div>
  } @else {
    @if (editable) {
      <span class="relation-div relation-editable" (click)="isEditing.set(true)">+ Clique para adicionar</span>
    } @else {
      <div class="empty">---</div>
    }
  }
}
