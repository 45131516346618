import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyView'
})
export class EmptyViewPipe implements PipeTransform {

  transform(value?: string | null, ...args: unknown[]): string {
    if (value == null) {
      return '-----';
    } else if (value.trim() === '') {
      return '-----';
    }
    return value;
  }

}
