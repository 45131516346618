import {Component, effect, inject, signal} from '@angular/core';
import {TaskData, TaskTable} from './task-table/task-table.component';
import {ItemCounterComponent} from '../../common/item-counter/item-counter.component';
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {BadgeUserData, PersonPickerComponent} from '../../common/person-picker/person-picker.component';
import {DeadlineFilterChange, DeadlineFilterComponent} from '../../common/deadline-filter/deadline-filter.component';
import {TaskService} from '../../services/task.service';
import {Task} from '../../../models/task';
import {MatDialog} from '@angular/material/dialog';
import {CreateTaskComponent} from './create-task/create-task.component';
import dayjs from 'dayjs';
import {User} from '../../../models/user';
import {UserService} from '../../services/user.service';
import {MatRipple} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MentionsDirective} from '../../common/mentions.directive';
import {StatusGroup} from '../../common/status-parser.pipe';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {Location} from '@angular/common';
import {SnackbarService} from '../../services/snackbar.service';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';

@Component({
  selector: 'app-tasks',
  imports: [
    TaskTable,
    ItemCounterComponent,
    MatFormField,
    MatInput,
    MatLabel,
    PersonPickerComponent,
    DeadlineFilterComponent,
    MatRipple,
    MentionsDirective,
    MatProgressSpinner,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.scss'
})
export class TasksComponent {
  statusMap = new Map<string, StatusGroup>([
    ['total', StatusGroup.total.setShowSeparator(true)],
    ['open', StatusGroup.open],
    ['inProgress', StatusGroup.inProgress],
    ['done', StatusGroup.done.setShowSeparator(true)],
    ['late', StatusGroup.late],
    ['ok', StatusGroup.ok],
  ]);

  toBeDeleted = '';
  statusOptions = [
    { value: 'open', label: "Aberto"},
    { value: 'inProgress', label: "Em progresso"},
    { value: 'done', label: "Concluido"}];
  statusFilter = new FormControl(['open', 'inProgress']);

  filterToday: boolean = false;
  filterLate: boolean = false;
  filterUntil: boolean = false;

  filterDate?: Date;

  usersService = inject(UserService);
  usersToShowBadges?: User[] = []

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  loading = true;
  tid?: string;

  constructor(
    private taskService: TaskService,
    private snackBar: SnackbarService
  ) {
    this.activatedRoute.paramMap.subscribe((param) => {
      this.tid = param.get('taskId') ?? undefined;
      this.update();
    });
    effect(() => {
      this.loading = false;
      this.update();
    });
  }

  static updateUsers(tasks: Task[], withDeadline = false): User[] | undefined {
    if (tasks.length === 0) {
      return;
    }
    let userList: User[] | undefined;
    for (let t of tasks.filter((task) => withDeadline ? task.deadline != null : true)) {
      if (t.assigneeUser && (userList?.findIndex((user) => user.id === t.assigneeUser?.id) === -1 || !userList)) {
        if (!userList) {
          userList = [];
        }
        userList.push(t.assigneeUser);
      }
    }
    return userList;
  }

  async update() {
    this.usersToShowBadges = TasksComponent.updateUsers(this.taskService.taskList());
    this.setStatusMap(this.taskService.taskList());
    if (this.tid && this.taskService.taskList().find((task) => task.id === this.tid)) {
      this.openDialog(this.tid);
    }
  }

  filteredTableData(taskData: TaskData[]) {
    if (taskData.length < this.taskService.taskList().length) {
      this.setStatusMap(taskData);
    } else {
      this.setStatusMap(this.taskService.taskList());
    }
  }

  setStatusMap(taskData: TaskData[]) {
    this.statusMap.get('total')?.setCount(taskData.length);
    this.statusMap.get('open')?.setCount(taskData.filter((task) => task.status === 'open').length);
    this.statusMap.get('inProgress')?.setCount(taskData.filter((task) => task.status === 'inProgress').length);
    this.statusMap.get('done')?.setCount(taskData.filter((task) => task.status === 'done').length);
    this.statusMap.get('ok')?.setCount(taskData.filter((task) => dayjs(task.deadline).isAfter(dayjs()) || task.deadline == null).length);
    this.statusMap.get('late')?.setCount(taskData.filter((task) => dayjs(task.deadline).isBefore(dayjs())).length);
  }

  readonly dialog = inject(MatDialog);
  usersToFilter: User[] = []

  opened = false;
  location = inject(Location);
  openDialog(taskId?: string): void {
    if (this.opened) {
      return;
    }
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      minWidth: '1120px',
      height: '700px',
      data: {
        taskData: taskId ? this.taskService.taskList().find((task) => task.id === taskId) : undefined,
        users: this.usersService.users()
      },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      this.tid = undefined;
      this.opened = false;
      this.location.go('tarefas');
      if (result) {
        if (result.deleteId) {
          this.toBeDeleted = result.deleteId;
        } else if (result.newTask) {
          const snack = this.snackBar.openSnackBar('success',
            'Tarefa salva com sucesso!',
            'Clique para vê-la'
          );
          snack.onAction().subscribe(() => {
            this.router.navigate(['tarefas', result.task.id]);
          });
          this.loading = true;
        }
      }
      await this.update();
    });
  }

  deadlineChange(event: DeadlineFilterChange) {
    this.filterToday = event.today ?? false;
    this.filterLate = event.late ?? false;
    this.filterUntil = event.until ?? false;
  }

  deadlineDateChange(event?: Date) {
    this.filterDate = event;
  }

  searchValue: string = '';

  newTask() {
    this.openDialog();
  }

  openDetails(taskId: string) {
    this.openDialog(taskId);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
  }

  personSelected(event: BadgeUserData[]) {
    this.usersToFilter = event.filter((user) => user.selected);
  }
}
