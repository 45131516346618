import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CS} from '../../models/cs';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';

@Injectable({
  providedIn: 'root'
})
export class CSService {

  csList = signal<CS[]>([])

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.cs.pipe(startWith(null))
      .subscribe(()=>{
        this.getAllServices();
      })
  }

  async getAllServices() {
    const result = await lastValueFrom(this.http.get<CS[]>(`${environment.backendUrl}/customer_services`));
    this.csList.set(result);
    return result;
  }

  async insertCS(cs: Omit<CS, 'id' | 'timestamp'>) {
    const response = await lastValueFrom(this.http.post<{
      id: string;
    }>(`${environment.backendUrl}/customer_services`, cs));
    await this.getAllServices();
    return response;
  }

  async delete(taskId: string) {
    await lastValueFrom(this.http.delete<{}>(`${environment.backendUrl}/customer_services/` + taskId));
    await this.getAllServices();
  }

  async updateStatus(csId: string, status: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/status', {status}));
    await this.getAllServices();
  }

  async updateDescription(csId: string, description: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/description', {description}));
    await this.getAllServices();
  }

  async updateEntity(csId: string, entity: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/entity', {entity}));
    await this.getAllServices();
  }

  async updateType(csId: string, type: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/type', {type}));
    await this.getAllServices();
  }
  async updateAttendDate(csId: string, attendDate: number) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/attendDate', {attendDate}));
    await this.getAllServices();
  }
  async updateAddress(csId: string, address: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/address', {address}));
    await this.getAllServices();
  }
  async updateAssignee(csId: string, assigneeUserId: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/assigneeUserId', {assigneeUserId}));
    await this.getAllServices();
  }
  async updateContact(csId: string, contactId: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/contactId', {contactId}));
    await this.getAllServices();
  }
}
