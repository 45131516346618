<div [ngClass]="{'notification-item': true, 'item-read': notification.read, loader: loading || loader}">
  <div *ngIf="!notification.read" class="dot"></div>
  <div class="content">
    <app-person-badge [profilePicture]="notification.sender?.picture"
                      [size]="40" [selected]="false"
                      [user]="notification.sender"></app-person-badge>
    <div class="notification-text">
      <span [innerHTML]="notification.message"></span>
    </div>
  </div>
  <div class="actions">
    <div class="action clear" (click)="_onClear()">LIMPAR</div>
    <div class="action read" (click)="_onToggle()">MARCAR COMO{{ notification.read ? ' NÃO':''}} LIDO</div>
  </div>
</div>
