import {Injectable, signal} from '@angular/core';
import {lastValueFrom, startWith} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CS, CsInformation} from '../../models/cs';
import {environment} from '../../environments/environment';
import {RealtimeService} from './realtime.service';
import {LaInformation} from '../../models/la';

@Injectable({
  providedIn: 'root'
})
export class CSService {

  csList = signal<CS[]>([])
  csTypeList = signal<CsInformation[]>([])

  constructor(
    private realtime: RealtimeService,
    private http: HttpClient
  ) {
    this.realtime.cs.pipe(startWith(null))
      .subscribe(()=>{
        this.getAllServices();
      })
  }

  async getAllCsInformation(organizationId?: string){
    const result = await lastValueFrom(this.http.get<CsInformation[]>(`${environment.backendUrl}/cs_information`));
    this.csTypeList.set(result);
  }

  async getAllServices() {
    const result = await lastValueFrom(this.http.get<CS[]>(`${environment.backendUrl}/customer_services`));
    this.csList.set(result);
    return result;
  }

  async updateCS(id: string,cs: Partial<Omit<CS, 'id' | 'timestamp'>>) {
    const response = await lastValueFrom(this.http.put<CS>(`${environment.backendUrl}/customer_services/${id}`, cs));
    await this.getAllServices();
    return response;
  }

  async delete(taskId: string) {
    await lastValueFrom(this.http.delete<{}>(`${environment.backendUrl}/customer_services/` + taskId));
    await this.getAllServices();
  }

  async updateStatus(csId: string, status: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/status', {status}));
    await this.getAllServices();
  }

  async updateType(csId: string, type: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/type', {type}));
    await this.getAllServices();
  }

  async updateAssignee(csId: string, assigneeUserId: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/assigneeUserId', {assigneeUserId}));
    await this.getAllServices();
  }
  async updateContact(csId: string, contactId: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/contactId', {contactId}));
    await this.getAllServices();
  }

  async updateProtocol(csId: string, protocol: string) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/protocol', {protocol}));
    await this.getAllServices();
  }
  async updateSplInsert(csId: string, splInsert: boolean) {
    await lastValueFrom(this.http.put<void>(`${environment.backendUrl}/customer_services/` + csId + '/splInsert', {splInsert}));
    await this.getAllServices();
  }
}
