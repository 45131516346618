<div class="header">
  <app-delete-confirmation
    [hidden]="isNew || authService.loggedUser()?.organizationRelationship?.position === 'ESTAGIARIO'"
    [deleting]="false"
    (deleteConfirmation)="deleteTask()"></app-delete-confirmation>

  @if (isNew) {
    <div class="task-name task-add">Adicionar Tarefa</div>
  } @else {
    <div class="task-name">{{ taskAlias }}</div>
  }
  <button mat-icon-button (click)="close()">
    <mat-icon class="close-icon" fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="content">
    <div class="left" [style.height]="isNew ? '567px' : '629px'">
      <div style="padding:15px">
      <div class="entry">
        <div class="title" [highlightError]="formGroup.controls.nameControl" >Nome:*</div>
        @if (isNew) {
          <form [formGroup]="formGroup">
            <mat-form-field subscriptSizing="dynamic" style="width: 577px;">
              <mat-label>Nome</mat-label>
              <input matInput placeholder="Tarefa X" formControlName="nameControl">
            </mat-form-field>
          </form>
        } @else if (isEditingName || !taskName || taskName === '') {
          <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
            <form [formGroup]="formGroup">
              <mat-form-field subscriptSizing="dynamic" style="width: 520px;">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Tarefa X" formControlName="nameControl">
              </mat-form-field>
            </form>
            <div style="display: flex; flex-direction: row; gap: 5px">
              <div mat-ripple class="save-small" (click)="saveName()">
                <mat-icon fontIcon="check"></mat-icon>
              </div>
              <div mat-ripple class="cancel-small" (click)="cancelSaveName()">
                <mat-icon fontIcon="close"></mat-icon>
              </div>
            </div>
          </div>
        } @else {
          <div class="task-name-editable" (click)="isEditingName = true">{{ taskName }}</div>
        }
      </div>
      <p class="title">Descrição:</p>
      @if (!isEditingDescription) {
        <div class="description" (click)="isEditingDescription = !isEditingDescription">
          @if(delta) {
            <quill-view class="view" format="object" [content]="delta"></quill-view>
          } @else {
            <p class="item-description" [innerHTML]="html"></p>
          }
        </div>
      } @else {
        <quill-editor placeholder="Escreva aqui" class="rich-text-description" [formControl]="richControl" format="object" (onContentChanged)="commentTextChanges($event)"></quill-editor>
        @if (!isNew) {
          <div style="display: flex; flex-direction: row-reverse; margin-bottom: 20px; gap: 5px; margin-top: 10px">
            <div class="save" mat-ripple (click)="save()">
              <div>Salvar</div>
            </div>
            <div class="cancel" mat-ripple (click)="cancelDescriptionEdit()">
              <div>Cancelar</div>
            </div>
          </div>
        }
      }
      @if (taskId && authService.orgPlan != Plan.SILVER) {
        <div style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-between;">
          <div mat-ripple class="upload" (click)="fileInput.click()">
            <mat-icon fontIcon="upload"></mat-icon>
            <div>Adicionar arquivos</div>
          </div>
          <input #fileInput type="file" hidden (change)="fileUpload($event)">
          <p class="title">Documentos</p>

        </div>

        <app-file-handler [referenceId]="taskId" [uploadFileList]="uploadFileList"
                          entity="tasks"
                          (fileUploaded)="fileUploadFinished($event)"
                          (fileRemoved)="fileRemoved($event) "/>

      }
    </div>
      @if (taskId) {
        <mat-expansion-panel
          class="expansion">
          <mat-expansion-panel-header>
            <mat-panel-title><p class="title">Histórico</p></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="history">
            @for (history of historyList; track history.id) {
              <app-history [history]="history"></app-history>
            }
          </div>
        </mat-expansion-panel>
      }
    </div>
    <div class="vertical-divider" [style.height]="isNew? '567px' : '629px'"></div>
    <div class="right">

      <section class="entry">
        <p class="subtitle">Responsável:</p>
        <section class="info-line">
          <app-assignee-selector style="width: 230px" [assignee]="taskAssignee" (selectAssignee)="selectAssignee($event)"/>
        </section>
      </section>

      <section #status class="entry">
        <p class="subtitle">Status:</p>
        <section class="info-line">
          <app-status-badge [list]="true" [nameId]="taskStatus" (click)="showOverlay()" [type]="'task'"></app-status-badge>
        </section>
      </section>


      <section class="entry">
        <p class="subtitle">Prazo:</p>
        <section class="info-line">
          <mat-form-field>
            <mat-label>Selecione uma data</mat-label>
            <input formMask="date"  matInput [matDatepicker]="picker" [formControl]="dateControl">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </section>
      </section>

      <section class="entry relation">
        <p class="subtitle relation-sub">Relacionado com:</p>
          <app-related-contact-cs-la [style]="{width: '240px'}" [selected]="relationships[0] && relationships[0].relationshipId"
                                 (selectedChange)="relatedChange($event)"/>
      </section>
      @if (taskId) {
        <p class="title" style="margin-top: 0">Comentários:</p>
        <app-comments *ngIf="taskId" [relationshipId]="taskId" [relationshipType]="'task'" [users]="data.users"></app-comments>
      }
    </div>
  </div>
  @if (isNew) {
    <div
      style="height: 61px;display: flex; flex-direction: row; gap: 5px; justify-content: space-evenly; border-top: 1px solid #D9D9D9;    align-items: center;">
      <div style="display: flex; flex-direction: row-reverse; gap: 5px">
        <app-button-w-loader
          label="Salvar"
          [loading]="isLoading"
          type="yellow"
          (buttonClick)="save()"
        />
        <app-button-w-loader
          label="Cancelar"
          type="outline"
          (buttonClick)="dialogRef.close()"
        />
      </div>
    </div>
  }
</mat-dialog-content>
