import {Component, inject, OnInit, signal} from '@angular/core';
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {MatInput, MatInputModule} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackbarService} from '../services/snackbar.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import {
  Auth,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  verifyPasswordResetCode,
  getAuth
} from '@angular/fire/auth';
import {merge} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {formErrors} from '../utils';

@Component({
  selector: 'app-login',
  imports: [
    MatFormField,
    MatInput,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinner,
    NgOptimizedImage,
    MatFormFieldModule,
    FormsModule,
    NgIf,
    MatIcon,
  ],
  templateUrl: './login.component.html',
  standalone: true,
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  mode: 'login' | 'forgot' | 'resetPassword' = 'login';
  authService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  snackService: SnackbarService = inject(SnackbarService);
  email = new FormControl('', [Validators.email, Validators.required]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  passwordVisible = false;
  oobCode?: string;
  isLoading = signal(false);
  errorMessage = signal({email: '', password:''});


  private auth = inject(Auth);
  constructor() {
    merge(this.password.valueChanges, this.email.valueChanges)
    .subscribe(() => this.updateErrorMessage());
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const mode = params['mode'];
      const actionCode = params['oobCode'];
      if (['resetPassword', 'login', 'forgot'].includes(mode)) {
        this.mode = mode;
      }
      if (actionCode) {
        this.password.disable();
        this.handleResetPassword(actionCode);
      }
    });
  }

  title = {
    login: 'Que bom ver você novamente!',
    forgot: 'Digite seu email de registro para receber uma nova senha',
    resetPassword: 'Reset de senha'
  }

  action(){
    switch (this.mode) {
      case 'login':
        this.login();
        break;
      case 'forgot':
        this.sendResetPassword();
        break;
      case 'resetPassword':
        this.resetPassword();
        break;
    }
  }

  back(){
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { mode: 'login' },
      queryParamsHandling: 'merge'
    });   }

  login() {
    const form = [this.email, this.password];
    if (form.some(control => control.invalid)) {
      form.forEach(control => control.markAsTouched());
      return;
    }
    this.isLoading.set(true);

    signInWithEmailAndPassword(this.auth, this.email.value!, this.password.value!)
      .then(() => {})
      .catch(() => this.snackService.openSnackBar('error', 'Usuário ou senha inválidos', 'Fechar'))
      .finally(() => this.isLoading.set(false));
    // this.authService.login({email: this.email.value!, password: this.password.value!})
    //   .then(() => this.router.navigate(['/tarefas']) )
    //   .catch(() => this.snackService.openSnackBar('error', 'Usuário ou senha inválidos', 'Fechar'))
    //   .finally(() => this.isLoading.set(false));
  }

  sendResetPassword(){
    if(this.email.invalid) {
      this.email.markAsTouched();
      return;
    }
    this.isLoading.set(true);
    this.authService.resetPassword({email: this.email.value!})
      .then(() => this.snackService.openSnackBar(
        'success',
        'Você receberá um email caso o usuário esteja registrado no sistema.',
        'OK',
        5000,
        'bottom'
        ))
      .finally(() => this.isLoading.set(false));
  }

  handleResetPassword(actionCode:string) {
    const auth = getAuth();
    verifyPasswordResetCode(auth, actionCode).then((email) => {
      this.oobCode = actionCode;
      this.password.enable();
    }).catch((error) => {
      this.snackService.openSnackBar('error', 'O link expirou, solicite novamente', 'Fechar');
    });
  }

  resetPassword(){
    const auth = getAuth();
    this.isLoading.set(true);
    if(!this.oobCode || this.password.invalid) {
      this.password.markAsTouched();
      return;
    }
    confirmPasswordReset(auth, this.oobCode, this.password.value!).then((resp) => {
      this.snackService.openSnackBar('success', 'Senha redefinida com sucesso', 'Fechar');
      this.password.reset('');
      this.passwordVisible = false;
      this.mode = 'login';
    }).catch((error) => {
      this.snackService.openSnackBar('error', 'O link expirou, solicite novamente', 'Fechar');
    }).finally(() => this.isLoading.set(false));
  }

  updateErrorMessage() {
    const errors = {
      email: '',
      password: ''
    }
    for(const key in this.password.errors) {
      if(this.password.errors?.hasOwnProperty(key)) {
        errors.password = formErrors[key];
      }
    }
    for(const key in this.email.errors) {
      if(this.email.errors?.hasOwnProperty(key)) {
        errors.email = formErrors[key];
      }
    }
    this.errorMessage.set(errors);
  }

  forgotPassword() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { mode: 'forgot' },
      queryParamsHandling: 'merge'
    });
  }
}
