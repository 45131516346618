<div class="file-handler">
  @for (file of files; track file.name) {
    <div class="file" [style.width]="files.length > 3 ? '202px' : '240px'">
      <div mat-ripple class="text" (click)="download(file)">
        <div class="title">{{ file.name }}</div>
        <div class="time">{{
            file.timestamp ?
              (file.timestamp | gm_date:'date-time') :
              dayjs() | gm_date
          }}</div>
      </div>
      <button mat-icon-button (click)="removeFile(file)">
        <mat-icon fontIcon="delete"></mat-icon>
      </button>
    </div>
  }
  @if (!files || files.length === 0) {
    <div class="empty-docs">Nenhum documento encontrado.</div>
  }
</div>
