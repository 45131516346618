import {Component, effect, EventEmitter, inject, OnInit, Output, signal, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
  MatRipple,
  provideNativeDateAdapter
} from '@angular/material/core';
import {ContactService} from '../../services/contact.service';
import {PersonBadgeComponent} from '../../common/person-picker/person-badge/person-badge.component';
import {EditableInputComponent} from '../../common/editable-input/editable-input.component';
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {MatSelect, MatSelectChange, MatSelectTrigger} from '@angular/material/select';
import {Bairro} from '../../../models/bairro';
import {User} from '../../../models/user';
import {Contact} from '../../../models/contact';
import {MatInput, MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {DeleteConfirmationComponent} from '../../common/delete-confirmation/delete-confirmation.component';
import {ContactData} from '../contact-table/contact-table.component';
import {TaskService} from '../../services/task.service';
import {ContactCompletePipe} from '../contact-complete.pipe';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ButtonWLoaderComponent} from '../../common/button-w-loader/button-w-loader.component';
import {getDownloadURL, getMetadata, ref, Storage, uploadBytes} from '@angular/fire/storage'
import {ulid} from 'ulid';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {SnackbarService} from '../../services/snackbar.service';
import {ToogleSelectComponent} from '../../common/toogle-select/toogle-select.component';
import dayjs from 'dayjs';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from '@angular/material/datepicker';
import {CSService} from '../../services/cs.service';
import {MatTooltip} from '@angular/material/tooltip';
import {NotificationsService} from '../../services/notifications.service';
import {DUDU_DATE_FORMATS, noWhitespaceValidator} from '../../utils';
import {GMDatePipe} from '../../common/date.pipe';
import {Platform} from '@angular/cdk/platform';
import {CustomDateAdapter} from '../../tasks/tasks/create-task/create-task.component';

type ContactDialogData = {
  contactData?: Contact,
  bairros: Bairro[],
}

@Component({
  selector: 'app-create-contact',
  imports: [
    MatIcon,
    MatIconButton,
    MatRipple,
    PersonBadgeComponent,
    EditableInputComponent,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    MatSelectTrigger,
    MatInput,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    DeleteConfirmationComponent,
    ButtonWLoaderComponent,
    ToogleSelectComponent,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    NgClass,
    MatTooltip,
    GMDatePipe
  ],
  templateUrl: './create-contact.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }, {
      provide: MAT_DATE_FORMATS, useValue: DUDU_DATE_FORMATS
    },
  ],
  styleUrl: './create-contact.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class CreateContactComponent implements OnInit {

  readonly dialogRef = inject(MatDialogRef<CreateContactComponent, { changed: boolean, isTemp: boolean }>);
  readonly data = inject<ContactDialogData>(MAT_DIALOG_DATA);
  @Output() tmp: EventEmitter<string | undefined> = new EventEmitter();

  contactName: string = '';
  contactBairro: string = '';
  contactType: string = this.data.contactData?.type ?? 'PF';
  contactEmail: string = '';
  contactPhone: string = '';
  contactBirthday?: dayjs.Dayjs;
  contactGender: string = '';
  contactAddress: string = '';
  contactMetadata: string = '';
  contactAssignee?: User;
  contactPicture?: string;
  contactCnpj?: string;
  contactLineOfWork?: string;
  contactAddressNumber?: string;
  contactAddressComp?: string;
  contactCity?: string;
  contactSite?: string;
  contactProfession?: string;

  isPJ = signal<boolean>(this.data.contactData?.type === 'PJ');

  genderOptions = [
    'Masculino',
    'Feminino',
    'Prefiro não informar'
  ];
  professionOptions = [
    'Agricultor',
    'Pedreiro',
    'Logista',
    'Advogado',
    'Medico',
    'Engenheiro'
  ];

  lineOfWorkOptions = [
    'Comercio',
    'Industria',
    'Marketing'
  ];

  isEditingName = false;
  isEditingBirthday = false;
  isEditingPhone = false;
  isEditingGender = false;
  isEditingEmail = false;
  isEditingAddress = false;
  isEditingMetadata = false;
  isEditingCnpj = false;
  isEditingLineOfWork = false;
  isEditingAddressNumber = false;
  isEditingAddressComp = false;
  isEditingCity = false;
  isEditingSite = false;
  isEditingProfession = false;

  contactId?: string;

  changed = false;

  contactService = inject(ContactService);
  notificationsService = inject(NotificationsService);
  taskService = inject(TaskService);
  cservice = inject(CSService);
  authService = inject(AuthService);
  storage = inject(Storage);
  snack = inject(SnackbarService);

  uploadFileList?: File[];
  pictureRef?: any;
  pictureUrl?: string;
  hasCs = signal<boolean>(false);

  fb = inject(FormBuilder);

  formGroup = this.fb.group({
    nameControl: new FormControl('', [Validators.required, noWhitespaceValidator]),
    BirthdayControl: new FormControl<number|null>(null, [Validators.min(0)]),
    addressControl: new FormControl('', []),
    phoneControl: new FormControl('', [Validators.required,noWhitespaceValidator]),
    genderControl: new FormControl('', []),
    emailControl: new FormControl('', [Validators.email]),
    metadataControl: new FormControl('', []),
    cnpjControl: new FormControl('', []),
    lineOfWorkControl: new FormControl('', []),
    addressNumberControl: new FormControl('', []),
    addressCompControl: new FormControl('', []),
    cityControl: new FormControl('', []),
    siteControl: new FormControl('', []),
    professionControl: new FormControl('', []),
  })

  isNew = true;
  changeType(event: boolean) {
    this.isPJ.set(event);
    this.contactType = event ? 'PJ' : 'PF';
    if (!this.isNew) {
      this.saveType(this.contactType)
    }
  }

  constructor() {
    effect(() => {
      this.hasCs.set(this.cservice.csList().findIndex((cs) => cs.contactId === this.contactId) !== -1);
    });
  }

  ngOnInit(): void {
    if (this.data?.contactData?.id != null) {
      this.isNew = false;
      const contract = this.data.contactData;
      this.contactId = contract.id;
      this.contactName = contract.name ?? '';
      this.contactAddress = contract.address ?? '';
      this.contactBairro = contract.bairro ?? '';
      this.contactEmail = contract.email ?? '';
      this.contactPhone = contract.phone ?? '';
      this.contactBirthday =  contract.birthday ? dayjs(contract.birthday) : undefined;
      this.contactGender = contract.gender ?? '';
      this.contactMetadata = contract.metadata ?? '';
      this.contactCnpj = contract.cnpj ?? '';
      this.contactLineOfWork= contract.lineOfWork ?? '';
      this.contactAssignee = contract.assigneeUser ?? undefined;
      this.contactPicture = contract.pictureId ?? undefined;
      this.contactType = contract.type ?? '';
      this.contactAddressNumber = contract.addressNumber ?? '';
      this.contactAddressComp = contract.addressComp ?? '';
      this.contactCity = contract.city ?? '';
      this.contactSite = contract.site ?? '';
      this.contactProfession = contract.profession ?? '';
      if(this.contactPicture){
        this.getProfilePic();
      }
    } else {
      this.contactAssignee = this.authService.loggedUser()!;
    }
  }

  async getProfilePic(){
    getDownloadURL(ref(this.storage, `uploads/contacts/${this.contactPicture}`)).then((url) => {
      if(url){
        this.pictureUrl = url;
      }
    }).catch((e)=>{
      console.log(e);
    })
  }

  async savePhone(phone?: string) {
    this.contactPhone = phone ?? '';
    this.isEditingPhone = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactPhone(this.contactId, this.contactPhone);
    }
  }

  userService = inject(UserService);

  async selectAssignee(event: MatSelectChange) {
    this.changed = true;
    if (this.data.contactData == null) {
      if (this.isNew) {
        this.contactAssignee = this.userService.users().find(user => user.id === event.value);
      }
      return;
    }
    this.contactAssignee = this.userService.users().find(user => user.id === event.value);
    this.tmp.emit(undefined);
    await this.contactService.updateContactAssignee(this.data.contactData.id, event.value);
  }

  async saveBirthday(birthday ?: string) {
    this.contactBirthday = dayjs(birthday);
    this.isEditingBirthday = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactBirthday(this.contactId, this.contactBirthday?.unix());
    }
  }

  async saveGender(gender?: string) {
    console.log(gender);
    this.contactGender = gender ?? '';
    this.isEditingGender = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactGender(this.contactId, this.contactGender);
    }
  }

  async saveCnpj(cnpj?: string) {
    this.contactCnpj = cnpj ?? '';
    this.isEditingCnpj = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactCnpj(this.contactId, this.contactCnpj);
    }
  }

  async saveType(type?: string) {
    this.contactType = type ?? '';
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactType(this.contactId, this.contactType);
    }
  }

  async saveLineOfWork(lineOfWork?: string) {
    this.contactLineOfWork = lineOfWork ?? '';
    this.changed = true;
    this.isEditingLineOfWork = false;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactLineOfWork(this.contactId, this.contactLineOfWork);
    }
  }


  async saveEmail(email?: string) {
    this.contactEmail = email ?? '';
    this.isEditingEmail = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactEmail(this.contactId, this.contactEmail);
    }
  }

  async saveName(name?: string) {
    this.contactName = name ?? '';
    this.isEditingName = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactName(this.contactId, this.contactName);
    }
  }

  async saveMetadata(metadata?: string) {
    this.contactMetadata = metadata ?? '';
    this.isEditingMetadata = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactMetadata(this.contactId, this.contactMetadata);
    }
  }

  async saveAddress(address?: string) {
    this.contactAddress = address ?? '';
    this.isEditingAddress = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactAddress(this.contactId, this.contactAddress);
    }
  }
  async saveAddressNumber(addressNumber?: string) {
    this.contactAddressNumber = addressNumber ?? '';
    this.isEditingAddressNumber = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactAddressNumber(this.contactId, this.contactAddressNumber);
    }
  }
  async saveAddressComp(addressComp?: string) {
    this.contactAddressComp = addressComp ?? '';
    this.isEditingAddressComp = false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactAddressComp(this.contactId, this.contactAddressComp);
    }
  }
  async saveCity(city?: string) {
    this.contactCity = city ?? '';
    this.isEditingCity= false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactCity(this.contactId, this.contactCity);
    }
  }
  async saveSite(site?: string) {
    this.contactSite = site ?? '';
    this.isEditingSite= false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactSite(this.contactId, this.contactSite);
    }
  }
  async saveProfession(profession?: string) {
    this.contactProfession = profession ?? '';
    this.isEditingProfession= false;
    this.changed = true;
    if (this.contactId) {
      this.tmp.emit(undefined);
      await this.contactService.updateContactProfession(this.contactId, this.contactProfession);
    }
  }

  close() {
    this.dialogRef.close({changed: this.changed, isTemp: false});
  }

  deleteLoading = false;

  deleteContact() {
    this.deleteLoading = true;
    this.contactService.deleteContact(this.contactId ?? '').then(() => {
      this.snack.openSnackBar('success', `Contato deletado com sucesso`, 'Ok');
      this.dialogRef.close({delete: true});
    }).catch((e)=>{
      console.log(e);
      this.snack.openSnackBar('error', `Erro ao deletar contato`, 'Ok');
    });

  }

  async fileUpload(event: any) {
    const file: File = event.target.files[0];
    const metadata = {
      contentType: file.type,
    };
    this.contactPicture = this.contactPicture ?? ulid();
    console.log(this.contactPicture);
    if (file) {
      uploadBytes(ref(this.storage, `uploads/contacts/${this.contactPicture}`), await file.arrayBuffer(), metadata)
        .then((uploaded) => {
          this.getProfilePic();
          if(!this.isNew){
            this.contactService.updateContactPicture(this.contactId!, this.contactPicture!);
          }
        });
    }
  }



  loading = false;
  async saveAll() {
    if (this.loading) {
      return;
    }
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.loading = true;
      const name = this.formGroup.get('nameControl')?.value ?? '';
      const address = this.formGroup.get('addressControl')?.value ?? '';
      const email = this.formGroup.get('emailControl')?.value ?? '';
      const phone = this.formGroup.get('phoneControl')?.value ?? '';
      const birthday = dayjs(this.formGroup.get('birthdayControl')?.value).unix() ?? null;
      const gender = this.formGroup.get('genderControl')?.value ?? '';
      const metadata = this.formGroup.get('metadataControl')?.value ?? '';
      const addressNumber = this.formGroup.get('addressNumberControl')?.value ?? '';
      const addressComp = this.formGroup.get('addressCompControl')?.value ?? '';
      const city = this.formGroup.get('cityControl')?.value ?? '';
      const site = this.formGroup.get('siteControl')?.value ?? '';
      const profession = this.formGroup.get('professionControl')?.value ?? '';
      const cnpj = this.formGroup.get('cnpjControl')?.value ?? '';
      const lineOfWork = this.formGroup.get('lineOfWorkControl')?.value ?? '';
      const type = this.contactType;
      this.contactService.createContact(name,
        type,
        address,
        email,
        phone,
        birthday ?? undefined,
        gender,
        metadata, this.contactAssignee?.id,
        this.contactPicture,
        cnpj,
        lineOfWork,
        addressNumber,
        addressComp,
        city,
        site,
        profession,
      ).then(async (response) => {
        const contact: ContactData = {
          id: response.id,
          name,
          type,
          bairro: address,
          email,
          phone,
          birthday: birthday ?? undefined,
          gender,
          loadingAssignee: false,
          address,
          addressNumber,
          addressComp,
          city,
          site,
          profession,
        }
        const result = new ContactCompletePipe().transform(contact);
        if (result === 'Pendente') {
          const task = await this.taskService.createTask(
            `Finalizar cadastro do contato "${name}"`,
            `Finalizar cadastro do contato "${name}"`,
            'open',
            this.authService.loggedUser()?.id,
            contact.id,
            dayjs().add(7, 'day').unix());
          this.notificationsService.send({
            type: 'task',
            userId: this.authService.loggedUser()?.id!,
            message: `Sistema atribuiu a tarefa Finalizar cadastro do contato <a href="{FRONTEND_URL}/tarefas/${task.id}">"${name}"</a> a você!`,
          }); // this should be on the backend
        }
        this.snack.openSnackBar('success', `Contato criado com sucesso`, 'Ok');
        this.dialogRef.close();
      }).catch((e)=>{
        console.log(e);
        this.snack.openSnackBar('error', `Erro ao criar contato`, 'Ok');
      })
    }
  }

  openCs(){
    if(this.hasCs())
      this.window.open(`/atendimentos?contactId=${this.contactId}`, '_blank', 'noopener');
  }

  protected readonly Validators = Validators;
  protected readonly dayjs = dayjs;
  protected readonly window = window;
  protected readonly noWhitespaceValidator = noWhitespaceValidator;
}
